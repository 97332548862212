import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { buyNowInfo as repBuyNowInfo } from '@/elearningservice/repository/buyNowInfo.js'
import { datatype } from '@/library2/utils/datatype.js'
import { dataTrans } from '@/library2/utils/dataTrans.js'
import { account } from '@/elearningservice/service/account.js'
import { cart as repCart } from '@/elearningservice/repository/cart.js'
import { log } from '@/library2/utils/log.js'
import { base } from '@/elearningservice/service/base.js'

export class cart extends serBasic {
  sortkind0 = false

  /**
   * @description: 立即購買
   */
  async addBuyNow(value) {
    const config = await new base().getConfigInfo()
    if (datatype.isNull(config.ConfigInfo.storeNbr)) return super._error()

    value['storeNbr'] = config.ConfigInfo.storeNbr

    const obj = new repBuyNowInfo()
    await obj.put(value)

    return super._success()
  }

  /**
   * @description: 獲取立即購買數據
   */
  async getBuyNow() {
    const config = await new base().getConfigInfo()
    if (datatype.isNull(config.ConfigInfo.storeNbr)) return super._error()

    const obj = new repBuyNowInfo()
    obj.where('storeNbr').equals(config.ConfigInfo.storeNbr)

    let data = await obj.get(false)

    return (datatype.isNull(data)) ? super._error() : super._success(data)
  }

  /**
   * @description: 新增購物車數據
   */
  async addCart(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    let carts = JSON.stringify(value)

    let data = await new request().connectNsuv().post('', { fun: 'cart', op: 'addCart' }, { Carts: carts, userNbr: userNbr })

    if (!datatype.isNull(data)) {
      this.delCartList()
      return super._success(data)
    }

    return super._error()
  }

  /**
   * @description: 修改購物車數據
   */
  async updCart(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    let carts = JSON.stringify(value)

    let data = await new request().connectNsuv().post('', { fun: 'cart', op: 'updCart' }, { Carts: carts, userNbr: userNbr })

    if (!datatype.isNull(data)) {
      this.delCartList()

      return super._success()
    }

    return super._error()
  }

  /**
   * @description: 刪除購物車數據
   */
  async delCart(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    value = { ...value, ...{ userNbr: userNbr } }

    let data = await new request().connectNsuv().post('', { fun: 'cart', op: 'delCart' }, value)

    if (!datatype.isNull(data)) {
      this.delCartList()
      return super._success()
    }

    return super._error()
  }

  async delCartList() {
    let userNbr = new account().getAuth()
    if (!userNbr) return false

    const obj = new repCart()
    obj.where('userNbr').equals(userNbr)
    await obj.del()
  }

  /**數組根據數組對象中的某個屬性值進行排序的方法
  * 使用例子：newArray.sort(sortByArr(['number'],false)) //表示根據number屬性降序排列;若第二個參數不傳遞，默認表示升序排序
  * @param attr 排序的屬性 ['name','sex'...],根據一個字段或者多個字段排序
  * @param rev true表示升序排列，false降序排序
  * */
  _sortByArr(arr, rev) {
    if (rev == undefined) {
      rev = 1;
    } else {
      rev = (rev) ? 1 : -1;
    }
    return function (a, b) {
      for (var i = 0; i < arr.length; i++) {
        let attr = arr[i]
        if (a[attr] != b[attr]) {
          if (a[attr] > b[attr]) {
            return rev * 1;
          } else {
            return rev * -1;
          }
        }
      }
    }
  }


  /**
   * @description: 獲取購物車列表
   */
  async getCartList(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    const obj = new repCart()
    obj.setTickKey('cartTick|' + userNbr)

    obj.where('userNbr').equals(userNbr)

    let data = await obj.genData(new request().connectNsuv(), '', { fun: 'cart', op: 'getCartList' }, { ...value, ...{ userNbr: userNbr } }, (data) => {
      if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
        data['CartList'] = dataTrans.minDictRows(data.Dict, data.Rows)
      }

      data['userNbr'] = userNbr
      return data
    })

    if (!datatype.isNull(data)) {
      //返回值處理
      let cpgCartList = data.CpgList
      let soldOutCartList = []
      let noCpgCartList = []
      let cartList = datatype.isNull(data.CartList) ? [] : JSON.parse(JSON.stringify(data.CartList))

      //根據kind排序
      if (!datatype.isNull(cartList)) {
        cartList.sort((a, b) => {
          return a.kind - b.kind
        })
        log.addLog('cartList---sort', cartList)

        for (let k of cartList) {
          if (k.stat == 0) {
            //下架
            soldOutCartList.push(k)
          } else {
            if (k.kind == 0) {
              //主商品
              if (k.cpgNbr) {
                //包含活動
                for (let l in cpgCartList) {
                  if (k.cpgNbr == cpgCartList[l].cpgNbr) {
                    if (!datatype.isNull(cpgCartList[l]['CartList'])) {
                      cpgCartList[l]['CartList'].push(k)
                    } else {
                      cpgCartList[l]['CartList'] = [k]
                    }
                  }
                }
              } else {
                //無活動
                noCpgCartList.push(k)
              }

            } else if (k.kind == 2) {
              log.addLog('主商品已插入---')
              log.addLog('cpgCartList----1', cpgCartList)
              log.addLog('noCpgCartList----1', noCpgCartList)
              log.addLog('soldOutCartList---1', soldOutCartList)

              if (!this.sortkind0) {
                //對主商品排序
                cpgCartList.sort(this._sortByArr(['ptNbr'], false))
                noCpgCartList.sort(this._sortByArr(['ptNbr'], false))
                soldOutCartList.sort(this._sortByArr(['ptNbr'], false))

                this.sortkind0 = true
              }
              //加價購
              function insertAddition(ptInfo, PtList, field) {
                if (field) {
                  if (!datatype.isNull(PtList)) {
                    outer: for (let i in PtList) {
                      for (let j in PtList[i][field]) {
                        if (PtList[i][field][j].kind == 0) {
                          if (PtList[i][field][j].ptNbr == ptInfo.parPtNbr) {
                            PtList[i][field].splice(Number(j) + 1, 0, ptInfo);
                            break outer
                          }
                        }

                      }

                    }
                  }

                } else {
                  if (!datatype.isNull(PtList)) {
                    for (let i in PtList) {
                      if (PtList[i].kind == 0) {
                        if (PtList[i].ptNbr == ptInfo.parPtNbr) {
                          PtList.splice(Number(i) + 1, 0, ptInfo);
                          break
                        }
                      }

                    }
                  }
                }

                return PtList
              }
              cpgCartList = insertAddition(k, cpgCartList, 'CartList')

              noCpgCartList = insertAddition(k, noCpgCartList)

              if (!datatype.isNull(soldOutCartList)) {
                for (let i in soldOutCartList) {
                  if (soldOutCartList[i].ptNbr == k.parPtNbr) {
                    k.kind = 0
                    soldOutCartList.push(k)
                  }
                }
              }
              log.addLog('cpgCartList----2', cpgCartList)
              log.addLog('noCpgCartList----2', noCpgCartList)
              log.addLog('soldOutCartList---2', soldOutCartList)
            }

          }
        }
      }

      //移除沒有購物車商品的活動列表
      if (!datatype.isNull(cpgCartList)) {
        for (let i in cpgCartList) {
          if (datatype.isNull(cpgCartList[i].CartList)) {
            cpgCartList.splice(i, 1)
          }
        }

      }

      return super._success({ CartList: cartList, SoldOutCartList: soldOutCartList, NoCpgCartList: noCpgCartList, CpgCartList: cpgCartList })
    }

    return super._error()
  }

  /**
   * @description: 獲取購物車商品數量
   */
  async getCartCnt(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    value = { ...value, ...{ userNbr: userNbr } }

    let data = await new request().connectNsuv().post('', { fun: 'cart', op: 'getCartCnt' }, value)

    return datatype.isNull(data) ? super._error() : super._success({ cnt: data.cnt })
  }

  /**
   * @description: 獲取結算結果
   */
  async getCheckoutResult(value) {
    let userNbr = new account().getAuth()
    if (!userNbr) return super._error()

    let carts = JSON.stringify(value)

    let data = await new request().connectNsuv().post('', { fun: 'cart', op: 'getCheckoutResult' }, { Carts: carts, userNbr: userNbr })

    return datatype.isNull(data) ? super._error() : super._success(data)
  }
}

