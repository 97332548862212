//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon, Image, Tag, Button, Checkbox, InputNumber } from "element-ui";
import { ctrlOrder } from "@/controller";
export default {
    components: {
        [Image.name]: Image,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [InputNumber.name]: InputNumber,
    },
    props: {
        data: Object,
        isHeader: Boolean,
        //退貨模式
        afterSale: {
            type: Boolean,
            default: false,
        },
        stepper: {
            type: Boolean,
            default: false,
        },
        checkbox: {
            type: Boolean,
            default: false,
        },
        soNbr: {
            type: String,
        },
        SodList: Array,
    },
    name: "GoodsItem",
    data() {
        return {};
    },
    watch: {},
    mounted() {},
    created() {},
    methods: {
        goBeAfter(ln) {
            let _ln;
            for (const item of this.SodList) {
                item.ln == ln ? (item.isChecked = true) : (item.isChecked = false);
                item.currNum = item.qtyOrd;
                item.disabled = false;
                if (item.kind == 0) {
                    _ln = item.ln;
                } else {
                    item.parent = _ln;
                }
            }
            ctrlOrder.addAfter({ SodList: this.SodList, soNbr: this.soNbr });
        },
        checkboxChange(item) {
            this.$emit("checkboxChange", item);
        },
        stepperChange(value, item) {
            this.$emit("stepperChange", value, item);
        },
    },
};
