import { page } from "@/elearningservice/service/page.js";
import Common from "@/assets/js/common/common.js"; //公共方法
import store from "@/store/index.js";
import { form } from "@/hooks";
const hkForm = new form();
class ctrlPage {
	//獲取頁面數據
	async getPageInfo(value) {
		let data = await new page().getPageInfo(value);
		if (data.PageInfo && data.PageInfo.List) {
			for (const item of data.PageInfo.List) {
				if (item.name == "HvForm") {
					const form = await hkForm.getFormInfo(item.nbr);
					if (form) {
						item.fldList = form.fldList;
						item.formInfo = form.formInfo;
					}
				}
			}
		}
		if (data.response) return data;
	}
	//根據路由信息獲取頁面內容
	async getPageInfoByRouter(route) {
		if (route.name == "notfound" || route.name == "reset" || route.name == "test1") return;
		const params = route.params;
		const _storeNameSpace = "page";
		let ret = "";
		if (params.cpgNbr) {
			ret = params.cpgNbr;
		} else if (params.cpnNbr) {
			ret = params.cpnNbr;
		} else if (params.cpgNbr) {
			ret = params.cpgNbr;
		} else if (params.ptNbr) {
			ret = params.ptNbr;
		} else if (params.nbr) {
			ret = params.nbr;
		}
		let p1 = "";
		if (route.name == "home") {
			p1 = "home";
		} else if (route.name == "onePagePtInfo") {
			p1 = "item";
		} else {
			p1 = params.fun;
		}
		const p3 = ret.split("_")[0];
		let obj = {
			p1,
			p2: params.op ? params.op : "",
			p3: p3,
		};
		const page = await this.getPageInfo(obj);
		let headInfo,
			footInfo,
			navInfo = {};
		if (Common.isExist(page)) {
			store.dispatch(_storeNameSpace + "/setPageInfo", page.PageInfo);
			const headId = page?.PageInfo?.headId ?? null;
			const footId = page?.PageInfo?.footId ?? null;
			const navId = page?.PageInfo?.navId ?? null;
			[headInfo, footInfo, navInfo] = await this.getPageSet(headId, footId, navId);
			Common.isExist(headInfo) ? store.dispatch(_storeNameSpace + "/setHeadInfo", headInfo) : store.dispatch(_storeNameSpace + "/setHeadInfo", {});
			Common.isExist(footInfo) ? store.dispatch(_storeNameSpace + "/setFootInfo", footInfo) : store.dispatch(_storeNameSpace + "/setFootInfo", {});
			Common.isExist(navInfo) ? store.dispatch(_storeNameSpace + "/setNavInfo", navInfo) : store.dispatch(_storeNameSpace + "/setNavInfo", {});
		} else {
			store.dispatch(_storeNameSpace + "/setPageInfo", {});
			store.dispatch(_storeNameSpace + "/setHeadInfo", {});
			store.dispatch(_storeNameSpace + "/setFootInfo", {});
			store.dispatch(_storeNameSpace + "/setNavInfo", {});
		}
	}
	//獲取頭部
	async getHeadInfo(value) {
		let data = await new page().getHeadInfo(value);
		if (data.response) return data;
	}
	//獲取底部
	async getFootInfo(value) {
		let data = await new page().getFootInfo(value);
		if (data.response) return data;
	}
	//獲取底部導航
	async getNavInfo(value) {
		let data = await new page().getNavInfo(value);
		if (data.response) {
			for (const item of data.list) {
				if (item.path == "/cart") {
					item.badge = store.state.cart.cartNum;
				}
			}
			return data;
		}
	}
	async getPageSet(headId, footId, navId) {
		let headInfo = {},
			footInfo = {},
			navInfo = {};
		if (headId) {
			headInfo = await this.getHeadInfo({ headId });
		}
		if (footId) {
			footInfo = await this.getFootInfo({ footId });
		}
		if (navId) {
			navInfo = await this.getNavInfo({ navId });
		}
		return [headInfo, footInfo, navInfo];
	}
}

export default new ctrlPage();
