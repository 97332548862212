import { course } from "@/elearningservice/service/course.js";
import { datatype } from "@/library2/utils/datatype.js";
import router from "@/router/index.js";
class ctrlCourse {
	playerInterval = null;
	//獲取課程列表
	async getCourseList() {
		const data = await new course().getCourseList();
		if (data.response) {
			//todo mock
			const playbackList = await this.getPlaybackList();

			if (playbackList) {
				for (const course of data.CourseList) {
					course.playbackInfo = {};
					course.playbackList = [];
					for (const item of playbackList) {
						if (course.ptNbr == item.ptNbr) {
							course.playbackList.push(item);
						}
					}
					//找出time 最新更新時間的進度條信息對象
					if (course.playbackList.length) {
						course.playbackInfo = course.playbackList.reduce((accumulator, currentValue) => {
							return (accumulator = accumulator.time > currentValue.time ? accumulator : currentValue);
						});
						delete course.playbackList;
					}
				}
			}
			return data.CourseList;
		}
	}
	//獲取課程播放記錄列表
	async getPlaybackList() {
		const data = await new course().getPlaybackList();
		// const playbackList = [
		// 	{
		// 		chpdNbr: "uipkc8",
		// 		position: 223,
		// 		ptNbr: "1e1dpo7",
		// 		time: 1644313347,
		// 		title: "究竟頭發能不能紮破氣球？究竟頭發能不能紮破氣球究竟頭發能不能紮破氣球究竟頭發能不能紮破氣球",
		// 		userNbr: "3ai2vs2",
		// 	},
		// 	{
		// 		chpdNbr: "vh4qa0",
		// 		position: -1,
		// 		ptNbr: "1e1dpo7",
		// 		time: 1644313353,
		// 		title: "刀槍不入",
		// 		userNbr: "3ai2vs2",
		// 	},
		// ];
		// return playbackList;
		if (data.response) return data.PlaybackList;
	}
	//根據chpd獲取播放記錄
	async getPlaybackByChpd(chpdNbr) {
		const playbackList = await this.getPlaybackList();
		if (playbackList) {
			for (const playback of playbackList) {
				if (chpdNbr == playback.chpdNbr) {
					return playback.position;
				}
			}
		}
	}

	//獲取課程詳情
	async getCourseInfo(value) {
		const chpdNbr = value.chpdNbr || null;
		const data = await new course().getCourseInfo(value);
		if (data.response) {
			data.chpdCurr = await this.getChpdCurr(data, chpdNbr);
			data.videoList = this.getVideoList(data, chpdNbr);

			const playback = await this.getPlaybackList();
			if(playback) this.getPlaybackStat(data, playback);
			return data;
		}
	}
	//獲取每個課程進度 -1 已看完
	getPlaybackStat(courseInfo, playback) {
		if (datatype.isNull(courseInfo.ChapterList)) return;
		for (const item of courseInfo.ChapterList) {
			if (!datatype.isNull(item.ChpdList)) {
				for (const _item of item.ChpdList) {
					for (const pb of playback) {
						if (pb.chpdNbr == _item.chpdNbr) {
							_item.stat = pb.position;
						}
					}
				}
			}
		}
	}
	//獲取當前課程影片簡略信息
	async getChpdCurr(courseInfo, chpdNbr) {
		const chapterList = courseInfo.ChapterList;

		//課程列表不存在，無法匹配對應影片
		if (!chapterList.length) return (courseInfo.error = "該課程影片不存在！");
		let ret = null;
		//不存在chpdNbr 課程首位影片(不存在則順延下一個影片)信息的chpdNbr賦值chpdNbr且路由跳轉
		if (!chpdNbr) {
			for (const chapter of chapterList) {
				for (const chpd of chapter.ChpdList) {
					if (chpd.chpdNbr) {
						chpdNbr = chpd.chpdNbr;
						break;
					}
				}
			}
			const currentRoute = router.currentRoute;
			router.replace({ path: currentRoute.path, query: { chpdNbr } });
		}

		//帶出選中chpdNbr對應信息並賦予isPlayer屬性
		for (const chapter of chapterList) {
			for (const chpd of chapter.ChpdList) {
				if (chpd.chpdNbr == chpdNbr) {
					chpd.isPlayer = true;
					ret = chpd;
					break;
				}
			}
		}
		return ret;
	}
	//獲取該課程所有影片
	getVideoList(courseInfo, chpdNbr) {
		const chapterList = courseInfo.ChapterList;
		//課程列表不存在，無法匹配對應影片
		if (!chapterList.length) return [];
		let list = [];
		for (const chapter of chapterList) {
			if (!datatype.isNull(chapter.ChpdList)) {
				list.push(...chapter.ChpdList);
			}
		}

		return list;
	}
	//獲取當前課程影片信息 src
	async getChpdInfo(value) {
		const data = await new course().getChpdInfo(value);
		if (data.response) return data;
	}

	//播放心跳 播放中n秒請求一次，開始、暫停、繼續、停止時請求一次
	async heartbeat(value) {
		const data = await new course().heartbeat(value);
		if (data.response) return data;
	}

	async downLoad(value) {
		return await new course().downLoad(value);
	}
	// async getCommentList(value) {
	// 	console.log("getCommentList value");
	// 	console.log(value);
	// 	let CommentInfo = {
	// 		list: [
	// 			{
	// 				content: "夢蕓\n近況如何\n算來已有十月未見妳\n甚是思念",
	// 				visitor: {
	// 					name: "我叫白雲",
	// 					avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 					userNbr: "12h",
	// 					badge: "授課講師",
	// 				},
	// 				createAt: "2021-12-8",
	// 				likes: 1,
	// 				cmtNbr: 1,
	// 				isReplyTextShow: false,
	// 				replyPlaceholder: null,
	// 				childrenComments: [
	// 					{
	// 						content: "此刻我能聞見漫天火藥味道\n我隨軍藏身長江邊一暗無天日的地窖底\n埋首臺燈下寫這些字卻不知把心緒給寄向何地",
	// 						visitor: {
	// 							name: "NARUTO",
	// 							avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 							userNbr: "12h",
	// 						},
	// 						createAt: "2021-12-8",
	// 						cmtNbr: 1.1,
	// 					},
	// 					{
	// 						content: "\n如磐石般堅毅",
	// 						visitor: {
	// 							name: "我叫黑土",
	// 							avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 							userNbr: "12h",
	// 						},
	// 						createAt: "2021-12-8",
	// 						cmtNbr: 1.2,
	// 					},
	// 				],
	// 			},
	// 			{
	// 				content: "我想時光亦是用來磨的\n細細地磨慢慢地磨\n總能磨出些許墨香來",
	// 				visitor: {
	// 					name: "我叫黑土",
	// 					avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 					userNbr: "12h",
	// 				},
	// 				createAt: "2021-12-8",
	// 				cmtNbr: 2,
	// 				isReplyTextShow: false,
	// 				replyPlaceholder: null,
	// 				childrenComments: [
	// 					{
	// 						content: "即使我鼻子已不靈\n眼睛生出疾\n僥幸妳的照片還能辨出依稀",
	// 						visitor: {
	// 							name: "NARUTO",
	// 							avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 							userNbr: "12h",
	// 						},
	// 						createAt: "2021-12-8",
	// 						cmtNbr: 2.1,
	// 					},
	// 					{
	// 						content: "\n如磐石般堅毅",
	// 						visitor: {
	// 							name: "我叫黑土",
	// 							avatar: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
	// 							userNbr: "12h",
	// 						},
	// 						createAt: "2021-12-8",
	// 						cmtNbr: 2.2,
	// 					},
	// 				],
	// 			},
	// 		],
	// 		num:999
	// 	};
	// 	return CommentInfo
	// 	// const data = await new course().getCommentList(value);
	// 	// if (data.response) return data;
	// }
	// async comment(value) {
	// 	console.log("comment value");
	// 	console.log(value);
	// 	// const data = await new course().comment(value);
	// 	// if (data.response) return data;
	// }
}

export default new ctrlCourse();
