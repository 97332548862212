/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 10:04:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 */
import { courseList as repCourseList } from "@/elearningservice/repository/courseList.js";
import { playbackList as repPlaybackList } from "@/elearningservice/repository/playbackList.js";
import { chpdInfo as repChpdInfo } from "@/elearningservice/repository/chpdInfo.js";
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { account } from "@/elearningservice/service/account.js";
import { datatype } from "@/library2/utils/datatype.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { base } from "@/elearningservice/service/base.js";
import des from "@/library2/utils/des.js";
import md5 from "js-md5";
export class course extends serBasic {
	/**
	 * @description: 獲取課程列表
	 */
	async getCourseList() {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		const obj = new repCourseList();
		obj.setTickKey("courseListTick|" + nbr);
		obj.where("userNbr").equals(nbr);
		const req = new request().connectNsuv();

		let data = await obj.genData(req, "", { fun: "course", op: "getCourseList" }, { userNbr: nbr }, (data) => {
			if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
				data = dataTrans.minDictRows(data.Dict, data.Rows);
				data.map((item) => {
					item["userNbr"] = nbr;
				});
				return data;
			}
		});

		return datatype.isNull(data) ? super._error(data) : super._success({ CourseList: data ? super._toBeArray(data) : [] });
	}

	/**
	 * @description: 獲取播放記錄列表
	 */
	async getPlaybackList() {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		const obj = new repPlaybackList();
		obj.setTickKey("playbackListTick|" + nbr);
		obj.where("userNbr").equals(nbr);
		const req = new request().connectNsuv();

		let data = await obj.genData(req, "", { fun: "course", op: "getPlaybackList" }, { userNbr: nbr }, (data) => {
			if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
				data = dataTrans.minDictRows(data.Dict, data.Rows);
				data.map((item) => {
					item["userNbr"] = nbr;
				});
				return data;
			}
		});

		return datatype.isNull(data) ? super._error(data) : super._success({ PlaybackList: data ? super._toBeArray(data) : [] });
	}

	/**
	 * @description: 獲取課程詳情
	 */
	async getCourseInfo(value) {
		//獲取userNbr
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, ptNbr: value.ptNbr };

		let data = await new request().connectNsuv().post("", { fun: "course", op: "getCourseInfo" }, value);

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 獲取視頻詳情
	 */
	async getChpdInfo(value) {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, chpdNbr: value.chpdNbr };

		const obj = new repChpdInfo();
		obj.setTickKey("chpdInfoTick|" + nbr + "|" + value["chpdNbr"]);
		obj.where("[userNbr+chpdNbr]").equals([nbr, value["chpdNbr"]]);
		const req = new request().connectNsuv();

		let data = await obj.genData(req, "", { fun: "course", op: "getChpdInfo" }, value, (data) => {
			return data;
		});

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 播放心跳 播放中n秒請求一次，開始、暫停、繼續、停止時請求一次
	 */
	async heartbeat(value) {
		//獲取userNbr
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, ...value };

		let data = await new request().connectNsuv().post("", { fun: "course", op: "heartbeat" }, value);

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 下載
	 */
	async downLoad(value) {
		const { sn, funName, chpdNbr } = value;
		//獲取userNbr
		let nbr = new account().getAuth();
		if (!nbr) return super._error();
		const serBase = new base();
		let params = {
			appid: process.env.VUE_APP_APPID,
			fun: "course",
			nonce: "9527",
			op: funName,
			chpdNbr,
			sn,
			userNbr: nbr,
			version: process.env.VUE_APP_VERSION,
		};

		let key = `appid=${process.env.VUE_APP_APPID}&fun=course&nonce=${9527}&op=${funName}&chpdNbr=${value.chpdNbr}&sn=${value.sn}&userNbr=${nbr}&version=${process.env.VUE_APP_VERSION}`;

		const url = `${process.env.VUE_APP_PROMO_BASE_URL}index.php?${key}&sign=${serBase.genSign(params)}`;

		return url;
	}
}
