import { cpg } from "@/elearningservice/service/cpg.js";

class crtlCpg {
    async getCpgInfo(value) {
        const data = await new cpg().getCpgInfo(value);
        if (data.response) return data.CpgInfo;
    }
    async setCorpSrc(value) {
        const data = await new cpg().setCorpSrc(value);
        if (data.response) return data.corpDisplayName;
    }
}

export default new crtlCpg();
