import { comment } from "@/elearningservice/service/comment.js";
import redirect from "@/controller/redirect.js";
class ctrlComment {
	//獲取評論列表
	async getCommentList(switchValue, ptNbr, chpdNbr, page) {
		let value = {
			page,
			ptNbr,
		};
		if (switchValue) {
			value = {
				chpdNbr,
				...value,
			};
		}
		const data = await new comment().getCommentList(value);
		if (data.response) {
			for (const item of data.list) {
				item.isReplyTextShow = false;
			}
			return data;
		}
	}
	//獲取回覆列表
	async getReplyList(cmtNbr, page) {
		const data = await new comment().getReplyList({ cmtNbr, page });
		if (data.response) return data;
	}
	//新增評論
	async addComment(content, chpdNbr) {
		const data = await new comment().addComment({ content, chpdNbr });
		if (data.response) return data;
	}

	//新增留言回覆
	async addReply(content, cmtNbr) {
		const data = await new comment().addReply({ content, cmtNbr });
		if (data.response) return data;
	}
    clearCommentInfo(){
        new comment().clearCommentInfo();
    }
}

export default new ctrlComment();
