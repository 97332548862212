var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Common.isExist(_vm.config))?_c('div',{staticClass:"inner loginDialog"},[(_vm.config.AcctType.includes('mobile') || _vm.config.AcctType.includes('email'))?[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.changeBtnInfo.title))]),(_vm.changeBtnInfo.isLoginFormShow && _vm.config.AcctType.includes('mobile'))?_c('el-form',{ref:"userForm",attrs:{"model":_vm.userForm}},[[_c('el-form-item',{attrs:{"prop":"memMobile","rules":[
                            {
                                validator: _vm.msg.eleIsMobile,
                                trigger: 'blur',
                                required: true,
                                message: _vm.msg.mobile,
                            } ]}},[_c('el-autocomplete',{attrs:{"fetch-suggestions":_vm.querySearch,"placeholder":"手機號碼"},model:{value:(_vm.userForm.memMobile),callback:function ($$v) {_vm.$set(_vm.userForm, "memMobile", $$v)},expression:"userForm.memMobile"}})],1),_c('el-form-item',{attrs:{"prop":"smsCode","rules":[
                            {
                                trigger: 'blur',
                                required: true,
                                message: _vm.msg.req,
                            } ]}},[_c('el-input',{staticClass:"codeInput",attrs:{"placeholder":"簡訊驗證碼"},model:{value:(_vm.userForm.smsCode),callback:function ($$v) {_vm.$set(_vm.userForm, "smsCode", $$v)},expression:"userForm.smsCode"}},[_c('div',{class:{ disabled: _vm.disabled },attrs:{"slot":"append"},on:{"click":function($event){return _vm.getCode('userForm')}},slot:"append"},[_vm._v(" "+_vm._s(_vm.yzmBtnText)+" ")])])],1)]],2):_vm._e(),(!_vm.changeBtnInfo.isLoginFormShow && _vm.config.AcctType.includes('email'))?_c('el-form',{ref:"userForm2",attrs:{"model":_vm.userForm2}},[[_c('el-form-item',{attrs:{"prop":"memEmail","rules":[
                            {
                                validator: _vm.msg.eleIsEmail,
                                trigger: 'blur',
                                required: true,
                                message: _vm.msg.email,
                            } ]}},[_c('el-input',{attrs:{"placeholder":"電子信箱帳號"},model:{value:(_vm.userForm2.memEmail),callback:function ($$v) {_vm.$set(_vm.userForm2, "memEmail", $$v)},expression:"userForm2.memEmail"}})],1),_c('el-form-item',{attrs:{"prop":"passwd","rules":[
                            {
                                trigger: 'blur',
                                required: true,
                                message: _vm.msg.password,
                            } ]}},[_c('el-input',{attrs:{"show-password":"","placeholder":"密碼"},model:{value:(_vm.userForm2.passwd),callback:function ($$v) {_vm.$set(_vm.userForm2, "passwd", $$v)},expression:"userForm2.passwd"}})],1)]],2):_vm._e(),_c('div',{staticClass:"btn-grp"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onLogin}},[_vm._v(" 立即登入 ")]),(_vm.config.AcctType.includes('mobile') && _vm.config.AcctType.includes('email'))?_c('el-button',{on:{"click":_vm.changeBtn}},[_vm._v(" "+_vm._s(_vm.changeBtnInfo.name)+" ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.changeBtnInfo.value),expression:"!changeBtnInfo.value"}],staticClass:"login-form-operate",style:({ color: _vm.config.color })},[(_vm.config.AcctType.includes('email'))?_c('router-link',{attrs:{"to":"/account/register","replace":""}},[_vm._v("立即註冊")]):_vm._e(),_c('router-link',{attrs:{"to":"/account/lookup"}},[_vm._v("忘記密碼")])],1)]:_vm._e(),_c('div',{staticClass:"agreement-content"},[_vm._v(" 登入即代表您已同意"),_c('a',{attrs:{"href":_vm.config.privacyTerm,"target":"_blank"}},[_vm._v("服務協議")]),_vm._v(" 與 "),_c('a',{attrs:{"href":_vm.config.serviceTerm,"target":"_blank"}},[_vm._v("隱私政策")])]),_c('OtherLogin')],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }