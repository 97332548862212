import router from "@/router/index.js";
import cookie from "vue-cookie";
import store from "@/store";
class basic {
    constructor() {}
    getUserNbr() {
        return cookie.get("userNbr");
    }
}

export default basic;
