"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataTrans = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @Description: 數據轉化相關類
 * @FilePath: \shop_corp\src\library2ts\utils\dataTrans.ts
 */
var dataTrans = /** @class */ (function () {
    function dataTrans() {
    }
    /**
     * @description: 數字添加千分號
     * @param {number|string} num 數字
     * @param {boolean} decimal 返回值是否包含小數
     * @return {number}
     */
    dataTrans.numFormat = function (num) {
        var num2 = num.toString();
        var str_int = '';
        var str_int2 = '';
        //判斷是否有小數
        if (num2.indexOf('.') == -1) {
            str_int = num2;
        }
        else {
            var str_ar = num2.split('.');
            str_int = str_ar[0];
            str_int2 = str_ar[1];
        }
        //整數位小於等於三位，直接返回
        if (str_int.length <= 3)
            return num2;
        var i = str_int.length % 3;
        var s1 = str_int.substr(0, i); //前面不滿三位的數取出來
        var s2 = str_int.substr(i); //後面的滿三位的數取出來
        var new_str = '';
        for (var j = 0; j < s2.length; j += 3) {
            new_str = new_str ? new_str + ',' + s2.substr(j, 3) : s2.substr(j, 3); //三位三位取出再合並，按逗號隔開
        }
        var fin_str = s1 ? s1 + ',' + new_str : new_str;
        var fin_str2 = '';
        if (str_int2) {
            fin_str2 = fin_str + '.' + str_int2;
        }
        else {
            fin_str2 = fin_str;
        }
        return fin_str2;
    };
    /**
     * @description: 轉換json key
     */
    dataTrans.transMap = function (data, map) {
        var ret = {};
        for (var i in data) {
            if (map[i]) {
                ret[map[i]] = data[i];
            }
            else {
                ret[i] = data[i];
            }
        }
        return ret;
    };
    /**
     * @description: 轉換arr of json key
     */
    dataTrans.transArrMap = function (data, map) {
        var ar = [];
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var i = data_1[_i];
            ar.push(this.transMap(i, map));
        }
        return ar;
    };
    /**
     * @description: 數組去除為空元素
     * @param {Array} ar 數組
     * @return {Array}
     */
    dataTrans.arrayFilter = function (ar) {
        return ar.filter(function (e1) { return e1.length > 0; });
    };
    /**
     * @description: 數組去重
     * @param {Array} ar 數組
     * @return {Array}
     */
    dataTrans.arrayUnique = function (ar) {
        var set = new Set(ar);
        return Array.from(set);
    };
    /**
     * @description: 混合Dict和Rows
     * @param {Array} dict
     * @param {Array} rows
     * @return {Array}
     */
    dataTrans.minDictRows = function (dict, rows) {
        var ret = [];
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var rs = rows_1[_i];
            var json = {};
            for (var i in rs) {
                json[dict[i]] = rs[i];
            }
            ret.push(json);
        }
        return ret;
    };
    /**
     * @description:  刪除對象中某個屬性
     * @param {object} json
     * @param {string} prop
     * @return {object}
     */
    dataTrans.unset = function (json, prop) {
        var data = [];
        Object.keys(json).forEach(function (key) {
            if (prop instanceof Array) {
                if (prop.includes(key))
                    return true;
            }
            else {
                if (key == prop)
                    return true;
            }
            data[key] = json[key];
        });
        return data;
    };
    /**
     * @description: 取出數組對象中key為某個值的新數組
     * @param {Array} ar
     * @param {string} field
     * @return {Array}
     */
    dataTrans.arrayColumn = function (ar, field) {
        var ar2 = [];
        for (var _i = 0, ar_1 = ar; _i < ar_1.length; _i++) {
            var i = ar_1[_i];
            if (typeof (i[field]) != 'undefined') {
                ar2.push(i[field]);
            }
        }
        return ar2;
    };
    /**
     * @description:  去除首尾空格
     * @param {string} str
     * @return {string}
     */
    dataTrans.trim = function (str) {
        return str.replace(/(^\s*) | (\s*$)/g, '');
    };
    /**
     * @description: base64編碼
     * @param {string} commonContent
     * @return {string}
     */
    dataTrans.encodeBase64 = function (commonContent) {
        var base64Content = Buffer.from(commonContent).toString('base64');
        return base64Content;
    };
    /**
     * @description: base64解碼
     * @param {string} base64Content
     * @return {string}
     */
    dataTrans.decodeBase64 = function (base64Content) {
        var commonContent = base64Content.replace(/\s/g, '+');
        commonContent = Buffer.from(commonContent, 'base64').toString();
        return commonContent;
    };
    /**
     * @description: 判斷兩個數組是否相同
     * @param {Array} ar1
     * @param {Array} ar2
     * @return {boolean}
     */
    dataTrans.arrEquals = function (ar1, ar2) {
        return JSON.stringify(ar1.sort()) == JSON.stringify(ar2.sort());
    };
    dataTrans.install = function (components) {
        var install = function (Vue) {
            if (install.installed)
                return;
            components.map(function (component) { return Vue.component(component.name, component); });
        };
        if (typeof window !== "undefined" && window.Vue) {
            install(window.Vue);
        }
    };
    return dataTrans;
}());
exports.dataTrans = dataTrans;
