import { datatype } from "@/library2/utils/datatype.js";
//公用方法========================================================================================
/**
 * getRndInteger 隨機四位數
 */
const getRndInteger = (max = 9999, min = 1000) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};
/**
 * getRndInteger 添加千分號
 */
const numFormat = (num) => {
	let num2 = String(num);
	let str_int = "";
	let str_int2 = "";
	//判斷是否有小數
	if (num2.indexOf(".") == -1) {
		str_int = num2;
	} else {
		let str_ar = num2.split(".");
		str_int = String(str_ar["0"]);
		str_int2 = String(str_ar["1"]);
	}
	//整數位小於等於三位，直接返回
	if (str_int.length <= 3) return num2;
	let i = str_int.length % 3;
	let s1 = str_int.substr(0, i); //前面不滿三位的數取出來
	let s2 = str_int.substr(i); //後面的滿三位的數取出來
	let new_str = "";
	for (let j = 0; j < s2.length; j += 3) {
		new_str = new_str ? new_str + "," + s2.substr(j, 3) : s2.substr(j, 3); //三位三位取出再合並，按逗號隔開
	}
	let fin_str = s1 ? s1 + "," + new_str : new_str;
	let fin_str2 = "";
	if (str_int2) {
		fin_str2 = fin_str + "." + str_int2;
	} else {
		fin_str2 = fin_str;
	}
	return fin_str2;
};
//滾輪置頂
const scrollToTop = () => {
	return window.scrollTo(0, 1);
};
const scrollAnimation = (currentY, targetY) => {
	// 獲取當前位置方法
	// const currentY = document.documentElement.scrollTop || document.body.scrollTop

	// 計算需要移動的距離
	let needScrollTop = targetY - currentY;
	let _currentY = currentY;
	setTimeout(() => {
		// 一次調用滑動幀數，每次調用會不一樣
		const dist = Math.ceil(needScrollTop / 30);
		_currentY += dist;
		window.scrollTo(_currentY, currentY);
		// 如果移動幅度小於十個像素，直接移動，否則遞歸調用，實現動畫效果
		if (needScrollTop > 10 || needScrollTop < -10) {
			scrollAnimation(_currentY, targetY);
		} else {
			window.scrollTo(_currentY, targetY);
		}
	}, 1);
};
const isVal = {
	//手機號碼
	isMobile(value) {
		if (datatype.isNull(value)) return true;
		//判斷是否為數字
		if (isNaN(value)) return false;
		//去除頭部
		value = value.toString();
		if (value.substr(0, 1) == "0") value = value.substr(1);

		var l = value.length;

		if (l != 9 && l != 11) return false;

		var s = value.substr(0, 1);

		if (s != "1" && s != "9") return false;
		if (l == 11 && s != "1") return false;
		if (l == 9 && s != "9") return false;

		return true;
	},
	//電子信箱
	isEmail(value) {
		if (datatype.isNull(value)) return true;
		return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.[_a-z0-9-]+(\.[_a-z0-9-]+)*$/i.test(value);
	},
	//是否是數字
	isNumber(value) {
		if (datatype.isNull(value)) return true;

		return /^[0-9]+$/.test(value);
	},
	//密碼
	isPassword(value) {
		if (datatype.isNull(value)) return true;

		return /^[a-zA-Z\w]{6,16}$/.test(value);
	},
};
/**
 * 檢查 value 是否是 類對象。 如果一個值是類對象，那麽它不應該是 null，而且 typeof 後的結果是 "object"。
 * 註：數組屬於類對象！！！
 * @param {*} value 要檢查的值。
 * @returns {boolean} 如果 value 為一個類對象，那麽返回 true，否則返回 false。
 * @example
 *
 * isObjectLike({})
 * // => true
 *
 * isObjectLike([1, 2, 3])
 * // => true
 *
 * isObjectLike(Function)
 * // => false
 *
 * isObjectLike(null)
 * // => false
 */
const isObjectLike = (value) => {
	return typeof value === "object" && value !== null;
};

const getMessengerUrl = () => {
	sessionStorage.getItem("messengerUrl") ? window.open(sessionStorage.getItem("messengerUrl")) : window.open("https://m.me/FiedoraTW?ref=default");
};
// 倒計時
const countdown = (value) => {
	if (!value && value <= 0) {
		return false;
	}
	let msec = parseInt(value - Date.now() / 1000) * 1000;
	let _day = parseInt(msec / 1000 / 60 / 60 / 24);
	let _hr = parseInt((msec / 1000 / 60 / 60) % 24);
	let _min = parseInt((msec / 1000 / 60) % 60);
	let _sec = parseInt((msec / 1000) % 60);
	let day = _day;
	let hr = _hr > 9 ? _hr : "0" + _hr;
	let min = _min > 9 ? _min : "0" + _min;
	let sec = _sec > 9 ? _sec : "0" + _sec;
	if (msec <= 0) {
		return false;
	}
	return { day, hr, min, sec };
};
/**
 * 傳入變量（數字、字符串）、數組、對象等判斷是否為空
 * @param str 需要判斷是否為空的內容
 * @returns {boolean} 返回布爾值 true不為空，false為空
 */
const isExist = (str) => {
	var thisType = typeof str;

	if (str === "" || str === null || str === undefined) {
		//null、undefined
		//這裏之所以用全等於，因為：
		//1.JS裏，‘’ == 0 == [],會被判斷成相同，而下方針對數字0和空數組做出單獨處理，故此處只需要單獨判斷‘’
		//2.JS裏，typeof null == object,為簡化下方object處判斷邏輯，故此處需要用全等判斷null
		return false;
	} else if (thisType == "string" && str.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
		//string
		return false;
	} else if ((thisType == "number" && isNaN(str)) || str == 0) {
		//number
		return false;
	} else if (thisType == "object") {
		if (str instanceof Array) {
			//array
			return !(str.length == 0);
		} else {
			//object
			return !(JSON.stringify(str) == "{}");
		}
	}
	return true; //傳入str不為空
};
const enumType = () => {
	var couponType = {};

	couponType[(couponType["shareCollect"] = -3)] = "shareCollect";
	couponType[(couponType["clickCollect"] = -2)] = "clickCollect";
	couponType[(couponType["unCollect"] = -1)] = "unCollect";
	couponType[(couponType["unused"] = 0)] = "unused";
	couponType[(couponType["used"] = 1)] = "used";
	couponType[(couponType["invalid"] = 2)] = "invalid";

	return couponType;
};
const regTest = (value, reg) => {
	return reg.test(value);
};
const linkGoType = (link, linkType) => {
	if (regTest(link, /http/)) {
		linkType ? window.open(link, "_blank") : (window.location.href = link);
	}
};
const copyText = (value) => {
	let domUrl = document.createElement("input");
	domUrl.value = value;
	domUrl.id = "creatCopyDom";
	document.body.appendChild(domUrl);
	domUrl.select();
	document.execCommand("Copy");
	let creatDom = document.getElementById("creatCopyDom");
	creatDom.parentNode.removeChild(creatDom);

	return true;
};
//時間戳轉 hh:mm:ss
const timesFormat = (data) => {
	let time = Number(data);
	let h = Math.floor(time / 3600);
	let m = Math.floor((time % 3600) / 60);
	let s = parseInt(time % 3600) % 60;
	let hh = h < 10 ? "0" + h : h;
	let mm = m < 10 ? "0" + m : m;
	let ss = s < 10 ? "0" + s : s;
	return hh + ":" + mm + ":" + ss;
};
//秒數轉 hh:mm:ss
const secondsFormat = (data) => {
	let secondTime = parseInt(data); // 秒
	let minuteTime = 0; // 分
	let hourTime = 0; // 小時
	if (secondTime >= 60) {
		minuteTime = parseInt(secondTime / 60);
		secondTime = parseInt(secondTime % 60);
		if (minuteTime >= 60) {
			hourTime = parseInt(minuteTime / 60);
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	let result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime));

	result = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + ":" + result;
	result = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + ":" + result;
	return result;
};
export default {
	getRndInteger,
	numFormat,
	scrollToTop,
	scrollAnimation,
	isVal,
	isObjectLike,
	getMessengerUrl,
	countdown,
	isExist,
	enumType,
	linkGoType,
	copyText,
	timesFormat,
	secondsFormat,
};
