//
//
//
//
//
//
//

import Vue from "vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

import VueYouTubeEmbed from "vue-youtube-embed";
import { getIdFromURL, getTimeFromURL } from "vue-youtube-embed";
// Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" });
import pa from "../../../utils/package";
import { isExist } from "../../../utils";
export default {
    name: "HvVideo",
    components: {
        videoPlayer,
    },
    props: {
        data: Object,
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player;
        },
    },
    data() {
        return {
            isYoutube: false, //是否是youtube類型影片
            YoutubePlayer: "", //YoutubePlayer實例
            vid: "",
            playerOptions: {
                // videojs options
                muted: true,
                autoplay: true,
                fluid:true,
                // width: '100%',
                // language: 'en',
                // playbackRates: [0.7, 1.0, 1.5, 2.0],
                // sources: [{
                //     // type: "video/youtube",
                //     // src:'http://www.youtube.com/embed/xjS6SftYQaQ'
                //     type: "video/mp4",
                //     // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                //     src:"http://vjs.zencdn.net/v/oceans.mp4",
                // }],
                // poster: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
            },
        };
    },
    watch: {
        data: {
            handler(val, oldVal) {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        init() {
            if (!isExist(this.data.playerOptions)) return;
            let data = this.data;
            this.playerOptions = { ...this.playerOptions, ...data.playerOptions };
            this.playerOptions.sources = [
                {
                    type: this.data.playerOptions.type,
                    src: this.data.playerOptions.src,
                },
            ];
            this.isYoutube = pa.regTest(this.playerOptions.sources[0].type, /youtube/);
            if (this.isYoutube) {
                Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" });
                this.vid = getIdFromURL(this.playerOptions.sources[0].src);
            }
        },
        //初始化
        playerReadied(player) {
            this.$emit("playerReadied", player);
        },
        //播放觸發事件
        onPlayerPlay() {
            this.$emit("onPlayerPlay");
            // Carousel.methods.pauseTimer();
            // this.autoplay = false;
        },
        //Youtube影片初始化鉤子
        onYoutubeReady(event) {
            this.YoutubePlayer = event.target;
            this.$emit("onYoutubeReady", event.target);
        },
        //Youtube影片播放鉤子
        onYoutubePlaying() {
            this.$emit("onYoutubePlaying");
            // Carousel.methods.pauseTimer();
            // this.autoplay = false;
        },
    },
};
