//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Autocomplete, Button, Divider, Icon, Input, Popover, Select, Tag, Option } from "element-ui";
export default {
    name: "SearchCom",
    components: {
        [Autocomplete.name]: Autocomplete,
        [Input.name]: Input,
        [Icon.name]: Icon,
        [Button.name]: Button,
        [Popover.name]: Popover,
        [Tag.name]: Tag,
        [Divider.name]: Divider,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        searchTags: {
            type: Object,
            default: () => {},
        },
        searchTag: {
            type: Array,
            default: () => [],
        },
        historySearchTags: {
            type: Array,
            default: () => [],
        },
        width: {
            type: [String, Number],
            default: 350,
        },
    },
    data() {
        return {
            search: "",
            selectType: "1",
            restaurants: [],
            state: "",
            timeout: null,
        };
    },
    computed: {
        computedSearchValue() {
            if (this.searchValue) {
                return this.searchValue();
            } else {
                return false;
            }
        },
        tags() {
            if(this.isExist(this.searchTag)) {
                return this.searchTag
            }
            if (this.selectType == 1) {
                return this.searchTags?.goodsTags;
            } else if (this.selectType == 2) {
                return this.searchTags?.shopTags;
            }
            return [];
        },
    },
    watch: {
        computedSearchValue(value) {
            this.searchInput(value);
        },
    },
    created() {},
    mounted() {
        // document.querySelector(".header-search").setAttribute("style",  `width:${this.width}px;height:50px`);
        //  document.querySelector(".header-search").setAttribute("style", `width:${this.width}px`);
    },
    methods: {
        searchInput(value) {
            this.search = value;
        },
        searchChange() {
            this.$emit("searchChange", this.search, this.selectType);
        },
        hotTagClick(item) {
            this.$emit("hotTagClick", item, this.selectType);
        },
        hisTagClick(item) {
            this.$emit("hisTagClick", item, this.selectType);
        },
        deleteHis() {
            this.$emit("deleteHis");
        },
    },
};
