import { getFormInfo, addForm, getFormValueInfo,uploadFile } from "@/api/form";
import { basic } from "@/hooks";
import store from "@/store";
class form extends basic {
    constructor() {
        super();
    }
    //獲取獲取表單詳情
    async getFormInfo(nbr) {
        const res = await getFormInfo({ nbr });
        // console.log(res);
        // res.fldList.push(
        //         {
        //             fldCmmt: "",
        //             fldName: "單圖上傳",
        //             fldType: "image", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f14", //唯一標識
        //             placeHolder: "asd",
        //         },
        //         {
        //             fldCmmt: "",
        //             fldName: "多圖上傳",
        //             fldType: "images", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f14", //唯一標識
        //             placeHolder: "asd",
        //         },
        // )
        // const res = {
        //     // label: "表單",
        //     name: "HvForm",
        //     formInfo: {
        //         title: "表單標題2",
        //         banner: "https://img01.yzcdn.cn/vant/cat.jpeg",
        //         desc: "<div href='#'>desc html</div>",
        //         // header: "<div href='#'>頭部html</div>",
        //         // footer: "<div href='#'>底部html</div>",
        //         submitBttn: "提交按鈕文本",
        //         type: "1", //1普通表單 2投票 3問卷
        //         isLogin: true,
        //         isLimit: false,
        //         isValid: false,
        //         style:"soild",//block
        //     },
        //     fldList: [
        //         {
        //             fldCmmt: "",
        //             fldName: "單選名稱",
        //             fldOptions: [
        //                 {
        //                     name: "是",
        //                     check: true,
        //                 },
        //                 {
        //                     name: "否",
        //                     check: false,
        //                 },
        //             ],
        //             fldType: "radio",
        //             isNotNull: 0,
        //             key: "f1",
        //             placeHolder: "",
        //             reqMsg: "", //未填寫提示信息
        //         },
        //         {
        //             fldCmmt: "備註信息111",
        //             fldName: "單選圖片",
        //             fldOptions: [
        //                 {
        //                     name:"AA",
        //                     img:"https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
        //                     check: false,
        //                 },
        //                 {
        //                     name:"BB",
        //                     img:"https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg",
        //                     check: true,
        //                 },
        //                  {
        //                     name:"CC",
        //                     img:"https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg",
        //                     check: false,
        //                 }
        //             ],
        //             fldType: "radioImages",
        //             isNotNull: 1,
        //             key: "f2",
        //             placeHolder: "",
        //             reqMsg: "", //未填寫提示信息
        //         },
        //         {
        //             fldCmmt: "備註信息111",
        //             fldName: "多選欄名稱",
        //             fldOptions: [
        //                 {
        //                     name: "選項A",
        //                     check: true,
        //                 },
        //                 {
        //                     name: "選項B",
        //                     check: true,
        //                 },
        //             ], //選項
        //             fldType: "checkbox", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f3", //唯一標識
        //             placeHolder: "",
        //             reqMsg: "", //未填寫提示信息
        //         },
        //         {
        //             fldCmmt: "",
        //             fldName: "多選欄圖片",
        //              fldOptions: [
        //                 {
        //                     name:"AA",
        //                     img:"https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
        //                 },
        //                 {
        //                     name:"BB",
        //                     img:"https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg",
        //                 },
        //                  {
        //                     name:"CC",
        //                     img:"https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg",
        //                 }
        //             ],
        //             fldType: "checkboxImages", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f4", //唯一標識
        //             placeHolder: "點擊選擇",
        //             reqMsg: "", //未填寫提示信息
        //         },
        //         {
        //             fldCmmt: "",
        //             fldName: "文本框",
        //             fldType: "input", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f5", //唯一標識
        //             placeHolder: "請填寫",
        //             reqMsg: "必填！！！", //未填寫提示信息
        //         },
        //         {
        //             fldCmmt: "",
        //             fldName: "郵箱框",
        //             fldType: "email", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f6", //唯一標識
        //             placeHolder: "郵箱填寫",
        //             reqMsg: "", //未填寫提示信息
        //             isRelation: true,
        //         },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "數字框（支持小數）",
        //         //     fldType: "number", //類型
        //         //     isNotNull: 0, //是否必填
        //         //     key: "f7", //唯一標識
        //         //     placeHolder: "數字填寫",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "整數",
        //         //     fldType: "digit", //類型
        //         //     isNotNull: 0, //是否必填
        //         //     key: "f8", //唯一標識
        //         //     placeHolder: "整數填寫",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         {
        //             fldCmmt: "",
        //             fldName: "手機號碼",
        //             fldType: "tel", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f9", //唯一標識
        //             placeHolder: "手機號碼填寫",
        //             reqMsg: "", //未填寫提示信息
        //             isRelation:true
        //         },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "密碼框",
        //         //     fldType: "password", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f10", //唯一標識
        //         //     placeHolder: "密碼填寫",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "文本域",
        //         //     fldType: "textarea", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f11", //唯一標識
        //         //     placeHolder: "",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "日期",
        //         //     fldType: "date", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f12", //唯一標識
        //         //     placeHolder: "asd",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "評分",
        //         //     fldType: "rate", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f13", //唯一標識
        //         //     placeHolder: "asd",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "",
        //         //     fldName: "附件上傳",
        //         //     fldType: "uploader", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f14", //唯一標識
        //         //     placeHolder: "asd",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "備註信息111",
        //         //     fldName: "下拉名稱",
        //         //     fldOptions: [
        //         //        {
        //         //             name: "選項A",
        //         //             check: false,
        //         //         },
        //         //         {
        //         //             name: "選項B",
        //         //             check: true,
        //         //         },
        //         //     ], //選項
        //         //     fldType: "select", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f15", //唯一標識
        //         //     placeHolder: "",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         // {
        //         //     fldCmmt: "備註信息111",
        //         //     fldName: "級聯名稱",
        //         //     fldOptions: [
        //         //         {
        //         //             name: "浙江",
        //         //             children: [
        //         //                 {
        //         //                     name: "杭州",
        //         //                     children: [{ name: "西湖區" }, { name: "余杭區" }],
        //         //                 },
        //         //                 {
        //         //                     name: "溫州",
        //         //                     children: [{ name: "鹿城區" }, { name: "甌海區" }],
        //         //                 },
        //         //             ],
        //         //         },
        //         //         {
        //         //             name: "福建",
        //         //             children: [
        //         //                 {
        //         //                     name: "福州",
        //         //                     children: [{ name: "鼓樓區" }, { name: "臺江區" }],
        //         //                 },
        //         //                 {
        //         //                     name: "廈門",
        //         //                     children: [{ name: "思明區" }, { name: "海滄區" }],
        //         //                 },
        //         //             ],
        //         //         },
        //         //     ], //選項
        //         //     fldType: "cascader", //類型
        //         //     isNotNull: 1, //是否必填
        //         //     key: "f16", //唯一標識
        //         //     placeHolder: "",
        //         //     reqMsg: "", //未填寫提示信息
        //         // },
        //         {
        //             fldCmmt: "",
        //             fldName: "文本框",
        //             fldType: "user", //類型
        //             isNotNull: 1, //是否必填
        //             key: "f17", //唯一標識
        //             placeHolder: "填寫",
        //             reqMsg: "必填！！！", //未填寫提示信息
        //             isRelation: true,
        //         },
        //     ],
        // };
        return res;
    }
    //獲取獲取表單詳情
    async addForm(formInfo, nbr, formNbr = null) {
        return await addForm({ formInfo: JSON.stringify(formInfo), nbr, formNbr });
    }
    //獲取獲取表單詳情
    async getFormValueInfo(nbr) {
        if(!this.getUserNbr()) return
        return await getFormValueInfo({ nbr, userNbr: this.getUserNbr() });
    }
    //獲取圖片上傳地址
    async uploadFile(upload, nbr, fileType) {
        return await uploadFile({ upload, nbr, fileType });
    }
}

export default form;
