import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { datatype } from "@/library2/utils/datatype.js";
import { account as serAcct } from "@/elearningservice/service/account.js";
import { statCpnList as repStatCpnList } from "@/elearningservice/repository/statCpnList.js";
import { userCpnList as repUserCpnList } from "@/elearningservice/repository/userCpnList.js";
import { base } from '@/elearningservice/service/base.js'

export class coupon extends serBasic {
    /**
     * @description: 根據訂單編號領取優惠券
     */
    async getCouponByOrder(value) {
        const soNbr = value["soNbr"];
        if (!soNbr) return super._error();

        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "getCouponByOrder" }, { soNbr: soNbr, userNbr: userNbr });
        if (!data) return super._error();

        this.delCoupons();

        let ret = this._dealCpnRet(data);

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }

    /**
     * @description: 根據優惠券編號領取優惠券
     */
    async collectCoupon(value) {
        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "collectCoupon" }, { cpnNbrs: value["cpnNbrs"], scene: value["scene"], userNbr: userNbr });
        if (!data) return super._error();

        this.delCoupons();

        let ret = this._dealCpnRet(data);

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }

    /**
     * @description: 根據優惠券編號獲取優惠券列表
     */
    async getCouponByCpn(value) {
        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "getCouponByCpn" }, { cpnNbrs: value["cpnNbrs"] });
        if (!data) return super._error();

        let ret = this._dealCpnRet(data);

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }

    /**
     * @description: 獲取用戶優惠券列表
     */
    async getCouponByNewUser() {
        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        const obj = new repUserCpnList();
        obj.setTickKey("ptUserListTick|" + userNbr);

        obj.where("userNbr").equals(userNbr);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "coupon", op: "getCouponByNewUser" }, { userNbr: userNbr }, (data) => {
            let ret = this._dealCpnRet(data);
            ret["userNbr"] = userNbr;
            return ret;
        });

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 獲取優惠券列表
     */
    async getCouponList(value) {
        const useStat = value["useStat"];

        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        const obj = new repStatCpnList();
        obj.setTickKey("statCpnListTick|" + userNbr + "|" + useStat);
        obj.where("[userNbr+useStat]").equals([userNbr, useStat]);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "coupon", op: "getCouponList" }, { useStat: useStat, userNbr: userNbr }, (data) => {
            let ret = this._dealCpnRet(data);
            ret["useStat"] = useStat;
            ret["userNbr"] = userNbr;

            return ret;
        });

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 輸入優惠碼返回優惠金額
     */
    async getPromoAmt(value) {
        const promoCode = value["promoCode"];
        if (!promoCode) return super._error();

        const ptNbrs = value["ptNbrs"];
        if (!ptNbrs) return super._error();

        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "getPromoAmt" }, { promoCode: promoCode, ptNbrs: ptNbrs, userNbr: userNbr });

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 根據優惠券獲取贈品數據
     */
    async getGiftByCoupon(value) {
        const couponNbr = value["couponNbr"];
        if (!couponNbr) return super._error();

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "getGiftByCoupon" }, { couponNbr: couponNbr });

        return datatype.isNull(data) ? super._error() : super._success({ GiftInfo: data.GiftInfo });
    }

    /**
     * @description: 根據優惠券類別獲取優惠券信息
     */
    async getCpnInfo(value) {
        const cpnNbr = value["cpnNbr"];
        if (!cpnNbr) return super._error();

        let userNbr = "";
        try {
            userNbr = await new serAcct().getAuth();
        } catch (error) {}

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "getCpnInfo" }, { cpnNbr: cpnNbr, userNbr: userNbr });
        if (!data) return super._error();

        let ret = this._dealCpnRet(data);
        ret["CpnInfo"] = data.CpnInfo;

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }

    _dealCpnRet(value) {
        let bak = JSON.parse(JSON.stringify(value));

        let ret = {};
        if (!datatype.isNull(value["Dict"]) && !datatype.isNull(value["Rows"])) {
            ret["list"] = dataTrans.minDictRows(value["Dict"], value["Rows"]);
        }

        if (bak.id) ret["id"] = bak.id;
        if (bak.name) ret["name"] = bak.name;
        if (bak.type) ret["type"] = bak.type;
        if (bak.styleType) ret["styleType"] = bak.styleType;

        return ret;
    }

    /**
     * @description: 刪除本地優惠券
     */
    delCoupons() {
        const obj2 = new repStatCpnList();
        obj2.clear();

        const obj3 = new repUserCpnList();
        obj3.clear();
    }

    /**
     * @description: 獲取可用和不可用優惠券列表
     */
    async getCouponByCouponNbr(value) {
        let res = await this.getCouponList({ useStat: 0 });
        if (!res["response"]) return super._error();

        let allCouponList = res["list"];
        let couponList = [];
        let unAvailCouponList = [];

        //可用優惠券列表
        if (value["CouponNbrs"]) {
            for (let i of value["CouponNbrs"]) {
                for (let j of allCouponList) {
                    if (i == j.couponNbr) {
                        couponList.push(j);
                    }
                }
            }
        }

        //不可用優惠券列表
        if (value["UnAvailCouponNbrs"]) {
            for (let i of value["UnAvailCouponNbrs"]) {
                for (let j of allCouponList) {
                    if (i == j.couponNbr) {
                        unAvailCouponList.push(j);
                    }
                }
            }
        }

        return super._success({ CouponList: couponList, UnAvailCouponList: unAvailCouponList });
    }

    /**
     * @description: 獲取新用戶優惠
     * @return {Promise<object>}
     */
    async collectCouponByUser(configInfo) {
        if (!configInfo) return super._error();
        if (!configInfo.hasNewUserCoupon) return super._success();

        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();

        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "collectCouponByUser" }, { userNbr: userNbr });

        let ret = this._dealCpnRet(data);

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }

    /**
     * @description: 根據訂單編號領取優惠券
     * @return {Promise<object>}
     */
    async collectCouponByOrder({ soNbr }) {
        const configInfo = await new base().getConfigInfo()
        
        if (datatype.isNull(configInfo)) return super._error();
        if (!configInfo.ConfigInfo.hasOrderCoupon) return super._success();

        const userNbr = await new serAcct().getAuth();
        if (!userNbr) return super._error();
        
        let data = await new request().connectNsuv().post("", { fun: "coupon", op: "collectCouponByOrder" }, { soNbr, userNbr });

        let ret = this._dealCpnRet(data);

        return datatype.isNull(ret) ? super._error() : super._success(ret);
    }
}
