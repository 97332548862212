import { account } from "@/elearningservice/service/account.js";
import { captcha } from "@/elearningservice/service/captcha.js";
import loginError from "@/exception/loginError.js";
import route from "@/router/index.js";
import { Message, Loading } from "element-ui";
import store from "@/store/index.js";
class ctrlAccount {
    //檢測登入
    chkLogin() {
        const data = new account().chkLogin();
        return data.response
    }
    //根據傳值判斷登入
    chkLoginStat(value) {
        if (!value) {
            loginError.goLogin();
            return true;
        } else {
            return false;
        }
    }
    //獲取校驗圖片
    async getSliderCaptcha() {
        const data = await new captcha().getSliderCaptcha({ dtu: "m" });
        if (data.response) return data;
    }
    //圖片校驗結果
    async chkSliderCaptchaCode(value) {
        return await new captcha().chkSliderCaptchaCode(value);
    }
    //登出
    async signout() {
        const data = await new account().signout();
        if (data.response) {
            Message.success({
                duration: 900,
                type: "success",
                message: "已登出",
            });
            store.dispatch("cart/setCartNum", 0);
            store.dispatch("base/setHasCollectCouponByUser", false);
            route.push({ path: "/" });
            return data;
        }
    }
    //取得會員非敏感數據
    async getInsenMemInfo() {
        //會員非敏感數據
        return await new account().getInsenMemInfo();
    }
    //取得會員敏感數據
    async getSenMemInfo(flag = true) {
        //會員敏感數據
        return await new account().getSenMemInfo();
    }
    //根據編號獲取會員電子信箱
    async getEmailByNbr(value) {
        const data = await new account().getEmailByNbr(value);
        if (data.response) return data.memEmail;
    }
    //更新會員敏感資料
    async updMemInfo(value) {
        let data = await new account().updMemInfo(value);
        if (data.response) return data;
    }
    //重設密碼
    async passwdReset(value) {
        let data = await new account().passwdReset(value);
        if (data.response) return data;
    }
    //驗證帳號是否存在
    async chkAcctExist(value) {
        return await new account().chkAcctExist(value);
    }
    //第三方帳號登入
    async oauthInit(value) {
        let data = await new account().oauthInit(value);
        if (data.response) return data;
    }
    //手機號碼登入
    async smsLogin(value) {
        let data = await new account().smsLogin(value);
        console.log(data);
        if (data.response) return data;
    }
    //電子信箱登入
    async userLogin(value) {
        let data = await new account().userLogin(value);
        if (data.response) return data;
    }
    //獲取驗證碼
    async getSmsCode(value) {
        let data = await new account().getSmsCode(value);
        if (data.response) return data;
    }
    //註冊
    async emailRegister(value) {
        let data = await new account().emailRegister(value);
        if (data.response) return data;
    }
    //忘記密碼
    async lookup(value) {
        let data = await new account().lookup(value);
        if (data.response) return data;
    }
}

export default new ctrlAccount();
