//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Image, Message, Tag } from "element-ui";
import { ctrlCpn } from "@/controller";
import { couponMixin } from "@/mixins/coupon";
export default {
    name: "couponItem",
    mixins: [couponMixin],
    components: {
        [Button.name]: Button,
        [Image.name]: Image,
        [Tag.name]: Tag,
    },
    props: {
        data: Object,
        source: String,
        isShowBtn: Boolean,
        boder: Boolean,
    },
    data() {
        return {};
    },
    methods: {
        async collectCoupon(cpnNbrs, scene) {
            const data = await ctrlCpn.collectCoupon({ cpnNbrs, scene });
            console.log(data);
            if (data) {
                Message.success("領取成功");
                this.$emit("collectCoupon", true);
            }
        },
        //分享領券
        async shareCollect(cpnNbr) {
            let obj = {
                cpnNbr,
                ptNbr: this.ptNbr,
            };
            this.cpgNbr && (obj.cpgNbr = this.cpgNbr);
            await this.mix_getShareUrl(obj);
            console.log(this.shareUrl);
        },
    },
};
