import Vue from "vue";
import VueRouter from "vue-router";
import { ctrlAccount,ctrlPage } from "@/controller";
import loginError from "@/exception/loginError.js";
const Home = () => import("@/views/Home");
const Goods = () => import("@/views/Goods");
const Checkout = () => import("@/views/Checkout");
const Category = () => import("@/views/Category");
const Pay = () => import("@/views/Pay");
const PayResult = () => import("@/views/PayResult");
const OrderDetail = () => import("@/views/OrderDetail");
const After = () => import("@/views/After");
const Login = () => import("@/views/Login");
const Register = () => import("@/views/Register");
const Forget = () => import("@/views/Forget");
const Reset = () => import("@/views/Reset");
const Menu = () => import("@/views/Menu");
const Address = () => import("@/views/Address");
const Profile = () => import("@/views/Profile");
const Coupon = () => import("@/views/Coupon");
const Order = () => import("@/views/Order");
const ReturnApply = () => import("@/views/ReturnApply");
const ReturnList = () => import("@/views/ReturnList");
const Cart = () => import("@/views/Cart");
const Promo = () => import("@/views/Promo");
const Info = () => import("@/views/Info");
const Courses = () => import("@/views/Courses");
const CourseDetail = () => import("@/views/CourseDetail");
const SearchRet = () => import("@/views/SearchRet");
const Test1 = () => import("@/views/Test/test1.vue");
const form = () => import("@/views/form");
const NotFound = () => import("@/views/NotFound");
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
	},
	{
		path: "/test1",
		name: "test1",
		component: Test1,
	},
	//商品詳情頁
	{
		path: "/:fun(item)/:corpNbr/:ptNbr",
		name: "ptInfo",
		component: Goods,
		props: true,
	},
	//一頁式商品詳情頁
	{
		path: "/:fun(buy)/:corpNbr/:ptNbr",
		name: "onePagePtInfo",
		component: Goods,
		props: true,
	},
	//商品詳情頁-預覽
	{
		path: "/:fun(preview)/item/:ptNbr",
		name: "preview",
		component: Goods,
		props: true,
	},
	//結算頁
	{
		path: "/:fun(shop)/:op(checkout)",
		name: "checkout",
		component: Checkout,
		meta: {
			title: "確認訂單",
			requireAuth: true,
		},
	},
	//支付頁
	{
		path: "/:fun(order)/:op(pay)/:nbr",
		name: "pay",
		component: Pay,
		meta: {
			title: "訂單付款",
			requireAuth: true,
		},
	},
	//線下支付結果頁
	{
		path: "/:fun(order)/:op(payResult)/:nbr",
		name: "payResult",
		component: PayResult,
		meta: {
			title: "訂單送出",
			requireAuth: true,
		},
	},
	//促銷/推廣 頁
	{
		path: "/:fun(campaign)/:corpNbr/:cpgNbr",
		name: "promo",
		component: Promo,
	},
	//優惠券推廣
	{
		path: "/:fun(promo)/:corpNbr/:cpnNbr",
		name: "promo",
		component: Promo,
	},
	//訂單詳情頁面
	{
		path: "/:fun(order)/:op(detail)/:nbr",
		name: "orderDetail",
		component: OrderDetail,
		meta: {
			title: "訂單詳情",
			requireAuth: true,
		},
	},
	//訂單詳情頁面-脫敏
	{
		path: "/:fun(orderd)/:nbr",
		name: "orderd",
		component: OrderDetail,
		meta: {
			title: "訂單詳情",
		},
	},
	//訂單詳情頁面-脫敏
	{
		path: "/:fun(orderc)/:nbr",
		name: "orderc",
		component: OrderDetail,
		meta: {
			title: "訂單詳情",
		},
	},
	//地址頁面
	// {
	//   path: "/:fun(user)/:op(address)",
	//   name: "address",
	//   component: Address,
	//   meta: {
	//     title: '地址訊息',
	//   }
	// },
	{
		path: "/:fun(user)",
		name: "menu",
		component: Menu,
		children: [
			{
				path: "/:fun(order)/:op(index)",
				component: Order,
				name: "order",
				meta: {
					title: "我的訂單",
					requireAuth: true,
				},
			},
			{
				path: "/:fun(course)/:op(index)",
				component: Courses,
				name: "courses",
				meta: {
					title: "我的課程",
					requireAuth: true,
				},
			},
			{
				path: ":op(coupon)",
				component: Coupon,
				name: "coupon",
				meta: {
					title: "折扣券",
					requireAuth: true,
				},
			},
			// {
			//     path: ":op(address)",
			//     component: Address,
			//     name: "address",
			//     meta: {
			//         title: "收貨地址",
			//     },
			// },
			{
				path: ":op(profile)",
				component: Profile,
				name: "profile",
				meta: {
					title: "個人資訊",
					requireAuth: true,
				},
			},
		],
	},
	//退貨申請入口
	{
		path: "/:fun(order)/:op(after)/:nbr",
		name: "after",
		component: After,
		props: true,
		meta: {
			title: "退貨申請",
			requireAuth: true,
		},
	},
	//退貨申請詳情
	{
		path: "/:fun(order)/:op(refund)/:nbr",
		name: "returnApply",
		component: ReturnApply,
		meta: {
			title: "退貨申請",
			requireAuth: true,
		},
	},
	//退貨詳情
	{
		path: "/:fun(order)/:op(refundInfo)/:nbr",
		name: "returnList",
		component: ReturnList,
		props: true,
		meta: {
			title: "退貨明細",
			requireAuth: true,
		},
	},
	{
		path: "/:fun(account)/:op(login)",
		name: "login",
		component: Login,
		meta: {
			title: "登入",
		},
	},
	{
		path: "/:fun(account)/:op(register)",
		name: "register",
		component: Register,
		meta: {
			title: "註冊",
		},
	},
	//忘記密碼
	{
		path: "/:fun(account)/:op(lookup)",
		name: "lookup",
		component: Forget,
		meta: {
			title: "忘記密碼",
		},
	},
	//重設密碼
	{
		path: "/:fun(account)/:op(reset)/:nbr?",
		name: "reset",
		component: Reset,
		meta: {
			title: "重設密碼",
		},
	},
	//購物車
	// {
	//     path: "/:fun(cart)",
	//     name: "cart",
	//     component: Cart,
	//     meta: {
	//         title: "購物車",
	//     },
	// },
	//表單頁
    {
        path: "/:fun(form)/:nbr?",
        name: "form",
        props:true,
        component: form,
        meta: {
            title: "表單",
        },
    },
	//咨詢頁
	{
		path: "/:fun(info)/:nbr",
		name: "info",
		component: Info,
	},
	//分類頁
	{
		path: "/:fun(items)/:nbr",
		name: "category",
		component: Category,
	},
	//搜索商品頁
    {
        path: "/:fun(search)",
        name: "searchRet",
        component: SearchRet,
    },
	//課程詳情頁面
	{
		path: "/:fun(course)/:op(detail)/:ptNbr",
		name: "courseDetail",
		component: CourseDetail,
		props: true,
		meta: {
			title: "課程詳情",
			requireAuth: true,
		},
	},
	{
		path: "*",
		name: "notfound",
		component: NotFound,
		meta: {
			title: "找不到當前頁面",
		},
	},
];

// 解決兩次訪問相同路由地址報錯
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
	mode: "history",
	// base: process.env.BASE_URL,
	routes,
	// scrollBehavior(to, from, savedPosition) {
	// 	return { x: 0, y: 0 };
	// },
});

router.beforeEach(async(to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	// //需要記錄上一個頁面來源的頁面
	// const routeNameList1 = ["courses","courseDetail","checkout", "pay", "payResult", "addressForm", "invoiceForm", "address", "coupon", "order", "orderDetail", "after", "returnApply", "imember", "profile", "cart", "promo", "ptInfo", "preview", "returnList"];
	// //需要記錄上一個頁面來源的頁面,不需要登入權限
	// const routeNameList2 = ["promo", "ptInfo", "preview"];

	// if (routeNameList1.includes(to.name)) {
	//     sessionStorage.setItem("herf_link", to.path);

	//     if (!routeNameList2.includes(to.name)) {
	//         const data = ctrlAccount.chkLogin();
	//         if (!data) loginError.goLogin();
	//     }
	// }
	// next();
	//根據路由信息獲取頁面內容
    await ctrlPage.getPageInfoByRouter(to)
	
	const requireAuth = to.meta.requireAuth;
	if (requireAuth) {
		sessionStorage.setItem("herf_link", to.path);

		const data = ctrlAccount.chkLogin();
		if (!data) loginError.goLogin();
	}
	next();
});

export default router;
