import { request as basicReq } from "@/library2/basicClass/request.js";
import des from "@/library2/utils/des.js";
import axios from "axios";
import { log } from "@/library2/utils/log.js";
import { connectError } from "@/exception/connectError.js";
import { datatype } from "@/library2/utils/datatype.js";
import md5 from "js-md5";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { captcha } from "@/elearningservice/repository/captcha.js";

export class request {
	_pending = [];
	captchaFlag = false;

	setCaptcha() {
		this.captchaFlag = true;
		return this;
	}

	_setPending(key, v) {
		window.sessionStorage.setItem(JSON.stringify(key), JSON.stringify(v));
	}

	_getPending(val) {
		let data = window.sessionStorage.getItem(JSON.stringify(val));
		if (!data) return false;

		data = JSON.parse(data);
		if (!data) return false;

		return true;
	}

	_sort(json) {
		let ar = [];
		for (let i in json) {
			ar.push(i);
		}

		let ar2 = ar.sort();

		let json2 = {};
		for (let i of ar2) {
			json2[i] = json[i];
		}

		return json2;
	}

	_removePending(ever) {
		this._setPending(ever.params, false);
	}

	//生成隨機
	_genNonce() {
		return (Math.random() * 10000) >> 0;
	}

	_genSign(params) {
		//排序
		let json = this._sort(params);

		//數組轉json
		let str = "";
		for (let i in json) {
			if (i == "sign") continue;
			if (json[i] instanceof File) continue;

			if (json[i] instanceof Array || json[i] instanceof Object) {
				json[i] = JSON.stringify(json[i]);
				// json[i] = json[i].replace(/\//g,'\\\/')
			}
			// if(typeof json[i] == "string" && json[i].indexOf('function') != -1){
			//   json[i] = json[i].replace(/\//g,'\\\/')
			// }

			if (json[i] === "" || json[i] === null || json[i] === "undefined") continue;
			json[i] = String(json[i]); //轉成字符串
			json[i] = json[i].replace(/(^\s*) | (\s*$)/g, "");

			str = str + (str === "" ? "" : "&") + i + "=" + json[i];
		}

		//拼接
		if (process.env.VUE_APP_APPSECRET) {
			str = str + "&key=" + dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY));
		}

		log.addLog("參與驗簽字符串", str);

		//md5
		return md5(str);
	}

	connectNsuv(options = {}) {
		//baseurl
		const baseUrl = process.env.VUE_APP_PROMO_BASE_URL ? process.env.VUE_APP_PROMO_BASE_URL : "";

		const obj = new basicReq(baseUrl);

		//動態引入mock
		if (process.env.NODE_ENV == "development" && !baseUrl) {
			try {
				import("../mock/index.js");
			} catch (e) {
				//TODO handle the exception
			}
		}

		const nonce = this._genNonce();

		obj.httpService.interceptors.request.use(
			async (request) => {
				// request.cancelToken = new axios.CancelToken((c) => {
				// 	const arr = ["heartbeat", "getPtByTag"];
				// 	if (arr.includes(request.params.op)) return;
				// 	const pending = this._getPending(request.params);
				// 	if (pending) {
				// 		c("Warning Repeated requests-----" + JSON.stringify(request.params));
				// 		this._setPending(request.params, false);
				// 	}

				// 	this._setPending(request.params);
				// });

				// setTimeout(() => {
				// 	this._removePending(request);
				// }, 1500);

				if (request.method == "post") {
					let formData = request.data;
					formData.append("nonce", nonce);
					if (process.env.VUE_APP_APPID) {
						formData.append("appid", process.env.VUE_APP_APPID);
					}
					if (process.env.VUE_APP_VERSION) {
						formData.append("version", process.env.VUE_APP_VERSION);
					}

					//驗證碼
					if (this.captchaFlag) {
						try {
							const _captcha = await new captcha().get("cpatcha");
							if (_captcha) {
								formData.append("captchaCode", _captcha.captchaCode);
								formData.append("verifyCode", _captcha.verifyCode);
								if (datatype.isNull(options["encryptFields"])) {
									options["encryptFields"] = ["captchaCode"];
								} else {
									options["encryptFields"].push("captchaCode");
								}
							}
						} catch (error) {}
					}

					let keys = [];
					formData.forEach((v, k) => {
						if (v instanceof File) return true; //文件類型

						if (datatype.isNull(v)) {
							keys.push(k);
						}
					});

					// log.addLog('待刪除的form key', keys)
					for (let i of keys) {
						formData.delete(i);
					}

					if (!datatype.isNull(options["encryptFields"]) || !datatype.isNull(options["decryptFields"])) {
						formData.set("algo", 3);
					}

					if (!datatype.isNull(options["encryptFields"])) {
						if (datatype.isArray(options["encryptFields"])) {
							let key = dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY));
							if (nonce) key = key + nonce;

							for (let i of options["encryptFields"]) {
								let rs = formData.get(i);
								if (!rs) continue;
								let cryptRs = des.desEncode(dataTrans.encodeBase64(rs), key);
								formData.set(i, cryptRs);
							}
						}

						options["encryptFields"] = []; //清空
					}

					let json = {};
					formData.forEach((v, k) => {
						if (typeof v == "string" && v.indexOf("\r") !== -1) {
							v = v.replace(/\r/g, "");
							formData.set(k, v);
						}

						json[k] = v;
					});

					let params = { ...request.params, ...json };
					let sign = this._genSign(params);

					log.addLog(JSON.stringify(request.params) + " sign--", sign);

					formData.append("sign", sign);

					//開發環境下輸出log
					// if(process.env.VUE_APP_DEBUG) {
					//   let values = formData.entries()

					//   log.addLog('post requrest body begin===')
					//   for (let i of values) {
					//      log.addLog('key='+i['0']+' '+'value='+i['1'])
					//   }
					//   log.addLog('post requrest body end===')
					// }
				}

				log.addLog(JSON.stringify(request.params) + "  請求攔截器", request); //開發環境下輸出

				return request;
			},
			(error) => Promise.reject(error)
		);

		//響應攔截器
		obj.httpService.interceptors.response.use(
			async (response) => {
				if (response.config.method == "post") {
					// ElementUI.Loading.service().close()   //close loading
				}

				//baseUrl存在 去做驗證
				// log.addLog('本地驗簽------開始')
				if (baseUrl) {
					//驗證nonce
					if (response.data.nonce != nonce) {
						return connectError.errOnce();
					}

					//
					// let sign =  this._genSign(response.data)

					// log.addLog('數據生成的sign', sign)
					// log.addLog('回傳的sign', response.data.sign)

					// if(sign != response.data.sign){
					//   return connectError.errSign()
					// }
				}
				// log.addLog('本地驗簽------結束')

				this._removePending(response.config);

				log.addLog(JSON.stringify(response.config.params) + "  響應攔截器", response); //開發環境下輸出

				if (!datatype.isNull(options["decryptFields"]) && baseUrl) {
					let key = dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY));

					if (response.data.nonce) key = key + response.data.nonce;
					if (datatype.isJson(options["decryptFields"])) {
						for (let i in response.data) {
							if (options["decryptFields"][i] && datatype.isArray(options["decryptFields"][i])) {
								if (i == "Rows") {
									let data2 = dataTrans.minDictRows(response.data.Dict, response.data.Rows);
									let newRows = [];
									for (let j of data2) {
										for (let k in j) {
											if (options["decryptFields"][i].includes(k)) {
												j[k] = dataTrans.decodeBase64(des.desDecode(j[k], key));
											}
										}
										let res = Object.values(j);
										newRows.push(res);
									}
									response.data["Rows"] = newRows;
								} else {
									for (let j in response.data[i]) {
										if (options["decryptFields"][i].includes(j)) {
											// console.log("key-----------");
											// console.log(key);
											// console.log("response.data[i][j]-----------------");
											// console.log(response.data[i][j]);
											response.data[i][j] = dataTrans.decodeBase64(des.desDecode(response.data[i][j], key));
										}
									}
								}
							}
						}
					} else if (datatype.isArray(options.decryptFields)) {
						for (let i in response.data) {
							if (options["decryptFields"].includes(i)) {
								response.data[i] = dataTrans.decodeBase64(des.desDecode(response.data[i], key));
							}
						}
					}

					options["decryptFields"] = []; //清空
				}

				return response;
			},
			(error) => Promise.reject(error)
		);

		return obj;
	}
}
