//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from 'element-ui';
import pa from "../../../utils/package";
export default {
    name:"HvTitle",
    components:{
        [Icon.name]:Icon
    },
    props: {
        data: Object,
    },
    computed:{
    },
    watch:{
        // data: { 
        //     immediate: true, // 很重要！！！ 
        //     handler (val) { //todo }
        //         console.log(6767);
        //     }
        // }
    },
    data(){
        return{
        }
    },
    created(){
    },
    mounted(){
    },
    methods:{
        linkGoType(link,linkType){
            pa.linkGoType(link,linkType)
        }
    }
}
