//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Fragment } from "vue-fragment";
import { mapState, mapActions,mapGetters } from "vuex";
// import { ctrlAccount } from "@/controller";
import { Menu, Submenu, MenuItem, MenuItemGroup, Icon, Dropdown, DropdownMenu, DropdownItem, Image, Badge } from "element-ui";
import pa from "../../../utils/package";
import { isExist } from "../../../utils";
export default {
	name: "navBar",
	components: {
		[Menu.name]: Menu,
		[Submenu.name]: Submenu,
		[MenuItem.name]: MenuItem,
		[MenuItemGroup.name]: MenuItemGroup,
		[Icon.name]: Icon,
		[Dropdown.name]: Dropdown,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
		[Image.name]: Image,
		[Badge.name]: Badge,
		Fragment,
	},
	props: {
		list: Array,
	},
	computed: {},
	data() {
		return {
			isLoginCom: false,
			isLogin: false,
			isExist
		};
	},
	computed: {
		// ...mapState({
		// 	userInfo: function(state) {
		// 		return state?.user?.userInfo ?? {};
		// 	},
		// }),
        ...mapGetters({
            userInfo: "localStore/getUserInfo",
        }),
		//購物車數量
		// cartNum() {
		//     return this.$store.state.cart.cartNum;
		// },
	},
	watch: {
		// cartNum() {
		//     for (const item of this.list) {
		//         if (item.path == "/cart") {
		//             this.$set(item, "badge", this.cartNum);
		//         }
		//     }
		// },
	},
	created() {
		this.isLoginCom = this.list.some((item) => item.path == "/account/login");
	},
	methods: {
		// handleCommand(val) {
		//     if (val == "signout") {
		//         return this.getSignout();
		//     }
		//     this.$router.push(val);
		// },
		// async getSignout() {
		//     const data = await ctrlAccount.signout();
		//     if (data) this.$store.dispatch("user/setUserInfo", {});
		// },
		go(path) {
			pa.linkGoType(path);
			return false;
		},
	},
};
