import { account } from "@/elearningservice/service/account.js";
import { cart } from "@/elearningservice/service/cart.js";
import route from "@/router/index.js";
import { Message } from "element-ui";
import loginError from "@/exception/loginError.js";
import Common from "@/assets/js/common/common.js"; //公共方法
import store from "@/store/index.js";
class ctrlCart {
    //立即購買
    async addBuyNow(value) {
        await new cart().addBuyNow(value);
        sessionStorage.setItem("herf_link", `/shop/checkout`);
        route.push({ path: "/shop/checkout" });
        return true;
    }
    async getBuyNow() {
        const data = await new cart().getBuyNow();

        if (data.response) {
            for (const item of data.PtList) {
                if (Common.isExist(item.Optd)) {
                    item.Optd.kind = 0;
                }
                if (Common.isExist(item.Addition)) {
                    for (const addi of item.Addition) {
                        addi.kind = 2;
                    }
                }
                if (Common.isExist(item.Access)) {
                    for (const acc of item.Access) {
                        acc.kind = 3;
                    }
                }
            }
            return data;
        }
    }
    //加入購物車
    async addCart(value, flag = true) {
        
        //檢查是否登入
        const ret = new account().chkLogin();
        if (!ret.response) loginError.goLogin();

        const data = await new cart().addCart(value);
        //臨時/cart
        if (data.response) {
            if (flag) Message.success("添加成功");
            store.dispatch("cart/setCartNum", await this.getCartCnt());

            return data.response;
        }
    }
    //獲取購物車數量
    async getCartCnt() {
        const data = await new cart().getCartCnt();
        return data.cnt;
    }
    //獲取購物車列表
    async getCartList() {
        const data = await new cart().getCartList();
        console.log("購物車列表數據------------------------");
        console.log(data);
        if (data.response) {
            for (const item of data.CartList) {
                item.checked = false;
            }
            return data;
        }
    }
    //計算優惠信息（贈品、金額）等 購物車列表頁
    async getCheckoutResultByCart(value) {
        // console.log(value);
        //獲取地址列表
        let data = await new cart().getCheckoutResult(value);
        if (data.response) return data;
    }
    //計算優惠信息（贈品、金額）等 購物車列表頁 -計算頁
    async getCheckoutResultByCheck(value) {
        let obj = {};
        const handleData = (cart, data, kind) => {
            if (data.cartNbr) {
                obj = {
                    cartNbr: data.cartNbr,
                    qtyOrd: data.qtyOrd,
                };
            } else {
                obj = {
                    skuId: data.skuId,
                    qtyOrd: data.qtyOrd,
                    kind: kind,
                };
                if (kind == 0) {
                    obj = { ...obj, ptNbr: cart.ptNbr };
                } else {
                    obj = { ...obj, parPtNbr: cart.ptNbr };
                }
                if (cart.cpgNbr) obj = { ...obj, cpgNbr: cart.cpgNbr };
            }
            return obj;
        };

        const ret = [];
        for (const cart of value) {
            obj = {};
            //主商品拼接
            if (Common.isExist(cart.Optd)) {
                ret.push(handleData(cart, cart.Optd, 0));
            }

            // 選購拼接
            if (Common.isExist(cart.Addition)) {
                for (const addi of cart.Addition) {
                    // addi.id ? addi.skuId = addi.id : addi.skuId = addi.skuId
                    obj = handleData(cart, addi, 2);
                    ret.push(obj);
                }
            }
        }
        // console.log(ret);

        let data = await new cart().getCheckoutResult(ret);
        if (data.response) return data;
    }
    //刪除
    async delCart(value) {
        //獲取地址列表
        let data = await new cart().delCart(value);
        if (data.response) return data;
    }
    //修改
    async updCart(value) {
        const Carts = [];
        Carts.push(value);

        //獲取地址列表
        let data = await new cart().updCart(Carts);
        if (data.response) return data;
    }
}

export default new ctrlCart();
