/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 09:44:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\info.ts
 */
import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { datatype } from '@/library2/utils/datatype.js'
import { info as repInfo } from '@/elearningservice/repository/info.js'

export class info extends serBasic {
  /**
   * @description: 獲取資訊
   */
  async getInfo(value){
    const obj = new repInfo()
    obj.setTickKey('infoTick|' + value['nbr'])
    obj.where('nbr').equals(value['nbr'])

    let data = await obj.genData(new request().connectNsuv(), '', { fun: 'info', op: 'getInfo' }, { nbr: value['nbr'] }, (data) => {
      data['nbr'] = value['nbr']

      return data
    })

    return datatype.isNull(data) ? super._error() : super._success(data)
  }
}

