import { account } from "@/elearningservice/service/account.js";
import { coupon } from "@/elearningservice/service/coupon.js";
import ctrlAccount from "@/controller";
import loginError from "@/exception/loginError.js";

class ctrlCpn {
    //獲取優惠券列表數據
    async getCouponList() {
        let due = []; //已過期優惠券
        let unused = []; //未使用優惠券
        let used = []; //已使用

        //未使用優惠券
        let resUnused = await new coupon().getCouponList({ useStat: 0 });
        if (resUnused.response) unused = resUnused.list;

        //已使用
        let resUsed = await new coupon().getCouponList({ useStat: 1 });
        if (resUsed.response) used = resUsed.list;

        //已過期優惠券
        let resDue = await new coupon().getCouponList({ useStat: 2 });
        if (resDue.response) due = resDue.list;

        return [unused, used, due];
    }
    //根據產品編號獲取優惠券列表
    async getCouponByCpn(value) {
        let data = await new coupon().getCouponByCpn(value);
        // console.log(data);
        if (data.response) return data.list;
    }
    //根據CouponNbr獲取可用不可用優惠券列表
    async getCouponByCouponNbr(value) {
        let couponList1 = []; //可用優惠券
        let couponList2 = []; //不可用優惠券
        const data = await new coupon().getCouponByCouponNbr(value);
        console.log(data);
        if (data.response) {
            couponList1 = data.CouponList;
            for (const item of couponList1) {
                item.selected = true;
            }
            couponList2 = data.UnAvailCouponList;
        }
        return [couponList1, couponList2];
    }

    async collectCouponByUser(configInfo) {
        let data = await new coupon().collectCouponByUser(configInfo);
        if (data.response) return data.list;
    }
    //根據訂單編號返回優惠券
    async collectCouponByOrder(value) {
        let data = await new coupon().collectCouponByOrder(value);
        if (data.response) return data.list;
    }
    async collectCoupon(value) {
        //檢查是否登入
        const ret = new account().chkLogin();
        if (!ret.response) loginError.goLogin();

        let data = await new coupon().collectCoupon(value);
        if (data.response) return data.list;
    }
    //優惠碼返回優惠金額
    async getPromoAmt(value) {
        let data = await new coupon().getPromoAmt(value);
        if (data.response) {
            return data.amtPromo;
        }

        return 0;
    }
}

export default new ctrlCpn();
