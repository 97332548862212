/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 10:40:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\invoice.ts
 */
import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { invList } from '@/elearningservice/repository/invList.js'
import { datatype } from '@/library2/utils/datatype.js'
import { dataTrans } from '@/library2/utils/dataTrans.js'
import { account as serAcct } from '@/elearningservice/service/account.js'

export class invoice extends serBasic {
  /**
   * @description: 獲取發票列表
   */
  async getInvList(){
    const userNbr = await new serAcct().getAuth()
    if (!userNbr) return super._error()

    const obj = new invList()
    obj.setTickKey('invListTick|' + userNbr)

    obj.where('userNbr').equals(userNbr)

    let data = await obj.genData(new request().connectNsuv(), '', { fun: 'shop', op: 'getInvList' }, { userNbr: userNbr }, (data) => {

      if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
        data = dataTrans.minDictRows(data.Dict, data.Rows)

        data.map((item) => {
          item['userNbr'] = userNbr
        })
        return data
      }

      return data
    })

    if (!datatype.isNull(data)) {
      if (!datatype.isArray(data)) data = [data]
      return super._success({ InvList: data })
    }

    return super._error()
  }

  /**
   * @description: 刪除發票相關數據
   */
  async delStorageInvs() {
    const userNbr = await new serAcct().getAuth()
    if (!userNbr) return false

    const obj = new invList()
    obj.where('userNbr').equals(userNbr)

    obj.del()
  }

  /**
   * @description: 修改發票信息
   */
  async updInvInfo(value){
    const userNbr = await new serAcct().getAuth()
    if (!userNbr) return super._error()

    let params = {
      soNbr: value['soNbr'],
      invType: value['invType'],
      userNbr: userNbr
    }
    if (value['invTitle']) params['invTitle'] = value['invTitle']
    if (value['taxNo']) params['taxNo'] = value['taxNo']

    let data = await new request().connectNsuv().post('', { fun: 'order', op: 'updInvInfo' }, params)

    if (!datatype.isNull(data)) {
      this.delStorageInvs()
      return super._success()
    }

    return super._error()
  }

}
