
export class basic{
    namespaced=true
  
    constructor(){
    }
  
  }
  
  export default basic
  