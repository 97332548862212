//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Fragment } from 'vue-fragment';
import {
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Image,
  Badge,
} from 'element-ui';
export default {
  name: 'NavBarItem',
  components: {
    [Menu.name]: Menu,
    [Submenu.name]: Submenu,
    [MenuItem.name]: MenuItem,
    [MenuItemGroup.name]: MenuItemGroup,
    [Icon.name]: Icon,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Image.name]: Image,
    [Badge.name]: Badge,
    Fragment,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    go(path) {
        this.Common.linkGoType(path);
        return false;
    },
  },
};
