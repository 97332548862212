//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ctrlBase } from "@/controller";
import { Image, Message } from "element-ui";
import { couponMixin } from "@/mixins/coupon";
export default {
    name: "Share",
    mixins: [couponMixin],
    components: {
        [Image.name]: Image,
    },
    props: {
        text: {
            type: String,
            default: "分享到：",
        },
        grpmInfo: {
            type: Object,
            default: () => {},
        },
        ptNbr: String,
        cpgNbr: String,
        ptName: String,
    },
    data() {
        return {
            icon: [
                {
                    name: "fb",
                },
                // {
                //     name:'messenger',
                // },
                // {
                //     name: "Line",
                // },
            ],
        };
    },
    watch: {},
    computed: {
        currUrl() {
            return window.location.href;
        },
    },
    mounted() {},
    created() {},
    methods: {
        simpleShare(type, { name }) {
            // 不是簡單分享往外拋出標識 todo MessBtn...
            if (type != "simple") return;
            // console.log(name);

            if (name == "fb") {
                this.fbShare();
            }

            if (name == "Line") {
                this.lineShare();
            }
        },
        async fbShare() {
            ctrlBase.gaSentEvent('商品頁', 'FB分享', this.ptName)
            let obj = {
                ptNbr: this.ptNbr,
            };
            this.cpgNbr && (obj.cpgNbr = this.cpgNbr);
            await this.mix_getShareUrl(obj);
        },
        lineShare() {
            window.open(`https://social-plugins.line.me/lineit/share?url=${this.currUrl}`, "_blank");
        },
        //複製地址
        copying() {
            ctrlBase.gaSentEvent('商品頁', '複製鏈接分享', this.ptName)
            let domUrl = document.createElement("input");
            domUrl.value = window.location.href;
            domUrl.id = "creatDom";
            document.body.appendChild(domUrl);
            domUrl.select();
            document.execCommand("Copy");
            let creatDom = document.getElementById("creatDom");
            creatDom.parentNode.removeChild(creatDom);
            Message.success({
                message: "複製成功",
            });
        },
    },
};
