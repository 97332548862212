//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Image, Radio, RadioGroup, Message, Dialog } from "element-ui";
import QRCode from "qrcodejs2";
import { ctrlPay } from "@/controller";

export default {
    name: "Payway",
    components: {
        [Image.name]: Image,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Dialog.name]: Dialog,
    },
    props: {
        orderDtl: Object,
        onlyOnline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tmpPayNbr: "", //當前選中付款方式Nbr
            wxpayShow: false,
            payList: [],
            submitbar: false,
            successStat: ["pledge"], //付款成功狀態
            payCheck:"",//付款備註
        };
    },
    mounted() {
        if (this.$route.query.paynowNbr) {
            Message.warning(`${this.$route.query.paynowNbr} 未完成付款`);
        }
    },
    created() {
        this.getPayList();
    },
    methods: {
        //獲取付款方式
        async getPayList() {
            this.payList = await ctrlPay.getPayList();
            if (this.Common.isExist(this.payList)) {
                this.tmpPayNbr = this.payList[0].payNbr;
                this.payCheck = this.payList[0].desc;
            }
        },
        payChange(desc) {
            this.payCheck = desc;
        },
        creatQrCode(text = "https://element.eleme.cn/#/zh-CN/component/select") {
            new QRCode(this.$refs.qrCodeUrl, {
                text: text, // 需要轉換為二維碼的內容
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        async pay() {
            this.submitbar = true;
            this.$emit("getSubmitbar", this.submitbar);
            setTimeout(() => {
                this.submitbar = false;
                this.$emit("getSubmitbar", this.submitbar);
            }, 1500);
            let payInfo = await ctrlPay.pay(this.orderDtl.SoInfo.soNbr, this.tmpPayNbr);
            if (payInfo) {
                //網頁微信付款
                if (payInfo["op"] == "wxpay") {
                    this.wxpayShow = true;
                    if (!this.qrcode) {
                        this.$nextTick(() => {
                            this.creatQrCode(payInfo.qrcode);
                            this.qrcode = true;
                        });
                    }
                }

                //輪詢
                if (!this.dt) {
                    this.dt = setInterval(() => {
                        setTimeout(async () => {
                            //獲取訂單明細
                            let stat = await ctrlOrder.getSoStat(this.orderDtl.SoInfo.soNbr);

                            if (this.successStat.includes(stat)) {
                                this.$router.push({ path: "/order/detail/" + this.orderDtl.SoInfo.soNbr });
                            }
                        }, 0);
                    }, this.polling * 1000);
                }
            }
        },
    },
};
