/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 10:04:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\cg.ts
 */
import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { cgPtList as serCgPtList } from '@/elearningservice/repository/cgPtList.js'
import { datatype } from '@/library2/utils/datatype.js'
import { dataTrans } from '@/library2/utils/dataTrans.js'

export class cg extends serBasic {
  /**
   * @description: 獲取商品列表
   */
  async getPtList(value) {
    const obj = new serCgPtList()
    obj.setTickKey('cgPtTick|' + value['cgNbr'])
    obj.where('cgNbr').equals(value['cgNbr'])

    let data = await obj.genData(new request().connectNsuv(), '', { fun: 'category', op: 'getPtList' }, { cgNbr: value.cgNbr }, data => {
      if (!datatype.isNull(data.Dict)) {
        data = dataTrans.minDictRows(data.Dict, data.Rows)

        data.map((item)=>{
          item['cgNbr'] = value['cgNbr']
        })
      }
      console.log("data---");
      console.log(data);
      return data
    })

    return datatype.isNull(data) ? super._error(data) : super._success({ PtList: data ? super._toBeArray(data) : [] })
  }

}
