import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/iconfont/iconfont.js";
// import "./assets/css/reset.css";
import "./assets/scss/common.scss";
import VueCookie from "vue-cookie";
import dayjs from "dayjs";
import { isExist, numFormat } from "@/utils";
import _ from "lodash";
import Common from "@/assets/js/common/common.js"; //公共方法
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import "./assets/scss/element-variables.scss";
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";
import { InfiniteScroll } from 'element-ui';
Vue.use(InfiniteScroll) 
Vue.prototype.dayjs = dayjs;
Vue.prototype.Common = Common; //公共方法
Vue.prototype.isExist = isExist;
Vue.use(VueCookie);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV != "dev") {
    //firebase
    // const firebaseConfig = {
    //     apiKey: "AIzaSyAtxKNi-wKmGe0zaUbN0SLvaJ57OnN-LGE",
    //     authDomain: "paiduola-10c9c.firebaseapp.com",
    //     databaseURL: "https://paiduola-10c9c.firebaseio.com",
    //     projectId: "paiduola-10c9c",
    //     storageBucket: "paiduola-10c9c.appspot.com",
    //     messagingSenderId: "214914015424",
    //     appId: "1:214914015424:web:c19f4f43b084f6e2968d25",
    // };

    // firebase.initializeApp(firebaseConfig);

    // window.firebase = firebase;

    // Sentry.init({
    //     dsn: "https://010ec659e5cf4add987650d8c2e33de8@sentry.so-buy.com/7",
    //     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    //     release: process.env.VUE_APP_RELEASE_VERSION,
    //     logErrors: true,
    // });
}
import MyPlugin from "@/common_module_pc/components/common/index";
Vue.use(MyPlugin);
import MethodsyPlugin from "@/components/Methods/index";
Vue.use(MethodsyPlugin);

// 價格顯示格式化
Vue.filter("priceFormat", (data) => {
    return `${Common.numFormat(data)}元`;
});

//地址去除逗號
// Vue.filter("cityReplace", data => {
//   return data.replace(/,/g, " ");
// });

let isIE = !!window.ActiveXObject || "ActiveXObject" in window ? true : false;
if (!isIE) {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
} else {
    alert("微軟已宣布停止IE瀏覽器的支持，可能會有安全風險，請使用其他瀏覽器打開");
}
