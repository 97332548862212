import basic from '@/store/basic.js'

class user extends basic {
    constructor() {
        super()
    }

    state = {
        userInfo: {},
    }

    mutations = {
        setUserInfo(state, val) {
            state.userInfo = val
        },
    }

    actions = {
        setUserInfo({ commit }, value) {
            commit('setUserInfo', value)
        },
    }
}

export default new user()
