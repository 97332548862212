//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { Image } from "element-ui";
import { isExist } from "../../../utils";
import SearchPc from "../Search";
export default {
	name: "NavHeader",
	components: {
		[Image.name]: Image,
		SearchPc
	},
	props: {
		headInfo: Object,
		navInfo: Object,
		isPointer: String,
		searchTags: Array,
		historySearchTags: Array,
	},
	computed: {
		//validateMsg
		...mapState({
			configInfo(state) {
				return state.base.configInfo;
			},
		}),
	},

	data() {
		return {
			isExist,
		};
	},
	mounted() {
		//isPointer:none 禁止所有點擊事件
		this.$nextTick(() => {
			const self = this;
			this.$refs.header.addEventListener(
				"click",
				function handle(e) {
					if (self.isPointer == "none") {
						e.stopPropagation();
						return false;
					}
				},
				true
			);
		});
	},
	methods: {},
};
