import basic from '@/store/basic.js'

class page extends basic {
    constructor() {
        super()
    }

    state = {
        pageInfo: {},
        headInfo: {},
        footInfo: {},
        navInfo: {},
    }

    mutations = {
        setPageInfo(state, val) {
            state.pageInfo = val
        },
        setHeadInfo(state, val) {
            state.headInfo = val
        },
        setFootInfo(state, val) {
            state.footInfo = val
        },
        setNavInfo(state, val) {
            state.navInfo = val
        },
    }

    actions = {
        setPageInfo({ commit }, value) {
            commit('setPageInfo', value)
        },
        setHeadInfo({ commit }, value) {
            commit('setHeadInfo', value)
        },
        setFootInfo({ commit }, value) {
            commit('setFootInfo', value)
        },
        setNavInfo({ commit }, value) {
            commit('setNavInfo', value)
        },
    }
}

export default new page()
