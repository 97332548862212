/*
 * @Author: andy
 * @Date: 2021-06-04 09:50:52
 * @LastEditTime: 2021-06-16 16:24:52
 * @Description: service 基礎類
 * @FilePath: \shop_corp\src\elearningservice\service\serBasic.js
 */
import {datatype} from '@/library2/utils/datatype.js'

export class serBasic {
  _success(content) {
    if (content) {
      return { ...{ response: true }, ...content }
    } else {
      return { response: true }
    }
  }

  _error(content) {
    if (content) {
      return { ...{ response: false }, ...content }
    } else {
      return { response: false }
    }
  }

  _toBeArray(value) {
    if (!datatype.isArray(value)) value = [value]
    return value
  }

  _paginate(db, page, paginate) {
    if (page) {
      let _page = 0
      if (page >= 2) {
        _page = Number(page - 1) * Number(paginate)
      }

      db.offset(_page)
    }
    if (paginate) db.limit(paginate)
  }
}
