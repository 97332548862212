//
//
//
//
//
//
//
//
//
//
//
//

import { Image } from "element-ui";

import VideoPopup from "../../base/VideoPopup";
import pa from "../../../utils/package";

export default {
    name: "HvImage",
    components: {
        VideoPopup,
        [Image.name]: Image,
    },
    props: {
        data: Object,
        fit: {
            type: String,
            default: "contain",
        },
    },
    computed: {},
    data() {
        return {
            default_img: require("../../../assets/images/default_img.png"),
        };
    },
    created() {},
    mounted() {},
    methods: {
        handleClick(link,linkType,video,videoType){
            if(link){ this.handleLink(link,linkType) }
            if(video){ this.handleVideo(video,videoType) }
            return false
            
        },
        handleLink(link,linkType){
            pa.linkGoType(link,linkType)
        },
        handleVideo(video,videoType){
            //每次重新賦值會導致video-player出現BUG
            if(!this.$refs.videoPopup.playerOptions.src){
                this.$refs.videoPopup.playerOptions = {
                    src:video,
                    type:videoType
                }
            }
            this.$refs.videoPopup.videoPop = true;
        },
    },
};
