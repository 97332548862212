/*
 * @Author: andy
 * @Date: 2021-06-16 17:35:37
 * @LastEditTime: 2021-07-16 09:37:48
 * @Description: 賬號相關
 * @FilePath: \shop_corp\src\shopsaasservice\service\account.ts
 */
import { insenMemInfo } from "@/elearningservice/repository/insenMemInfo.js";
import { config } from "@/elearningservice/repository/config.js";
import { datatype } from "@/library2/utils/datatype.js";
import { userNbr } from "@/elearningservice/repository/userNbr.js";
import loginError from "@/exception/loginError.js";
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { base } from "@/elearningservice/service/base.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { log } from "@/library2/utils/log.js";

export class account extends serBasic {
    /**
     * @description: 獲取驗證碼
     */
    async getSmsCode(value) {
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memMobile"] });

        let data = await req.post("", { fun: "account", op: "getSmsCode" }, { memMobile: value["memMobile"] });

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 驗證碼登錄
     */
    async smsLogin(value){
        const options = { encryptFields: ["memMobile"], decryptFields: { MemInfo: ["memName"] } };
        const req = new request().setCaptcha().connectNsuv(options);

        let data = await req.post("", { fun: "account", op: "smsLogin" }, { memMobile: value["memMobile"], smsCode: value["smsCode"] });
        if (datatype.isNull(data)) return super._error(data);

        //更新時間戳
        new base().getTickInfo();

        this._saveMemInfo(data);

        return datatype.isNull(data.MemInfo) ? super._error(data) : super._success(data.MemInfo);
    }

    _saveMemInfo(data) {
        //存儲userNbr
        let nbr = data.MemInfo.userNbr ? data.MemInfo.userNbr : data.MemFullInfo.userNbr;
        if (!datatype.isNull(nbr)) {
            const obj = new userNbr();
            obj.put(nbr);
        }

        if (!datatype.isNull(data.MemInfo)) {
            const obj2 = new insenMemInfo();
            obj2.put(data.MemInfo);
        }
    }

    /**
     * @description: 賬號密碼登錄
     */
    async userLogin(value){
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memEmail", "passwd"], decryptFields: { MemInfo: ["memName"] } });

        let data = await req.post("", { fun: "account", op: "userLogin" }, { memEmail: value["memEmail"], passwd: value["passwd"] });
        if (datatype.isNull(data)) return super._error();

        //更新時間戳
        new base().getTickInfo();

        this._saveMemInfo(data);

        return datatype.isNull(data.MemInfo) ? super._error(data) : super._success(data.MemInfo);
    }

    /**
     * @description: 註冊
     */
    async emailRegister(value) {
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memName", "memEmail", "passwd"], decryptFields: { MemInfo: ["memName"] } });

        let data = await req.post("", { fun: "account", op: "emailRegister" }, { memName: value["memName"], memEmail: value["memEmail"], passwd: value["passwd"] });
        if (datatype.isNull(data)) return super._error();

        //更新時間戳
        new base().getTickInfo();

        this._saveMemInfo(data);

        return datatype.isNull(data.MemInfo) ? super._error(data) : super._success(data.MemInfo);
    }

    /**
     * @description: 登出
     * @return {Promise<object> }
     */
    async signout(){
        const userNbr = this.getAuth();
        if (!userNbr) return super._error();

        //刪除會員數據
        this.delMemInfo();

        const req = new request().connectNsuv();
        let data = await req.post("", { fun: "account", op: "signout" }, { userNbr: userNbr });

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 刪除用戶數據
     */
    delMemInfo(){
        const obj2 = new insenMemInfo();
        obj2.del();

        const obj3 = new userNbr();
        obj3.del();

        const obj4 = new config();
        obj4.clear();
    }

    /**
     * @description: 獲取用戶編號
     */
    getAuth() {
        const obj = new userNbr();
        let auth = obj.get();

        if (datatype.isNull(auth)) {
            //再次拋出
            return false;
        } else {
            return auth;
        }
    }

    /**
     * @description: 檢查是否登錄
     */
    chkLogin() {
        let ret = this.getAuth();
        return datatype.isNull(ret) ? super._error() : super._success();
    }

    /**
     * @description: 忘記密碼
     */
    async lookup(value){
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memEmail"] });

        let data = await req.post("", { fun: "account", op: "lookup" }, { memEmail: value["memEmail"] });

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 重設密碼
     */
    async passwdReset(value){
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["newPasswd"] });

        let data = await req.post("", { fun: "account", op: "passwdReset" }, { nbr: value["nbr"], newPasswd: value["newPasswd"] });

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 重設密碼-郵箱賬號登錄狀態
     */
    async changePasswd(value){
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["oldPasswd", "newPasswd"] });

        let data = await req.post("", { fun: "account", op: "changePasswd" }, value);

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 短信修改密碼
     */
    async smsChangePasswd(value) {
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memMobile", "newPasswd"] });

        let data = await req.post("", { fun: "account", op: "smsChangePasswd" }, value);

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description: 根據編號獲取郵箱
     */
    async getEmailByNbr(value){
        const req = new request().setCaptcha().connectNsuv({ decryptFields: ["memEmail"] });

        let data = await req.post("", { fun: "account", op: "getEmailByNbr" }, { nbr: value["nbr"] });

        return datatype.isNull(data) ? super._error() : super._success({ memEmail: data.memEmail });
    }

    /**
     * @description: 獲取用戶信息
     */
    async getSenMemInfo(){
        let nbr = this.getAuth();
        if (!nbr) return super._error();
        const req = new request().connectNsuv({ decryptFields: { MemFullInfo: ["memName", "mobile", "email"] } });

        const data = await req.post("", { fun: "account", op: "getMemInfo" }, { userNbr: nbr });
        console.log("-------------");
        console.log(data);
        return datatype.isNull(data.MemFullInfo) ? super._error() : super._success(data.MemFullInfo);
    }

    /**
     * @description: 修改會員敏感信息
     */
    async updMemInfo(value){
        const nbr = this.getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const options = { encryptFields: ["memName", "mobile", "email"], decryptFields: { MemFullInfo: ["memName", "mobile", "email"], MemInfo: ["memName"] } };
        let req = new request().connectNsuv(options);

        // if (value["avatar"]) {
        //     let file = { avatar: value["avatar"] };
        //     req.setFile(file);

        //     value = dataTrans.unset(value, "avatar");
        // }

        log.addLog("updMemInfo--value--", value);
        let data = await req.post("", { fun: "account", op: "updProfile" }, value);

        if (datatype.isNull(data)) return super._error();

        if (!datatype.isNull(data.MemInfo)) {
            //更新會員非敏感數據
            const obj = new insenMemInfo();
            obj.put(data.MemInfo);
        }

        return super._success();
    }

    /**
     * @description: 獲取會員非敏感數據
     */
    async getInsenMemInfo() {
        let nbr = this.getAuth();
        if (!nbr) return super._error();

        const obj = new insenMemInfo();
        let data = await obj.get();

        if (datatype.isNull(data)) {
            let res = await this.getSenMemInfo();

            data = {
                avatarUrl: res["avatarUrl"],
                memName: res["memName"],
                userNbr: res["userNbr"],
                isNew: res["isNew"],
            };

            obj.put(data);
        }

        return super._success(data);
    }

    /**
     * @description: 驗證賬號是否存在
     */
    async chkAcctExist(value){
        const req = new request().setCaptcha().connectNsuv({ encryptFields: ["memEmail"] });

        let data = await req.post("", { fun: "account", op: "chkAcctExist" }, value);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 第三方登錄
     */
    async oauthInit(value){
        let data = await new request()
            .setCaptcha()
            .connectNsuv()
            .post("", { fun: "account", op: "oauthInit" }, value);
        if (datatype.isNull(data)) return super._error();

        //更新時間戳
        new base().getTickInfo();

        return super._success(data);
    }
}
