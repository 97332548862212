//
//
//
//
//
//
//
//

import BaseImage from "../../base/Image";
export default {
    name: "Cube",
    components: {
        BaseImage,
    },
    props: {
        data: Object,
    },
    computed: {},
    data() {
        return {
            cubeWidth: "",
        };
    },
    watch: {
        "data.type": {
            handler(newVal) {
                this.cubeWidth = document.querySelector(".hv-cube").offsetWidth;
                const type = this.data.type;
                if (["cube", "hybrid1", "hybrid2", "hybrid3"].includes(type)) {
                    document.querySelector(".hv-cube").style.cssText = `
                height:${this.cubeWidth}px;
            `;
                } else {
                    document.querySelector(".hv-cube").style.cssText = `
                height:auto;
            `;
                }
            },
        },
    },
    created() {},
    mounted() {},
};
