//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Autocomplete, Button, Divider, Icon, Input, Popover, Tag } from "element-ui";
export default {
	name: "Search",
	components: {
		[Autocomplete.name]: Autocomplete,
		[Input.name]: Input,
		[Icon.name]: Icon,
		[Button.name]: Button,
		[Popover.name]: Popover,
		[Tag.name]: Tag,
		[Divider.name]: Divider,
	},
	props: {
		searchTags: Array,
		historySearchTags: Array,
	},
	inject: ["searchValue"],
	computed: {
		computedSearchValue() {
			if(this.searchValue) return this.searchValue();
		},
	},
	watch: {
		computedSearchValue(value){
			this.searchInput(value);
		}
	},
	data() {
		return {
			search: "",
			restaurants: [],
			state: "",
			timeout: null,
		};
	},
	created() {},
	mounted() {},
	methods: {
		searchInput(value) {
			this.search = value;
		},
		searchChange() {
			this.$emit("searchChange", this.search);
		},
		deleteHis() {
			this.$emit("deleteHis");
		},
	},
};
