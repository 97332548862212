/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 09:44:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\info.ts
 */
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { datatype } from "@/library2/utils/datatype.js";
import { account as serAcct } from '@/elearningservice/service/account.js'
import { loveCodeList } from '@/elearningservice/repository/loveCodeList.js'
export class shop extends serBasic {
	/**
	 * @description: 獲取預設捐贈碼
	 */
	 async getLoveCodeList(){
		const userNbr = await new serAcct().getAuth()
		if (!userNbr) return super._error()
	
		const obj = new loveCodeList()
		obj.setTickKey('loveCodeListTick|' + userNbr)
	
		obj.where('userNbr').equals(userNbr)
	
		let data = await obj.genData(new request().connectNsuv(), "", { fun: "shop", op: "getLoveCodeList" }, { userNbr: userNbr }, (data) => {
            data["userNbr"] = userNbr;
            return data;
        });
		if(datatype.isNull(data)){
			return super._error(data);
		}
		
		return super._success({ list: data.LoveCodeList ? super._toBeArray(data.LoveCodeList) : [] });
	  }
}
