/*
 * @Author: your name
 * @Date: 2021-06-08 10:21:19
 * @LastEditTime: 2021-06-18 11:16:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_corp\src\shopcorpservice\repository\userNbr.js
 */
import {cookieBasic} from '@/library2/basicClass/cookieBasic.js'

export class userNbr extends cookieBasic{
	constructor() {
		super()
		this._mySetParam()
	}

	_mySetParam(){
		super.setKey('userNbr')
	}
}
