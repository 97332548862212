import dayjs from "dayjs";

//去除前後空格
export const getTrim = (str) => {
    if(!str) return str;
    return str.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * @description: base64編碼
 * @param {string} commonContent
 * @return {string}
 */
export function encodeBase64(commonContent) {
    let base64Content = Buffer.from(commonContent).toString("base64");
    return base64Content;
}

/**
 * @description: base64解碼
 * @param {string} base64Content
 * @return {string}
 */
export function decodeBase64(base64Content) {
    let commonContent = base64Content.replace(/\s/g, "+");
    commonContent = Buffer.from(commonContent, "base64").toString();
    return commonContent;
}

/**
 * @description 判斷是否是數組
 * @param arg
 */
export function isArray(arg) {
    if (typeof Array.isArray === "undefined") {
        return Object.prototype.toString.call(arg) === "[object Array]";
    }
    return Array.isArray(arg);
}

//日期時間轉換

export const getDate = (item) => {
    const newDate = dayjs(new Date()).format("YYYY-MM-DD");
    const day = dayjs(`${item}`);
    if (day.diff(dayjs(newDate), "day") == 0) {
        return `今天(${getWeek(day)})`;
    } else {
        return `${dayjs(item).format("MM月DD日")}(${getWeek(day)})`;
    }
};

export const getWeek = (day) => {
    const _day = dayjs(day).day();
    switch (_day) {
        case 1:
            return "星期一";
        case 2:
            return "星期二";
        case 3:
            return "星期三";
        case 4:
            return "星期四";
        case 5:
            return "星期五";
        case 6:
            return "星期六";
        default:
            return "星期日";
    }
};

export const getTime = (item) => {
    return item.split(":")[0] > dayjs().hour() || (item.split(":")[0] == dayjs().hour() && item.split(":")[1] > dayjs().minute());
};

export const isType = (obj) => {
    const type = Object.prototype.toString.call(obj);
    switch (type) {
        case "[object Array]":
            return "Array";
        case "[object Object]":
            return "Object";
        case "[object String]":
            return "String";
        case "[object Number]":
            return "Number";
        case "[object Function]":
            return "Function";
        case "[object Boolean]":
            return "Boolean";
        case "[object Null]":
            return "Null";
        default:
            return "Undefined";
    }
};

/**
 * 傳入變量（數字、字符串）、數組、對象等判斷是否為空
 * @param str 需要判斷是否為空的內容
 * @returns {boolean} 返回布爾值 true不為空，false為空
 */
export const isExist = (str) => {
    var thisType = typeof str;

    if (str === "" || str === null || str === undefined) {
        //null、undefined
        //這裏之所以用全等於，因為：
        //1.JS裏，‘’ == 0 == [],會被判斷成相同，而下方針對數字0和空數組做出單獨處理，故此處只需要單獨判斷‘’
        //2.JS裏，typeof null == object,為簡化下方object處判斷邏輯，故此處需要用全等判斷null
        return false;
    } else if (thisType == "string" && str.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
        //string
        return false;
    } else if ((thisType == "number" && isNaN(str)) || str == 0) {
        //number
        return false;
    } else if (thisType == "object") {
        if (str instanceof Array) {
            //array
            return !(str.length == 0);
        } else {
            //object
            return !(JSON.stringify(str) == "{}");
        }
    }
    return true; //傳入str不為空
};

/**
 * getRndInteger 添加千分號
 */
export const numFormat = (num) => {
    let num2 = String(num);
    let str_int = "";
    let str_int2 = "";
    //判斷是否有小數
    if (num2.indexOf(".") == -1) {
        str_int = num2;
    } else {
        let str_ar = num2.split(".");
        str_int = String(str_ar["0"]);
        str_int2 = String(str_ar["1"]);
    }
    //整數位小於等於三位，直接返回
    if (str_int.length <= 3) return num2;
    let i = str_int.length % 3;
    let s1 = str_int.substr(0, i); //前面不滿三位的數取出來
    let s2 = str_int.substr(i); //後面的滿三位的數取出來
    let new_str = "";
    for (let j = 0; j < s2.length; j += 3) {
        new_str = new_str ? new_str + "," + s2.substr(j, 3) : s2.substr(j, 3); //三位三位取出再合並，按逗號隔開
    }
    let fin_str = s1 ? s1 + "," + new_str : new_str;
    let fin_str2 = "";
    if (str_int2) {
        fin_str2 = fin_str + "." + str_int2;
    } else {
        fin_str2 = fin_str;
    }
    return fin_str2;
};

export const copyUrl = (value) => {
    let domUrl = document.createElement("input");
    domUrl.value = value;
    domUrl.id = "creatDom";
    document.body.appendChild(domUrl);
    domUrl.select();
    document.execCommand("Copy");
    let creatDom = document.getElementById("creatDom");
    creatDom.parentNode.removeChild(creatDom);
};
// const regTest = (value,reg) => {
//     return reg.test(value);
// }

export const timeCountdown = (obj) => {
    const TIME_COUNT = 60; //默認倒計時秒數
    if (!obj.timer) {
        obj.waitTime = TIME_COUNT;
        obj.canGet = false;
        obj.timer = setInterval(() => {
            if (obj.waitTime > 1 && obj.waitTime <= TIME_COUNT) {
                obj.waitTime--;
            } else {
                obj.canGet = true;
                clearInterval(obj.timer); //清空定時器
                obj.timer = null;
            }
        }, 1000);
    }
    return {
        timer: obj.timer,
        canGet: obj.canGet,
        waitTime: obj.waitTime,
    };
};

//等待js加入完成
export const loadScript = (src, callback) => {
    var script = document.createElement("script"),
        head = document.getElementsByTagName("head")[0];
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.src = src;
    if (script.addEventListener) {
        script.addEventListener(
            "load",
            function () {
                callback();
            },
            false
        );
    } else if (script.attachEvent) {
        script.attachEvent("onreadystatechange", function () {
            var target = window.event.srcElement;
            if (target.readyState == "loaded") {
                callback();
            }
        });
    }
    head.appendChild(script);
};

/**
 * 谷歌地圖計算兩個坐標點的距離
 * @param lng1  經度1
 * @param lat1  緯度1
 * @param lng2  經度2
 * @param lat2  緯度2
 * @return 距離（千米）
 */
export const getDistance = (lng1, lat1, lng2, lat2) => {
    const EARTH_RADIUS = 6378.137;

    const rad = (d) => {
        return (d * Math.PI) / 180.0;
    };

    const radLat1 = rad(lat1);
    const radLat2 = rad(lat2);
    const a = radLat1 - radLat2;
    const b = rad(lng1) - rad(lng2);
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return s.toFixed(2);
};

export const isMobileDevice = () => {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
};

export const filterParams = (obj) => {
    let _newPar = {};
    for (let key in obj) {
        //如果對象屬性的值不為空，就保存該屬性（這裏我做了限制，如果屬性的值為0，保存該屬性。如果屬性的值全部是空格，屬於為空。
        if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== "") {
            _newPar[key] = obj[key];
        }
    }
    return _newPar;
};

// /**
//  * @description 格式化時間
//  * @param time
//  * @param cFormat
//  * @returns {string|null}
//  */
// export function parseTime(time, cFormat) {
//     if (arguments.length === 0) {
//         return null;
//     }
//     const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
//     let date;
//     if (typeof time === "object") {
//         date = time;
//     } else {
//         if (typeof time === "string" && /^[0-9]+$/.test(time)) {
//             time = parseInt(time);
//         }
//         if (typeof time === "number" && time.toString().length === 10) {
//             time = time * 1000;
//         }
//         date = new Date(time);
//     }
//     const formatObj = {
//         y: date.getFullYear(),
//         m: date.getMonth() + 1,
//         d: date.getDate(),
//         h: date.getHours(),
//         i: date.getMinutes(),
//         s: date.getSeconds(),
//         a: date.getDay(),
//     };
//     const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
//         let value = formatObj[key];
//         if (key === "a") {
//             return ["日", "一", "二", "三", "四", "五", "六"][value];
//         }
//         if (result.length > 0 && value < 10) {
//             value = "0" + value;
//         }
//         return value || 0;
//     });
//     return time_str;
// }

// /**
//  * @description 格式化時間
//  * @param time
//  * @param option
//  * @returns {string}
//  */
// export function formatTime(time, option) {
//     if (("" + time).length === 10) {
//         time = parseInt(time) * 1000;
//     } else {
//         time = +time;
//     }
//     const d = new Date(time);
//     const now = Date.now();

//     const diff = (now - d) / 1000;

//     if (diff < 30) {
//         return "剛剛";
//     } else if (diff < 3600) {
//         // less 1 hour
//         return Math.ceil(diff / 60) + "分鐘前";
//     } else if (diff < 3600 * 24) {
//         return Math.ceil(diff / 3600) + "小時前";
//     } else if (diff < 3600 * 24 * 2) {
//         return "1天前";
//     }
//     if (option) {
//         return parseTime(time, option);
//     } else {
//         return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "時" + d.getMinutes() + "分";
//     }
// }

// /**
//  * @description 將url請求參數轉為json格式
//  * @param url
//  * @returns {{}|any}
//  */
// export function paramObj(url) {
//     const search = url.split("?")[1];
//     if (!search) {
//         return {};
//     }
//     return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
// }

// /**
//  * @description 父子關系的數組轉換成樹形結構數據
//  * @param data
//  * @returns {*}
//  */
// export function translateDataToTree(data) {
//     const parent = data.filter((value) => value.parentId === "undefined" || value.parentId == null);
//     const children = data.filter((value) => value.parentId !== "undefined" && value.parentId != null);
//     const translator = (parent, children) => {
//         parent.forEach((parent) => {
//             children.forEach((current, index) => {
//                 if (current.parentId === parent.id) {
//                     const temp = JSON.parse(JSON.stringify(children));
//                     temp.splice(index, 1);
//                     translator([current], temp);
//                     typeof parent.children !== "undefined" ? parent.children.push(current) : (parent.children = [current]);
//                 }
//             });
//         });
//     };
//     translator(parent, children);
//     return parent;
// }

// /**
//  * @description 樹形結構數據轉換成父子關系的數組
//  * @param data
//  * @returns {[]}
//  */
// export function translateTreeToData(data) {
//     const result = [];
//     data.forEach((item) => {
//         const loop = (data) => {
//             result.push({
//                 id: data.id,
//                 name: data.name,
//                 parentId: data.parentId,
//             });
//             const child = data.children;
//             if (child) {
//                 for (let i = 0; i < child.length; i++) {
//                     loop(child[i]);
//                 }
//             }
//         };
//         loop(item);
//     });
//     return result;
// }

// /**
//  * @description 10位時間戳轉換
//  * @param time
//  * @returns {string}
//  */
// export function tenBitTimestamp(time) {
//     const date = new Date(time * 1000);
//     const y = date.getFullYear();
//     let m = date.getMonth() + 1;
//     m = m < 10 ? "" + m : m;
//     let d = date.getDate();
//     d = d < 10 ? "" + d : d;
//     let h = date.getHours();
//     h = h < 10 ? "0" + h : h;
//     let minute = date.getMinutes();
//     let second = date.getSeconds();
//     minute = minute < 10 ? "0" + minute : minute;
//     second = second < 10 ? "0" + second : second;
//     return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //組合
// }

// /**
//  * @description 13位時間戳轉換
//  * @param time
//  * @returns {string}
//  */
// export function thirteenBitTimestamp(time) {
//     const date = new Date(time / 1);
//     const y = date.getFullYear();
//     let m = date.getMonth() + 1;
//     m = m < 10 ? "" + m : m;
//     let d = date.getDate();
//     d = d < 10 ? "" + d : d;
//     let h = date.getHours();
//     h = h < 10 ? "0" + h : h;
//     let minute = date.getMinutes();
//     let second = date.getSeconds();
//     minute = minute < 10 ? "0" + minute : minute;
//     second = second < 10 ? "0" + second : second;
//     return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //組合
// }

// /**
//  * @description 獲取隨機id
//  * @param length
//  * @returns {string}
//  */
// export function uuid(length = 32) {
//     const num = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
//     let str = "";
//     for (let i = 0; i < length; i++) {
//         str += num.charAt(Math.floor(Math.random() * num.length));
//     }
//     return str;
// }

// /**
//  * @description m到n的隨機數
//  * @param m
//  * @param n
//  * @returns {number}
//  */
// export function random(m, n) {
//     return Math.floor(Math.random() * (m - n) + n);
// }

// /**
//  * @description addEventListener
//  * @type {function(...[*]=)}
//  */
// export const on = (function () {
//     return function (element, event, handler, useCapture = false) {
//         if (element && event && handler) {
//             element.addEventListener(event, handler, useCapture);
//         }
//     };
// })();

// /**
//  * @description removeEventListener
//  * @type {function(...[*]=)}
//  */
// export const off = (function () {
//     return function (element, event, handler, useCapture = false) {
//         if (element && event) {
//             element.removeEventListener(event, handler, useCapture);
//         }
//     };
// })();

// //精度計算

// export const numAdd = (num1, num2) => {
//     let baseNum, baseNum1, baseNum2;
//     try {
//         baseNum1 = num1.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum1 = 0;
//     }
//     try {
//         baseNum2 = num2.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum2 = 0;
//     }
//     baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
//     return (num1 * baseNum + num2 * baseNum) / baseNum;
// };

// export const numSub = (num1, num2) => {
//     let baseNum, baseNum1, baseNum2;
//     try {
//         baseNum1 = num1.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum1 = 0;
//     }
//     try {
//         baseNum2 = num2.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum2 = 0;
//     }
//     baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
//     return (num1 * baseNum - num2 * baseNum) / baseNum;
// };

// export const numMulti = (num1, num2) => {
//     let baseNum = 0;
//     try {
//         baseNum += num1.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum = 0;
//     }
//     try {
//         baseNum += num2.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum = 0;
//     }
//     return (Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", ""))) / Math.pow(10, baseNum);
// };

// export const numDiv = (num1, num2) => {
//     let baseNum1 = 0,
//         baseNum2 = 0;
//     let baseNum3, baseNum4;
//     try {
//         baseNum1 = num1.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum1 = 0;
//     }
//     try {
//         baseNum2 = num2.toString().split(".")[1].length;
//     } catch (e) {
//         baseNum2 = 0;
//     }
//     baseNum3 = Number(num1.toString().replace(".", ""));
//     baseNum4 = Number(num2.toString().replace(".", ""));
//     return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
// };

// /**
//  * @description 判讀是否為外鏈
//  * @param path
//  * @returns {boolean}
//  */
// export function isExternal(path) {
//     return /^(https?:|mailto:|tel:|\/\/)/.test(path);
// }

// /**
//  * @description 校驗密碼是否小於6位
//  * @param value
//  * @returns {boolean}
//  */
// export function isPassword(value) {
//     return value.length >= 6;
// }

// /**
//  * @description 判斷是否為數字
//  * @param value
//  * @returns {boolean}
//  */
// export function isNumber(value) {
//     const reg = /^[0-9]*$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是名稱
//  * @param value
//  * @returns {boolean}
//  */
// export function isName(value) {
//     const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否為IP
//  * @param ip
//  * @returns {boolean}
//  */
// export function isIP(ip) {
//     const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
//     return reg.test(ip);
// }

// /**
//  * @description 判斷是否是傳統網站
//  * @param url
//  * @returns {boolean}
//  */
// export function isUrl(url) {
//     const reg =
//         /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
//     return reg.test(url);
// }

// /**
//  * @description 判斷是否是小寫字母
//  * @param value
//  * @returns {boolean}
//  */
// export function isLowerCase(value) {
//     const reg = /^[a-z]+$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是大寫字母
//  * @param value
//  * @returns {boolean}
//  */
// export function isUpperCase(value) {
//     const reg = /^[A-Z]+$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是大寫字母開頭
//  * @param value
//  * @returns {boolean}
//  */
// export function isAlphabets(value) {
//     const reg = /^[A-Za-z]+$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是字符串
//  * @param value
//  * @returns {boolean}
//  */
// export function isString(value) {
//     return typeof value === "string" || value instanceof String;
// }

// /**
//  * @description 判斷是否是端口號
//  * @param value
//  * @returns {boolean}
//  */
// export function isPort(value) {
//     const reg = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是手機號
//  * @param value
//  * @returns {boolean}
//  */
// export function isPhone(value) {
//     const reg = /^1\d{10}$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是身份證號(第二代)
//  * @param value
//  * @returns {boolean}
//  */
// export function isIdCard(value) {
//     const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否是郵箱
//  * @param value
//  * @returns {boolean}
//  */
// export function isEmail(value) {
//     const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否中文
//  * @param value
//  * @returns {boolean}
//  */
// export function isChina(value) {
//     const reg = /^[\u4E00-\u9FA5]{2,4}$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否為空
//  * @param value
//  * @returns {boolean}
//  */
// export function isBlank(value) {
//     return value === null || false || value === "" || value.trim() === "" || value.toLocaleLowerCase().trim() === "null";
// }

// /**
//  * @description 判斷是否為固話
//  * @param value
//  * @returns {boolean}
//  */
// export function isTel(value) {
//     const reg = /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 轉])*([0-9]{1,4}))?$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否為數字且最多兩位小數
//  * @param value
//  * @returns {boolean}
//  */
// export function isNum(value) {
//     const reg = /^\d+(\.\d{1,2})?$/;
//     return reg.test(value);
// }

// /**
//  * @description 判斷是否為json
//  * @param value
//  * @returns {boolean}
//  */
// export function isJson(value) {
//     if (typeof value === "string") {
//         const obj = JSON.parse(value);
//         return !!(typeof obj === "object" && obj);
//     }
//     return false;
// }
