import basic from "@/store/basic.js";
class cart extends basic {
    constructor() {
        super();
    }

    state = {
        cartNum: 0, //購物車數量
    };

    mutations = {
        setCartNum(state, val) {
            state.cartNum = val;
        },
    };

    actions = {
        setCartNum: ({ commit }, val) => {
            commit("setCartNum", val);
        },
    };
}

export default new cart();
