"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ls = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-06-10 17:13:31
 * @Description: localstorage基礎類
 * @FilePath: \shop_corp\src\library2ts\storage\localstorage.ts
 */
var ls = /** @class */ (function () {
    function ls() {
        this._init();
    }
    ls.prototype._init = function () {
        //檢查是否支持localstorage
        if (!window.localStorage)
            this._error('@_init Nonsupport Localstorage');
    };
    /**
     * @description: 修改某一筆數據，若不存在，則新增
     * @param {string} key
     * @param {any} data
     */
    ls.prototype.put = function (key, data) {
        this._chkKey(key);
        Promise.resolve().then(function () {
            localStorage.setItem(key, JSON.stringify(data));
        });
    };
    /**
     * @description: 獲取某一筆數據
     * @param {string} key
     */
    ls.prototype.get = function (key) {
        return new Promise(function (resolve, reject) {
            var data = localStorage.getItem(key);
            resolve(JSON.parse(data));
        });
    };
    /**
     * @description: 刪除數據
     * @param {string} key
     */
    ls.prototype.del = function (key) {
        this._chkKey(key);
        Promise.resolve().then(function () {
            localStorage.removeItem(key);
        });
    };
    /**
     * @description: 清空數據
     */
    ls.prototype.clear = function () {
        Promise.resolve().then(function () {
            localStorage.clear();
        });
    };
    /**
     * @description: key 檢查
     * @param {string} key
     * @return {boolean}
     */
    ls.prototype._chkKey = function (key) {
        if (key === null)
            this._error('@_chkKey Err Key');
        return true;
    };
    /**
     * @description: 異常處理
     * @param {string} msg
     */
    ls.prototype._error = function (msg) {
        throw new Error('@localstorage ' + msg);
    };
    return ls;
}());
exports.ls = ls;
