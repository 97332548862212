import Vue from "vue";
import Vuex from "vuex";
import base from "./modules/base.js";
import page from "./modules/page.js";
import user from "./modules/user.js";
import validate from "./modules/validate.js";
import cart from "./modules/cart.js";
import comment from "./modules/comment.js";
import localStore from "./modules/localStore.js";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const types = {};

const state = {};

const getters = {};

const mutations = {};

const actions = {};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {
		base,
		page,
		user,
		validate,
		cart,
		comment,
		localStore,
	},
	plugins: [
		createPersistedState({
			//   key: 'ptsku',
			paths: ["localStore"],
		}),
	],
});
