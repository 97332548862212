/*
 * @Author: your name
 * @Date: 2021-07-15 12:33:44
 * @LastEditTime: 2021-07-15 18:08:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\repository\tick.ts
 */
import {idxBasic} from '@/library2/basicClass/idxBasic.js'
import DB from '@/elearningservice/repository/DB.js'

export class tick extends idxBasic {
  constructor() {
    super(DB)
    this._mySetParam()
  }

  _mySetParam() {
    super.setTable('tick')

    super.setTickKey('tickInfoTick')

    //緩存一周時間
    super.setExpires(86400 * 7)
  }
}

