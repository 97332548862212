//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
// import serAcct from '@/shopservice/service/account.js';
import { Dialog, Input, Form, FormItem, Message, Autocomplete, Button } from "element-ui";
import { OtherLogin } from "@/components";
import { ctrlAccount, ctrlBase } from "@/controller";
export default {
    name: "loginDialog",
    components: {
        [Dialog.name]: Dialog,
        [Input.name]: Input,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Autocomplete.name]: Autocomplete,
        [Button.name]: Button,
        OtherLogin,
    },
    props: {
        abody: { type: Boolean, default: true }, //Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必須指定該屬性並賦值為 true
        isClose: { type: Boolean, default: true }, //是否顯示關閉按鈕
        isModal: { type: Boolean, default: true }, //是否需要遮罩層
        isClickModal: { type: Boolean, default: false }, //是否可以通過點擊 modal 關閉 Dialog
    },
    data() {
        return {
            disabled: false, //允許點擊
            countdown: 60, //獲取驗證碼倒計時
            yzmBtnText: "獲取驗證碼",
            settimer: "",
            loginVisible: true,
            userForm: {
                memMobile: "",
                smsCode: "",
            }, //表單驗證信息
            userForm2: {
                memEmail: "",
                passwd: "",
            },
            changeBtnInfo: {
                title: "手機簡訊登入", //登入方式標題
                name: "電子信箱登入", // 切換按鈕名稱
                isLoginFormShow: true, //true 手機登入 ， false 電子信箱登入
            },
            restaurants: [], //手機號碼歷史記錄
        };
    },
    computed: {
        ...mapState({
            //validateMsg
            msg: function(state) {
                return state["validate"];
            },
            config(state) {
                const type = state.base.configInfo.AcctType;
                if (this.Common.isExist(type)) {
                    if (type.includes("mobile")) {
                        this.changeBtnInfo.title = "手機簡訊登入";
                        this.changeBtnInfo.name = "電子信箱登入";
                        this.changeBtnInfo.isLoginFormShow = true;
                    } else if (type.includes("email")) {
                        this.changeBtnInfo.title = "電子信箱登入";
                        this.changeBtnInfo.name = "手機簡訊登入";
                        this.changeBtnInfo.isLoginFormShow = false;
                    }
                }
                return state.base.configInfo;
            },
        }),
    },
    created() {},
    mounted() {
        this.restaurants = JSON.parse(localStorage.getItem("telArr")) || [];
    },

    methods: {
        //關閉前的回調
        beforeClose() {},
        //登入
        async onLogin(val) {
            this.changeBtnInfo.isLoginFormShow ? this.smsLogin(val) : this.userLogin();
        },
        //手機登入
        async smsLogin(val) {
            this.$refs.userForm.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "smsLogin",
                        val: { memMobile: this.userForm.memMobile, smsCode: this.userForm.smsCode },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
        //電子信箱登入
        async userLogin() {
            this.$refs.userForm2.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "userLogin",
                        val: { memEmail: this.userForm2.memEmail, passwd: this.userForm2.passwd },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
        //獲取驗證碼
        getCode(formName) {
            this.$refs[formName].validateField("memMobile", async (res) => {
                if (res) return false;
                this.settime(); //獲取驗證碼倒計時
                //發送短信驗證碼請求,
                ctrlBase.gaSentEvent("用戶登入", "獲取驗證碼", document.title);
                const obj = {
                    memMobile: this.userForm.memMobile,
                };
                const data = await ctrlAccount.getSmsCode(obj);
                if (!data) {
                    this.yzmBtnText = "獲取驗證碼";
                    this.countdown = 60;
                    this.disabled = false;
                    clearInterval(this.settimer); // 清除定時器
                }
            });
        },
        //獲取驗證碼倒計時
        settime() {
            if (this.countdown == 0) {
                this.yzmBtnText = "獲取驗證碼";
                this.countdown = 60;
                this.disabled = false;
                return;
            } else {
                this.yzmBtnText = this.countdown + "s" + "後重發";
                this.countdown--;
                this.disabled = true;
            }
            this.settimer = setTimeout(() => {
                this.settime();
            }, 1000);
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 調用 callback 返回建議列表的數據
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        //切換登入方式
        changeBtn() {
            this.changeBtnInfo.isLoginFormShow = !this.changeBtnInfo.isLoginFormShow;
            if (this.changeBtnInfo.name == "手機簡訊登入") {
                this.changeBtnInfo.title = "手機簡訊登入";
                this.changeBtnInfo.name = "電子信箱登入";
                this.$refs.userForm2.clearValidate();
                ctrlBase.gaSentEvent("用戶登入", "切換手機簡訊登入", document.title);
            } else {
                this.changeBtnInfo.title = "電子信箱登入";
                this.changeBtnInfo.name = "手機簡訊登入";
                this.$refs.userForm.clearValidate();
                ctrlBase.gaSentEvent("用戶登入", "切換電子信箱登入", document.title);
            }
        },
    },
};
