//
//
//
//
//
//
//

import { ctrlCpn } from "@/controller";
import { CouponGet } from "@/components";
export default {
    name: "CouponMethods",
    components: {
        CouponGet
    },
    props: {
        data: Object,
    },
    watch: {
        data(newV) {
            // console.log(newV);
        },
    },
    mounted() {},
    data() {
        return {
            cpnlist:[]
        };
    },
    computed: {},
    methods: {
        async collectCoupon(cpnNbrs, scene) {
            console.log("領券");
            const data = await ctrlCpn.collectCoupon({ cpnNbrs, scene });
            if (data) {
                this.cpnlist = data;
                this.$refs.couponGet.couponShow = true;
                return data
            }
        },
    },
};
