import { pt } from "@/elearningservice/service/pt.js";
import { MessageBox } from "element-ui";
class ctrlPt {
    //獲取商品信息
    async getPtInfo2(value, fun) {
        let data;
        if (fun != "preview") {
            data = await new pt().getPtInfo(value);
        } else {
            data = await new pt().getPrePtInfo(value);
        }
        // if (data) {
        //     const checkPt = await this.checkPtBuy({ptNbr:data.ptNbr});
        //     data.checkPt = checkPt
        // }
        return data;
    }
    async dealPtInfoTick(value) {
        const data = await new pt().dealPtInfoTick(value);
        if (data.response) return data;
    }
    //新增到貨通知
    async addNotice(value) {
        let res = await new pt().addNotice(value);

        if (res.response) {
            MessageBox("預約成功！若商品到貨，我們將以簡訊、郵件方式通知您", "提示", {
                confirmButtonText: "確定",
                callback: (action) => {},
            });
        }

        return res;
    }

    //檢查商品是否可買
    async checkPtBuy(value) {
        const data = await new pt().checkPtBuy(value);

        if (data.response) {
            switch (data.stat) {
                case "so_duplicate":
                    data.text = "已購買過該課程，點擊前往訂單>";
                    break;
                case "course_duplicate":
                    data.text = "已購買過該課程，不可重複購買";
                    break;
                default:
                    break;
            }
            return data;
        }
    }
}

export default new ctrlPt();
