/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-07-16 09:23:44
 * @Description: 請求基礎類
 * @FilePath: \shop_corp\src\library2ts\basicClass\request.ts
 */
import axios from 'axios';
import { connectError } from '@/exception/connectError.js';
import { datatype } from '@/library2/utils/datatype.js';
import axiosRetry from 'axios-retry';
import { formatRequestDate } from "@/utils";
export class request {
  httpService = null;
  _ret = null;
  file = null;
  fileList = [];
  constructor(baseUrl) {
    //初始化配置
    this.httpService = axios.create({
      baseURL: baseUrl, //domain
      timeout: 20000, //超時
      withCredentials: true,
    });

    //異常重試
    axiosRetry(this.httpService, {
      retries: 2,
      retryDelay: (retryCount) => {
        return retryCount * 3000;
      },
      retryCondition: (err) => {
        console.log(err);
        return true;
      },
    });
  }

  _dealUrl(url) {
    if (!url || typeof url == 'undefined') {
      url = '/index.php';
    }
    return url;
  }

  post(url, params, params2) {
    url = this._dealUrl(url);
    //數據過濾
    if (params2) {
        formatRequestDate(params2);
    }
    let data = new FormData();

    if (!datatype.isNull(this.fileList)) {
      for (const file of this.fileList) {
        for (const item of file.files) {
          data.append(file.name + '[]', item);
        }
      }
      this.fileList = [];
    }

    if (params2)
      Object.keys(params2).forEach((key) => data.append(key, params2[key]));

    return new Promise((resolve, reject) => {
      this.httpService({
        url,
        method: 'post',
        params,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({ data }) => {
          this._ret = data;

          if (data.retCode == '1' || data.retCode == 1) {
            resolve(data);
          } else {
            throw new connectError(data.retCode, data.msg);
          }
        })
        .catch((err) => {
          this._ret = err;

          console.log('err----', err);

          if (err.message.includes('timeout')) {
            // 判斷請求異常信息中是否含有超時timeout字符串
            connectError.error('timeout');
          } else if (err.message.includes('Repeated requests')) {
            connectError.error('repeated');
          } else {
            connectError.error(err.code, err.msg);
          }
        })
        .finally(() => {
          this.file = null;

          if (this._ret.retCode != '1' || this._ret.retCode != 1) {
            resolve(false);
          }
        });
    });
  }

  /**
   * @description: 設定上傳文件
   * @param {Array} file
   * @return {*}
   */
  setFile(file) {
    this.file = file;
    return this;
  }
  setFileList(file) {
    this.fileList.push(file);
    return this;
  }
  // /**
  //  * @description: 文件上傳
  //  * @param {string} url
  //  * @param {object} params
  //  * @param {any} file
  //  * @param {any} value
  //  * @return {any}
  //  */
  // upload(url: string, params: object, file: any, value: any = null): any {
  //   url = this._dealUrl(url)
  //   let data = new FormData()

  //   if (!datatype.isNull(file)) {
  //     for (let i in file) {

  //       if (file[i] instanceof Array) {
  //         if (file[i].length > 1) {
  //           for (let j of file[i]) {
  //             data.append(i + '[]', j)
  //           }
  //         } else {
  //           for (let j of file[i]) {
  //             data.append(i, j)
  //           }
  //         }
  //       } else {
  //         data.append(i, file[i])
  //       }
  //     }
  //   }

  //   if (value) Object.keys(value).forEach((key) => data.append(key, value[key]))

  //   return new Promise((resolve, reject) => {
  //     this.httpService({
  //       url,
  //       params,
  //       method: 'post',
  //       data,
  //       headers: { 'Content-Type': 'multipart/form-data' }
  //     }).then(({ data }: any) => {
  //       this._ret = data

  //       if (data.retCode == "1" || data.retCode == 1) {
  //         resolve(data)
  //       } else {
  //         throw new connectError(data.retCode, data.msg)
  //       }
  //     }).catch((err: any) => {
  //       this._ret = err

  //       console.log(err)

  //       if (err.message.includes('timeout')) {   // 判斷請求異常信息中是否含有超時timeout字符串
  //         connectError.error('timeout')
  //       } else if (err.message.includes('Repeated requests')) {
  //         connectError.error('repeated')
  //       } else {
  //         connectError.error(err.code, err.msg)
  //       }
  //     }).finally(() => {
  //       if (this._ret.retCode != "1" || this._ret.retCode != 1) {
  //         resolve(false)
  //       }
  //     })
  //   })
  // }
}
