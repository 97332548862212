import { address as addr } from "@/elearningservice/repository/address.js";
import { storeAddress } from "@/elearningservice/repository/storeAddress.js";
import { datatype } from "@/library2/utils/datatype.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { account } from "@/elearningservice/service/account.js";
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { frcList } from "@/elearningservice/repository/frcList.js";
import { areaList } from "@/elearningservice/repository/areaList.js";

export class address extends serBasic {
    _addrList = [];

    /**
     * @description: 獲取配送方式
     */
    async getFrcList(){
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new frcList();

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "addr", op: "getFrcList" }, { userNbr: nbr }, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                return dataTrans.minDictRows(data.Dict, data.Rows);
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ FrcList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description: 獲取城市列表
     */
    async getAreaList() {
        const obj = new areaList();

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "addr", op: "getAreaList" }, {}, (data) => data.AreaList);

        return datatype.isNull(data) ? super._error(data) : super._success({ AreaList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description: 新增地址
     */
    async addAddr(value){
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const req = new request().connectNsuv({ encryptFields: ["name", "tel", "email"] });
        let data = await req.post("", { fun: "addr", op: "addAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 刪除地址
     */
    async delAddr(value){
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "addr", op: "delAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 刪除本地地址數據
     */
    async delStorageAllAddrs() {
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new addr();
        obj.where("userNbr").equals(nbr);

        obj.del();
    }

    /**
     * @description: 獲取地址
     */
    async getAddrList(){
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new addr();
        obj.setTickKey("addressTick|" + nbr);
        obj.where("userNbr").equals(nbr);

        const options = { decryptFields: { Rows: ["name", "tel"] } };
        const req = new request().connectNsuv(options);

        let data = await obj.genData(req, "", { fun: "addr", op: "getAddressList" }, { userNbr: nbr }, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);
                data.map((item) => {
                    item["userNbr"] = nbr;
                });
                return data;
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ AddrList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description: 根據地址編號獲取地址
     */
    async getAddrByNbr(value){
        let data = await this.getAddrList();
        let addrList = data["AddrList"];

        let addr = "";
        addrList.map((v) => {
            if (v.addrNbr == value["addrNbr"]) {
                addr = v;
            }
        });

        return datatype.isNull(addr) ? super._success() : super._success({ Addr: addr });
    }

    /**
     * @description: 根據手機號獲取地址
     */
    async getAddrByMobile(value){
        let addrList = this._addrList;
        if (datatype.isNull(addrList)) {
            let data = await this.getAddrList();
            if (!data["response"]) return super._error();

            addrList = data["AddrList"];
        }

        let addr = {};
        let addr2 = {}; //預選地址
        addrList.map((v) => {
            if (v["addrNbr"] == value["mobile"]) {
                addr = v;
            }

            if (v.isDefault) {
                addr2 = v;
            }
        });

        let addr3 = {};
        if (!datatype.isNull(addr)) {
            addr3 = addr;
        } else if (!datatype.isNull(addr2)) {
            addr3 = addr2;
        } else {
            addr3 = addrList["0"];
        }

        return datatype.isNull(addr3) ? super._success() : super._success({ Addr: addr3 });
    }

    /**
     * @description: 修改地址
     */
    async updAddr(value){
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const req = new request().connectNsuv({ encryptFields: ["name", "tel", "email"] });

        let data = await req.post("", { fun: "addr", op: "updAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 設置默認地址
     */
    async setDefault(value){
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "addr", op: "setDefault" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 獲取運費金額
     */
    async getAmtFreight(value){
        let data = await new request().connectNsuv().post("", { fun: "addr", op: "getAmtFreight" }, value);

        return datatype.isNull(data) ? super._error() : super._success({ amtFreight: data.amtFreight });
    }

    /**
     * @description: 獲取到店地址
     */
    async getStoreAddressList() {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new storeAddress();
        obj.setTickKey("storeAddressListTick|" + nbr);
        obj.where("userNbr").equals(nbr);

        const options = { decryptFields: { Rows: ["name", "tel"] } };
        const req = new request().connectNsuv(options);

        let data = await obj.genData(req, "", { fun: "addr", op: "getStoreAddressList" }, { userNbr: nbr }, (data) => {
            // data = {
            //     Dict: ["addrNbr", "name", "nameDes", "gender", "tel", "telDes", "storeType", "storeId", "storeName", "storeAddress", "isDefault"],
            //     Rows: [
            //         ["1av8t11", "春", "春*", "M", "15005970001", "150****0001", "711", "id777", "店名稱11", "店地址", "0"],
            //         ["1av8t12", "春", "春*", "M", "15005970002", "150****0002", "711", "id87", "店名稱22", "店地址", "1"],
            //         ["1av8t13", "春", "春*", "M", "15005970003", "150****0003", "hilife", "id777hilife", "hilife店名稱1", "店地址", "0"],
            //         ["1av8t14", "春", "春*", "M", "15005970004", "150****0004", "hilife", "id87hilife", "hilife店名稱2", "店地址", "1"],
            //         ["1av8t15", "春", "春*", "M", "15005970005", "150****0005", "family", "id777family", "family店名稱1", "店地址", "0"],
            //         ["1av8t16", "春", "春*", "M", "15005970006", "150****0006", "family", "id87family", "family店名稱2", "店地址", "1"],
            //     ],
            // };
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);
                data.map((item) => {
                    item["userNbr"] = nbr;
                });
                return data;
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ AddrList: data ? super._toBeArray(data) : [] });
    }
    /**
     * @description: 新增到店地址
     */
    async addStoreAddr(value) {
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const req = new request().connectNsuv({ encryptFields: ["name", "tel", "email"] });
        let data = await req.post("", { fun: "addr", op: "addStoreAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllStoreAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 修改到店地址
     */
    async updStoreAddr(value){
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const req = new request().connectNsuv({ encryptFields: ["name", "tel", "email"] });

        let data = await req.post("", { fun: "addr", op: "updStoreAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllStoreAddrs();
            return super._success();
        }

        return super._error();
    }
    /**
     * @description: 刪除到店地址
     */
    async delStoreAddr(value){
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "addr", op: "delStoreAddr" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllStoreAddrs();
            return super._success();
        }

        return super._error();
    }
    /**
     * @description: 設置默認到店地址
     */
    async setStoreDefault(value){
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "addr", op: "setStoreDefault" }, value);

        if (!datatype.isNull(data)) {
            this.delStorageAllStoreAddrs();
            return super._success();
        }

        return super._error();
    }

    /**
     * @description: 根據到店地址編號獲取地址
     */
     async getStoreAddrByNbr(value){
        let data = await this.getStoreAddressList();
        let storeAddressList = data["AddrList"];

        let addr = "";
        storeAddressList.map((v) => {
            if (v.addrNbr == value["addrNbr"]) {
                addr = v;
            }
        });

        return datatype.isNull(addr) ? super._success() : super._success({ Addr: addr });
    }

    /**
     * @description: 刪除本地到店地址數據
     */
    async delStorageAllStoreAddrs() {
        const nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new storeAddress();
        obj.where("userNbr").equals(nbr);

        obj.del();
    }
}
