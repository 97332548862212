//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseImage from "../../base/Image";
import pa from "../../../utils/package";
import { Card, Image, Button, Row, Col, Tag } from "element-ui";
export default {
	name: "Goods",
	components: {
		BaseImage,
		[Card.name]: Card,
		[Image.name]: Image,
		[Button.name]: Button,
		[Row.name]: Row,
		[Col.name]: Col,
		[Tag.name]: Tag,
	},
	props: {
		data: Object,
	},
	computed: {
		list() {
			return this.data.list;
		},
		colSize() {
			switch (this.data.type) {
				case 1:
					return 24;
				case 2:
					return 12;
				case 3:
					return 8;
				case 4:
					return 6;
				case 5:
					return 5;
				case 6:
					return 4;
				case 7:
					return 7;
				case 8:
					return 3;
				default:
					return 6;
			}
		},
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {
		handleClick(link, linkType) {
			if (link) {
				pa.linkGoType(link, linkType);
			}
		},
		//按鈕點擊回調
		btnClick(btnType, item) {
			this.$emit("GoodsBtnEvent", btnType, item);
		},
	},
};
