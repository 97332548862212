import { datatype } from "@/library2/utils/datatype.js";
import { account } from "@/elearningservice/service/account.js";
import loginError from "@/exception/loginError.js";
import { address } from "@/elearningservice/service/address.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import route from "@/router/index.js";
import { Message } from "element-ui";
class ctrlAddr {
    //獲取配送方式
    async getFrcList() {
        let data = await new address().getFrcList();
        return data.FrcList;
    }
    //獲取用戶地址列表
    async getAddrList() {
        //獲取地址列表
        let data = await new address().getAddrList();
        // console.log(data);
        if (!data.response) return [];

        let addrList = data.AddrList;

        let map = {
            addrNbr: "id",
        };
        //key轉換
        addrList = dataTrans.transArrMap(addrList, map);
        return addrList;
    }
    //新增地址
    async addAddr(value) {
        let data = await new address().addAddr(value);

        if (data.response) {
            Message.success("新增成功");
        }
    }
    //修改地址
    async updAddr(value) {
        //修改地址列表
        let data = await new address().updAddr(value);

        if (data.response) {
            Message.success("修改成功");
        }
    }
    //刪除地址
    async delAddr(addrNbr) {
        //刪除地址
        let data = await new address().delAddr({ addrNbr });

        if (data.response) return data;
    }
    //預設地址
    async setDefault(addrNbr) {
        //預設地址
        let data = await new address().setDefault({ addrNbr });
        if (data.response) return data;
    }
    //獲取城市列表
    async getAreaList() {
        let data = await new address().getAreaList();
        this._areaList = data.AreaList;
        return data.AreaList;
    }
    //獲取運費
    async getAmtFreight(frcNbr, amt, cityId) {
        let data = await new address().getAmtFreight({ frcNbr: frcNbr, amt: amt, cityId: cityId });
        return data.amtFreight;
    }
    //獲取到店地址
    async getStoreAddressList() {
        //獲取地址列表
        let data = await new address().getStoreAddressList();
        // console.log(data);
        if (!data.response) return [];

        let addrList = data.AddrList;

        let map = {
            addrNbr: "id",
            storeId: "cityId",
            storeName: "city",
            storeAddress: "address",
        };
        for (const item of addrList) {
            item.isStore = 1;
        }
        //key轉換
        addrList = dataTrans.transArrMap(addrList, map);
        return addrList;
    }
    //新增到店地址
    async addStoreAddr(value) {
        let data = await new address().addStoreAddr(value);

        if (data.response) {
            Message.success("新增成功");
        }
    }
    //修改到店地址
    async updStoreAddr(value) {
        let data = await new address().updStoreAddr(value);

        if (data.response) {
            Message.success("修改成功");
        }
    }
    //刪除到店地址
    async delStoreAddr(addrNbr) {
        let data = await new address().delStoreAddr({ addrNbr });

        if (data.response) return data;
    }
    //預設到店地址
    async setStoreDefault(addrNbr) {
        let data = await new address().setStoreDefault({ addrNbr });
        if (data.response) return data;
    }
}

export default new ctrlAddr();
