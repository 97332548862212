//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Coupon",
    components: {},
    props: {
        data: Object,
    },
    computed: {},
    data() {
        return {
            // componentName: "",
        };
    },
    created() {},
    mounted() {
        // if (process.env.VUE_APP_VAR == "hv_saas") {
        //     this.componentName = "CouponMethods";
        //     this.Common.isExist(this.data.list) && this.uncollectCoupon();
        // }
    },
    methods: {
        // //無門檻領券
        // async uncollectCoupon() {
        //     const unCpnNbrs = this.data.list.filter((item) => item.stat == -1).map((ele) => ele.cpnNbr);
        //     //沒有未領取的無門檻券 直接return
        //     if (!this.Common.isExist(unCpnNbrs)) return false;
        //     this.$nextTick(async () => {
        //         //領券回調
        //         const data = await this.$refs.couponMethods.collectCoupon(unCpnNbrs.toString(), 0);
        //         for (const call of data) {
        //             for (const cpn of this.data.list) {
        //                 if (call.cpnNbr == cpn.cpnNbr) {
        //                     cpn.stat = call.stat;
        //                 }
        //             }
        //         }
        //     });
        // },
        async collect(item) {
            this.$emit("collectCoupon", item.cpnNbr, 1, "getPageInfo");
            // if (process.env.VUE_APP_VAR == "hv_saas") {
            //     this.componentName = "CouponMethods";
            //     this.$nextTick(async () => {
            //         const data = await this.$refs.couponMethods.collectCoupon(item.cpnNbr, 1);
            //         for (const call of data) {
            //             if (call.cpnNbr == item.cpnNbr) {
            //                 item.stat = call.stat;
            //             }
            //         }
            //     });
            // } else {
            //     Toast("領券");
            // }
        },
        shareCollect(item) {
            this.$emit("shareCollect", item);
        },
    },
};
