//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Dialog, Image, Divider} from "element-ui";
export default {
    name: "IntroDialog",
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Image.name]: Image,
        [Divider.name]: Divider
    },
    props: {
        intro: Object,
        courseList: Array,
    },
    data() {
        return {
            IntroShow: false,
        };
    },
    mounted() {},
    created() {},
    methods: {
        go(item){
            this.$router.push(item.redirectUrl);
        }
    },
};
