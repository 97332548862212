import basic from "@/store/basic.js";
import { datatype } from "@/library2/utils/datatype.js";
import Common from "@/assets/js/common/common.js"; //公共方法
class validate extends basic {
    constructor() {
        super();
    }
    state = {
        req: "請輸入正確內容",
        email: "請輸入正確電子信箱",
        mobile: "請輸入正確手機號碼",
        password: "密碼長度在6-16之間，只能包含字符、數字和下劃線",
        certNo: "請輸入正確的身份證號碼",

        eleIsEmail(rule, value, callback) {
            if (datatype.isNull(value)) {
                callback(new Error("請輸入正確內容"));
            } else if (!Common.isVal.isEmail(value)) {
                callback(new Error("請輸入正確電子信箱"));
            } else {
                callback();
            }
        },
        eleIsMobile(rule, value, callback) {
            if (datatype.isNull(value)) {
                callback(new Error("請輸入正確內容"));
            } else if (!Common.isVal.isMobile(value)) {
                callback(new Error("請輸入正確手機號碼"));
            } else {
                callback();
            }
        },
        eleIsPassword(rule, value, callback) {
            if (datatype.isNull(value)) {
                callback(new Error("請輸入正確內容"));
            } else if (!Common.isVal.isPassword(value)) {
                callback(new Error("密碼長度在6-16之間，只能包含字符、數字和下劃線"));
            } else {
                callback();
            }
        },
    };

    actions = {};
}

export default new validate();
