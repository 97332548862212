/*
 * @Author: your name
 * @Date: 2021-06-16 15:49:37
 * @LastEditTime: 2021-06-17 16:29:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_corp\src\elearningservice\repository\insenMemInfo.ts
 */
//會員非敏感數據
import { lsBasic } from '@/library2/basicClass/lsBasic.js'

export class insenMemInfo extends lsBasic {
	constructor() {
		super()
		this._mySetParam()
	}

	_mySetParam() {
		super.setTable('memInfo')
	}
}
