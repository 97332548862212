//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SearchPro, NavBar } from '@/components';
import { mapGetters, mapState } from 'vuex';
import {
  Button,
  ButtonGroup,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Image,
  Avatar,
  Divider,
  Link,
  Icon,
  Badge,
  Drawer,
} from 'element-ui';
export default {
  name: 'HeaderWrapper',
  components: {
    SearchPro,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
    [Image.name]: Image,
    [Avatar.name]: Avatar,
    [Divider.name]: Divider,
    [Link.name]: Link,
    [Icon.name]: Icon,
    [Badge.name]: Badge,
    [Drawer.name]: Drawer,
    NavBar,
  },
  props: {
    configInfo: {
      type: Object,
      default: () => {},
    },
    searchTags: {
      type: Object,
      default: () => {},
    },
    searchTag: {
      type: Array,
      default: () => [],
    },
    historySearchTags: {
      type: Array,
      default: () => [],
    },
    navInfo: {
      type: Object,
      default: () => {},
    },
    headInfo: {
      type: Object,
      default: () => {},
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      menuDrawer: false,
    };
  },
  computed: {
    ...mapGetters({
      // userInfo: "localStore/getUserInfo",
      //   cartNum: 'cart/getCartNum',
    }),
    ...mapState({
      //   userInfo: function(state) {
      //     return state?.user?.userInfo ?? {};
      //   },
    }),
  },
  created() {},
  methods: {
    onlineService() {
      this.$emit('onlineService');
    },
    signout() {
      this.$emit('signout');
    },
  },
};
