import { payModules } from "@/elearningservice/service/pay.js";
import redirect from "@/controller/redirect.js";
class crtlPay {
    //獲取付款方式
    async getPayList() {
        let data = await new payModules().getPayList();
        if (data.response) return data.PayList;
    }

    //匯款帳號輸入
    async updPayInfo(value) {
        let data = await new payModules().updPayInfo(value);
        if (data.response) return true;
    }

    //付款
    async pay(soNbr, payNbr) {
        let value = { soNbr, payNbr };
        let data = await new payModules().pay(value);
        if (data.response) {
            //手機付款 後臺返回js
            if (data.js) {
                eval(data.js);
            }

            if (data.PayInfo) {
                return data.PayInfo;
            }
            redirect.push(data);
        }
    }
}

export default new crtlPay();
