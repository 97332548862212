/*
 * @Author: your name
 * @Date: 2021-03-18 15:02:42
 * @LastEditTime: 2021-07-16 09:43:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\cpg.js
 */
import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { datatype } from '@/library2/utils/datatype.js'
import { cpgInfo as repCpgInfo } from '@/elearningservice/repository/cpgInfo.js'

export class cpg extends serBasic {
  /**
   * @description: 獲取活動基本數據
   */
  async getCpgInfo(value){
    const obj = new repCpgInfo()
    obj.setTickKey('cpgInfoTick|' + value['cpgNbr'])
    obj.where('cpgNbr').equals(value['cpgNbr'])

    let data = await obj.genData(new request().connectNsuv(), '', { fun: 'cpg', op: 'getCpgInfo' }, { cpgNbr: value['cpgNbr'] }, (data) => {
      data['cpgNbr'] = value['cpgNbr']

      return data
    })

    return datatype.isNull(data) ? super._error() : super._success({ CpgInfo: data.CpgInfo, Extra: data.Extra })
  }

  /**
   * @description: 設定訂單渠道來源 新分享和用戶分享需要設定
   * @param {object} value
   * @return {Promise<object>}
   */
  async setCorpSrc(value){
    let data = await new request().connectNsuv().post('', { fun: 'cpg', op: 'setCorpSrc' }, value)

    return datatype.isNull(data) ? super._error() : super._success(data)
  }
}

