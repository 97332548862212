//
//
//
//
//
//
//
//
//
//
//

import VideoItem from "./item.vue";
import BaseImage from "../../base/Image";
export default {
	name: "HvVideo",
	components: {
		VideoItem,
		BaseImage,
	},
	props: {
		data: Object,
	},
	computed: {},
	data() {
		return {};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
