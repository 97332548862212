//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog } from "element-ui";
import VideoItem from "../../common/Video/item.vue";
export default {
    name: "VideoPopup",
    components: {
        VideoItem,
        [Dialog.name]: Dialog,
    },
    props: {
        data: Object,
    },
    computed: {
        itemFormate() {
            return (item) => {
                let data = {
                    playerOptions: {},
                };
                data.playerOptions = item;
                return data;
            };
        },
    },
    data() {
        return {
            videoPop: false,
            playerOptions: "",
            player: "",
            youtubePlayer: "",
        };
    },
    created() {},
    mounted() {},
    methods: {
        //彈窗動畫結束開啟回調
        openedPop() {
            this.youtubePlayer && this.youtubePlayer.playVideo();
            this.player && this.player.play();
        },
        //彈窗關閉回調
        closePop() {
            this.youtubePlayer && this.youtubePlayer.pauseVideo();
            this.player && this.player.pause();
            this.videoPop = false;
        },
        // 播放器準備完成回調
        playerReadied(player) {
            this.player = player;
        },
        //Youtube準備完成回調
        onYoutubeReady(player) {
            this.youtubePlayer = player;
        },
    },
};
