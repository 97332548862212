"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 16:24:50
 * @LastEditTime: 2021-06-22 17:23:14
 * @LastEditors: Please set LastEditors
 * @Description: log相關類
 * @FilePath: \shop_corp\src\library2\utils\log2.ts
 */
var log = /** @class */ (function () {
    function log() {
    }
    log.addLog = function (msg) {
        var log = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            log[_i - 1] = arguments[_i];
        }
        // if (process.env.VUE_APP_DEBUG) log.length > 0 ? console.log(msg, JSON.parse(JSON.stringify(log))) : console.log(JSON.parse(JSON.stringify(msg)))
    };
    return log;
}());
exports.log = log;
