//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    name: "CheckOutList",
    data() {
        return {};
    },
    mounted() {},
    created() {},
    methods: {},
};
