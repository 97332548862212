//
//
//
//
//
//
//
//
//
//
//
//
//

import MarqueeTips from 'vue-marquee-tips';
export default {
  name: 'Notice',
  components: {
    MarqueeTips,
  },
  props: {
    data: Object,
  },
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
