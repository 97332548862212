//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { Dialog, Input, Form, FormItem, Message, Cascader, CascaderPanel, Checkbox, CheckboxGroup, Radio, RadioGroup, Button } from "element-ui";
import { ctrlAddr } from "@/controller";
export default {
    name: "AddressWin",
    components: {
        [Dialog.name]: Dialog,
        [Form.name]: Form,
        [Input.name]: Input,
        [FormItem.name]: FormItem,
        [CascaderPanel.name]: CascaderPanel,
        [Cascader.name]: Cascader,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Button.name]: Button,
    },
    data() {
        return {
            AddressWinVisible: false,
            adr: {
                gender: "M",
                city: "",
                address: "",
                cityCheck: "",
            },
            areaList: [],
            status: "add", //add新增 edit編輯
            tmpFrcItem: {}, //當前選中類型
            thsAreaCode: "",
        };
    },
    mounted() {},
    created() {
        //獲取省市區地址列表
        this.getAreaList();
    },
    watch: {
        status(value) {
            if (value == "add") {
                this.adr = {
                    gender: "M",
                };
            }
        },
    },
    computed: {
        ...mapState({
            //validateMsg
            msg: function(state) {
                return state["validate"];
            },
        }),
    },
    methods: {
        async getAreaList() {
            this.areaList = await ctrlAddr.getAreaList();
        },
        adrHandleChange() {
            this.thsAreaCode = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels;
        },
        //彈窗關閉事件
        handleCloseAdr() {
            this.AddressWinVisible = false;
            this.$refs["adrRuleForm"].clearValidate();
        },
        //提交地址表單
        async onAddrFormSumbit(formName) {
            if (this.Common.isExist(this.tmpFrcItem.isStore) && !this.Common.isExist(this.adr.city)) return Message.error("請添加地址");
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                let obj = {};
                if (this.Common.isExist(this.tmpFrcItem.isStore)) {
                    obj = {
                        addrNbr: this.adr.id,
                        name: this.adr.name,
                        gender: this.adr.gender,
                        tel: this.adr.tel,
                        storeType: this.tmpFrcItem.storeType,
                        storeId: this.adr.cityCheck,
                        storeName: this.adr.city,
                        storeAddress: this.adr.address,
                        isDefault: this.adr.checked == true ? 1 : 0,
                    };
                    this.adr.id ? await ctrlAddr.updStoreAddr(obj) : await ctrlAddr.addStoreAddr(obj);
                } else {
                    obj = {
                        addrNbr: this.adr.id,
                        name: this.adr.name,
                        gender: this.adr.gender,
                        tel: this.adr.tel,
                        cityId: this.adr.cityCheck.toString(),
                        city: this.thsAreaCode ? this.thsAreaCode.toString() : this.adr.city,
                        address: this.adr.address,
                        isDefault: this.adr.checked == true ? 1 : 0,
                    };
                    this.adr.id ? await ctrlAddr.updAddr(obj) : await ctrlAddr.addAddr(obj);
                }
                this.AddressWinVisible = false;
                this.$emit("upAddressList", this.tmpFrcItem);
            });
        },
        //獲取第三方地址
        openOther() {
            const redirectUrl = `${window.location.protocol}//${window.location.host}${this.tmpFrcItem.storeUrl}`;
            window.open(redirectUrl, "_blank");
            // window.open(redirectUrl, "_blank", "toolbar=yes, width=900, height=700");
        },
    },
};
