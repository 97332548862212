//
//
//
//
//
//

export default {
    name:"RichText",
    components:{
    },
    props: {
        data: Object,
    },
    computed:{
    },
    data(){
        return{
        }
    },
    created(){
    },
    mounted(){
    }
}
