//
//
//
//
//
//
//
//
//
//
//

import { Tabs, TabPane } from "element-ui";
export default {
	name: "HvTabs",
	components: {
		[Tabs.name]: Tabs,
		[TabPane.name]: TabPane,
	},
	props: {
		data: Object,
	},
	computed: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {
		//按鈕點擊回調
		GoodsBtnEvent(btnType, item) {
			this.$emit("GoodsBtnEvent", btnType, item);
		},
	},
};
