"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datatype = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-06-17 11:49:18
 * @Description: 類型判斷相關類
 * @FilePath: \shop_corp\src\library2ts\utils\datatype.ts
 */
var datatype = /** @class */ (function () {
    function datatype() {
    }
    /**
     * @description: 判斷是否為數組
     */
    datatype.isArray = function (obj) {
        return obj instanceof Array;
    };
    /**
     * @description:  判斷是否為json
     */
    datatype.isJson = function (obj) {
        return (!(obj instanceof Array) && (obj instanceof Object));
    };
    /**
     * @description: 判斷數據是否為空
     */
    datatype.isNull = function (val) {
        //字符串
        if (!val)
            return true;
        //undefined
        if (val == 'undefined')
            return true;
        //null
        if (val == 'null')
            return true;
        //NaN
        if (val == 'NaN')
            return true;
        //數組
        if (this.isArray(val)) {
            if (val.length <= 0)
                return true;
            for (var _i = 0, val_1 = val; _i < val_1.length; _i++) {
                var i = val_1[_i];
                if (this.isArray(i)) {
                    if (i.length > 0)
                        return false;
                }
                else if (this.isJson(i)) {
                    if (Object.keys(i).length > 0)
                        return false;
                }
                else {
                    if (![undefined, null, NaN].includes(i))
                        return false;
                }
            }
        }
        //json
        if (this.isJson(val)) {
            if (Object.keys(val).length <= 0)
                return true;
        }
        return false;
    };
    /**
     * @description: 判斷兩個對象是否相同
     */
    datatype.isSameJson = function (j1, j2) {
        var flag = true;
        for (var i in j1) {
            if (typeof (j2[i]) == 'undefined' || j2[i] != j1[i]) {
                flag = false;
                break;
            }
        }
        if (flag) {
            for (var j in j2) {
                if (typeof (j1[j]) == 'undefined' || j1[j] != j2[j]) {
                    flag = false;
                    break;
                }
            }
        }
        return flag;
    };
    return datatype;
}());
exports.datatype = datatype;
