import basic from "@/store/basic.js";
import { ctrlAccount } from "@/controller";
import { Message } from "element-ui";
import route from "@/router/index.js";
import store from "@/store/index.js";
import cookies from "vue-cookie";
class base extends basic {
    constructor() {
        super();
    }

    state = {
        configInfo: {},
        graphicVerifyShow: false, //圖形驗證彈窗
        verifySource: "", //校驗來源
        verifyRes: false, //校驗結果
        verifyParmInfo: {},
        chkAcctExistStat: true,
        needUpdate: false, //是否需要更新PageInfo
        hasCollectCouponByUser:false,//是否允許調用collectCouponByUser接口
    };

    mutations = {
        setConfigInfo(state, val) {
            state.configInfo = val;
        },
        setGraphicVerifyShow(state, val) {
            state.graphicVerifyShow = val;
        },
        setVerifyRes(state, val) {
            state.verifyRes = val;
        },
        setVerifySource(state, val) {
            state.verifySource = val;
        },
        setVerifyParmInfo(state, val) {
            state.verifyParmInfo = val;
        },
        setChkAcctExistStat(state, val) {
            state.chkAcctExistStat = val;
        },
        setSuccess(state, val) {
            state.verifyParmInfo.success = val;
        },
        setNeedUpdate(state, val) {
            state.needUpdate = val;
        },
        setHasCollectCouponByUser(state, val) {
            state.hasCollectCouponByUser = val;
        },
    };

    actions = {
        setConfigInfo: ({ commit }, val) => {
            commit("setConfigInfo", val);
        },
        setGraphicVerifyShow: ({ commit }, val) => {
            commit("setGraphicVerifyShow", val);
        },
        setVerifyRes: ({ commit }, val) => {
            commit("setVerifyRes", val);
        },
        setChkAcctExistStat: ({ commit }, val) => {
            commit("setChkAcctExistStat", val);
        },
        setNeedUpdate: ({ commit }, val) => {
            commit("setNeedUpdate", val);
        },
        setHasCollectCouponByUser: ({ commit }, val) => {
            commit("setHasCollectCouponByUser", val);
        },
        setVerifySource: async ({ commit }, obj) => {
            const loginAfter = async (txt = "登入成功") => {
                Message.success({ type: "success", message: txt });
                commit("setHasCollectCouponByUser", true);
                //存儲用戶個人信息
                const user = await ctrlAccount.getSenMemInfo();
                store.dispatch("user/setUserInfo", user);

                const herf_link = sessionStorage.getItem("herf_link");
                // console.log('herf_link=====',herf_link);
                if (herf_link) {
                    route.replace({ path: herf_link });
                    sessionStorage.removeItem("herf_link");
                    return;
                } else {
                    route.push("/");
                }
            };
            // console.log(loginAfter);
            switch (obj.type) {
                case "forGet":
                    let success = false;
                    let data = null;
                    data = await ctrlAccount.lookup(obj.val);
                    if (data) {
                        success = true;
                        obj = { ...obj, success };
                        console.log(obj);
                    }
                    break;
                case "smsLogin":
                    data = await ctrlAccount.smsLogin(obj.val);
                    if (data) {
                        //手機號碼記錄歷史
                        let telArr = JSON.parse(localStorage.getItem("telArr")) || [];
                        for (var i of telArr) {
                            if (i.value == obj.val.memMobile) break;
                        }
                        telArr.push({ value: obj.val.memMobile });
                        localStorage.setItem("telArr", JSON.stringify(telArr));

                        loginAfter();
                    }
                    break;
                case "userLogin":
                    data = await ctrlAccount.userLogin(obj.val);
                    if (data) loginAfter();
                    break;
                case "chkAcctExist":
                    data = await ctrlAccount.chkAcctExist(obj.val);
                    if (data) {
                        return new Promise((resolve, reject) => {
                            commit("setVerifyParmInfo", obj);
                            resolve(data.isExist);
                        });
                    }
                    // if (data.isExist) {
                    //     commit("setChkAcctExistStat", false);
                    // } else {
                    //     commit("setChkAcctExistStat", true);
                    // }
                    break;
                case "onRegister":
                    data = await ctrlAccount.emailRegister(obj.val);
                    if (data) loginAfter("註冊成功");
                    break;
                case "passwdReset":
                    data = await ctrlAccount.passwdReset(obj.val);
                    if (data) {
                        Message.success({ type: "success", message: "修改成功" });
                        route.push("/");
                    }
                    break;
            }
            commit("setVerifyParmInfo", obj);
        },
    };
}

export default new base();
