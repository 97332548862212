import { account } from "@/elearningservice/service/account.js";
import { order } from "@/elearningservice/service/order.js";
import { datatype } from "@/library2/utils/datatype.js";
import loginError from "@/exception/loginError.js";
import redirect from "@/controller/redirect.js";
import { ctrlCart } from "@/controller";
import route from "@/router/index.js";
import { Message } from "element-ui";
import store from "@/store/index.js";
class crrlOrder {
    //取得訂單明細
    async getDetail(value) {
        return await new order().getDetail({ soNbr: value.nbr });
    }
    //取得訂單明細-脫敏
    async getDesDetail(value) {
        const data = await new order().getDesDetail({ soNbr: value.nbr });
        if (data.response) return data;
    }
    //取得退貨原因
    async getRsnList() {
        const data = await new order().getRsnList();
        if (data.response) return data.RsnList;
    }
    //取得訂單明細
    async getSoStat(soNbr) {
        let value = { soNbr: soNbr };

        let data = await new order().getSoStat(value);

        return data.stat;
    }

    //取得訂單列表 todo
    async getSoList(value) {
        let list = [];
        //全部訂單
        const data = await new order().getSoList(value);
        if (data.response) list = this._genStatDtl(data.SoList);
        return list;
    }
    //取得狀態信息
    _genStatDtl(soList) {
        if (soList.length) {
            for (let i in soList) {
                soList[i]["statDtl"] = this.getOrderStatDtl(soList[i]);
            }
        }

        return soList;
    }
    //加入訂單
    async addOrder(value) {
        
        if(value.carrierType == 3) delete value.carrierType;

        let data = await new order().addOrder(value);

        if (!datatype.isNull(data)) {

            //頁面跳轉
            redirect.push(data);
        }
    }
    //購物車加入訂單
    async addCartOrder(value) {
        let data = await new order().addCartOrder(value);
        if (data.response) {
            route.replace(data.redirectUrl);
        }
    }

    //待付款取消訂單待付款
    async cancelOrder(value) {
        const data = await new order().cancelOrder(value);
        if (data.response) return data.response;
    }

    //已付款取消訂單
    async refund(value) {
        const data = await new order().refund(value);
        if (data.response) return true;
    }

    //取得訂單明細
    async getSoStat(soNbr) {
        let value = { soNbr: soNbr };

        let data = await new order().getSoStat(value);

        return data.stat;
    }
    //退貨申請
    // soNbr	string	是	訂單Nbr
    // rsn	string	是	取消的原因
    // cmmt	string	否	備註
    // Upload	image	是	圖片	必填，多圖；不參與簽名
    async applyRet(soNbr, rsn, cmmt, Upload, receiverName, receiverTel, receiverAddr, receiverCityId, receiverCity, retType) {
        //圖片處理
        let ar = [];
        for (let i of Upload) {
            ar.push(i.raw);
        }

        const data = await new order().applyRet({ soNbr, rsn, cmmt, Upload: ar, receiverName, receiverTel, receiverAddr, receiverCityId, receiverCity, retType });
        if (data.response) {
            Message.success({
                type: "success",
                message: "退貨申請成功",
            });
            setTimeout(() => {
                redirect.push(data);
            }, 1500);
        }
    }
    //部分退貨申請
    async applyPartRet(soNbr, rsn, cmmt, Upload, receiverName, receiverTel, receiverAddr, receiverCityId, receiverCity, retType, SodList) {
        //圖片處理
        let ar = [];
        for (let i of Upload) {
            ar.push(i.raw);
        }

        const data = await new order().applyPartRet({ soNbr, rsn, cmmt, Upload: ar, receiverName, receiverTel, receiverAddr, receiverCityId, receiverCity, retType, SodList });
        if (data.response) {
            Message.success({
                type: "success",
                message: "退貨申請成功",
                // onClose: () => {
                //     redirect.push(data);
                // },
            });
            setTimeout(() => {
                redirect.push(data);
            }, 1500);
        }
    }
    /**
     * 獲取訂單狀態詳情
     * 顯示的文字、圖片及允許操作的權限
     * bugAgain 再次購買
     * cancelOrder 取消訂單
     * reFund  取消訂單-退錢
     * applyAgain 再次申請
     * pay  立即付款
     * exppay  立即付款--體驗
     * afterSale 退貨
     * returns 退貨
     */
    getOrderStatDtl(value) {
        let statDtl = {};

        const stat = value.stat;
        switch (stat) {
            case "pay":
                statDtl = {
                    msg: "待付款",
                    desc: value.payDate ? "截止" + value.payDate + "訂單將自動取消，請盡快付款" : "",
                    permit: ["pay", "cancelOrder"],
                    type: "danger",

                    msg2: "待付款",
                    desc2: value.payDate ? "截止" + value.payDate + "訂單將自動取消，請盡快付款" : "",
                    permit2: ["pay"],
                };
                break;
            case "cancel":
                statDtl = {
                    msg: "已取消",
                    desc: "",
                    permit: value.isExp ? ["applyAgain"] : ["buyAgain"],
                    type: "info",

                    msg2: "已取消",
                    desc2: "",
                    permit2: value.isExp ? ["applyAgain"] : ["buyAgain"],
                };
                break;
            case "pledge":
                statDtl = {
                    msg: "已付款",
                    desc: "",
                    permit: value.isExp ? [] : ["reFund"],
                    type: "success",

                    msg2: "已付款",
                    desc2: "",
                    permit2: value.isExp ? [] : [],
                };
                break;
            case "refunding":
                statDtl = {
                    msg: "退款申請",
                    desc: "您的申請正在受理中",
                    permit: [],
                    type: "warning",

                    msg2: "退款申請",
                    desc2: "您的申請正在受理中",
                    permit2: [],
                };
                break;
            case "shiping":
                statDtl = {
                    msg: "出貨中",
                    desc: "商品配送中，請保持手機通暢",
                    permit: value.isExp ? ["exppay"] : ["afterSale"],
                    type: "success",

                    msg2: "出貨中",
                    desc2: "商品配送中，請保持手機通暢",
                    permit2: value.isExp ? ["exppay"] : [],
                };
                break;
            case "reting":
                statDtl = {
                    msg: "退貨中",
                    desc: "您的退貨申請正在受理中",
                    permit: [],
                    type: "warning",

                    msg2: "退貨中",
                    desc2: "",
                    permit2: [],
                };
                break;
            case "reted":
                statDtl = {
                    msg: "退貨/退款完成",
                    desc: value.isExp ? "感謝您的體驗，您的信用評等已加分，歡迎體驗其它商品" : "感謝您對我們的信任，期待再次光臨",
                    permit: value.isExp ? [] : ["buyAgain"],
                    type: "success",

                    msg2: "退貨/退款完成",
                    desc2: "",
                    permit2: value.isExp ? [] : ["buyAgain"],
                };
                break;
            case "closed":
                statDtl = {
                    msg: "已結案",
                    desc: "",
                    permit: value.isExp ? ["applyAgain"] : ["buyAgain"],
                    type: "success",

                    msg2: "已結案",
                    desc2: "",
                    permit2: value.isExp ? ["applyAgain"] : ["buyAgain"],
                };
                break;
            case "voided":
                statDtl = {
                    msg: "交易關閉",
                    desc: "",
                    permit: value.isExp ? ["applyAgain"] : ["buyAgain"],
                    type: "info",

                    msg2: "交易關閉",
                    desc2: "",
                    permit2: value.isExp ? ["applyAgain"] : ["buyAgain"],
                };
                break;
            case "applying":
                statDtl = {
                    msg: "已成立",
                    desc: "體驗訂單已成立，待出貨",
                    permit: value.isExp ? ["exppay", "cancelOrder"] : [],

                    msg2: "已成立",
                    desc2: "體驗申請中",
                    permit2: value.isExp ? ["exppay"] : [],
                };
                break;
            case "ship":
                statDtl = {
                    msg: "已成立",
                    desc: "商品正在出庫中",
                    permit: value.isExp ? ["exppay", "cancelOrder"] : [],

                    msg2: "已成立",
                    desc2: "",
                    permit2: value.isExp ? ["exppay"] : [],
                };
                break;
            case "rejected":
                statDtl = {
                    msg: "申請拒絕",
                    desc: '未符合申請，如有疑問請聯絡<a href="https://m.me/FiedoraTW?ref=default" target="_blank">客服</a>',
                    permit: [],

                    msg2: "申請拒絕",
                    desc2: "",
                    permit2: [],
                };
                break;
            case "delivered":
                statDtl = {
                    msg: "已簽收",
                    desc: "",
                    permit: ["afterSale"],
                    type: "success",

                    msg2: "已簽收",
                    desc2: "",
                    permit2: [],
                };
                break;
        }

        return statDtl;
    }
    //加入退貨
    async addAfter(value) {
        const data = await new order().addAfter(value);
        data && route.push("/order/after/" + value.soNbr);
    }
    //獲取退貨列表
    async getAfter(value) {
        const data = await new order().getAfter(value);
        if (data.response) return data.SodList;
    }
    //獲取部分退貨的試算結果
    async getPartRetResult(value, isAll) {
        const data = await new order().getPartRetResult(value);
        if (data.response) {
            //全選且數量相等
            isAll ? route.push({ path: "/order/refund/" + value.soNbr }) : route.push({ path: "/order/refund/" + value.soNbr, query: { isPart: true } });
        }
    }
    //獲取部分退貨的試算結果 - 本地存儲結果
    async getPartRetResult2(value) {
        const data = await new order().getPartRetResult2(value);
        if (data.response) return data;
    }
    //上傳折讓單
    async upAllowanceInfo(value) {
        let data = await new order().upAllowanceInfo(value);
        if (data.response) {
            Message.success({
                type: "success",
                message: "上傳成功",
            });
            return true;
        }
    }
    //根據訂單編號返回退貨列表 獲取狀態
    async getRetList1(value) {
        const data = await new order().getRetList1(value);
        if (data.response) return data;
    }
    //根據訂單編號返回退貨列表
    async getRetList2(value) {
        const data = await new order().getRetList2(value);
        if (data.response) return data;
    }
}

export default new crrlOrder();
