//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Divider, Button } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ctrlAccount } from "@/controller";
export default {
    components: {
        [Divider.name]: Divider,
        [Button.name]: Button,
    },
    computed: {
        ...mapState({
            config(state) {
                return state.base.configInfo;
            },
        }),
        isFastLogin() {
            if (this.Common.isExist(this.config.AcctType)) {
                return this.config.AcctType.filter((item) => item != "email" && item != "mobile").length;
            }
        },
    },
    props: {
        data: Object,
    },
    methods: {
        async oauthInit(snsType) {
            // const redirectUrl = sessionStorage.getItem("oauthUrl");
            // sessionStorage.removeItem("oauthUrl");
            let redirectUrl = "";
            sessionStorage.getItem("herf_link") ? (redirectUrl = `${window.location.protocol}//${window.location.host}${sessionStorage.getItem("herf_link").split("_")[0]}`) : (redirectUrl = "");
            
            const data = await ctrlAccount.oauthInit({ snsType, redirectUrl });
            if (data) {
                window.open(data.oauthUrl, "_self");
            }
        },
    },
};
