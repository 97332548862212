import { getSearch, getSearchTags } from "@/api/pt";
import { basic } from "@/hooks";
import store from "@/store";
class pt extends basic {
	constructor() {
		super();
	}
    async getSearch(q) {
        const res = await getSearch({ q });
		// const list = [
		// 	{
		// 		ptNbr: "s1",
		// 		title: "商品1",
		// 		desc: "詳情描述",
		// 		img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
		// 		price: 12,
		// 		origPrice: 15,
		// 		ttlStock: 99,
		// 		link: "/item/index/vu8xh8",
		// 		isLevelPrice: 13,
		// 		levelNbr: "",
		// 	},
		// 	{
		// 		ptNbr: "s2",
		// 		title: "商品2",
		// 		desc: "詳情描述",
		// 		img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
		// 		price: 22,
		// 		origPrice: 30,
		// 		ttlStock: 99,
		// 		link: "/item/index/1xlfzf0",
		// 		isLevelPrice: 13,
		// 		levelNbr: "",
		// 	},
		// ];
		return res;
	}
	async getSearchTags() {
        const res = await getSearchTags();
		// const tags = ["手機", "臺式電腦", "ipad", "iphone", "三星手機", "小米手機"];
		return res;
	}
	async getHistorySearchTags() {
		return store.getters["localStore/getHisList"];
	}
}

export default pt;
