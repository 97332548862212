import router from '@/router/index.js'
const regTest = (value,reg) => {
    return reg.test(value);
}
const linkGoType = (link,linkType) => {
    //特殊環境 /xxx/xx 路由 需要手動拼接domain
    if(process.env.VUE_APP_PRO_NAME == 'saas_drag' && !regTest(link,/http/)) {
        if(process.env.VUE_APP_ENV == 'dev') link = 'http://www.s8.com' + link
        if(process.env.VUE_APP_ENV == 'sit') link = `${window.location.protocol}//${window.location.host}` + link
    }

    if(regTest(link,/http/)){
        linkType?window.open(link,"_blank"):window.location.href = link
    }else{
        if(!linkType){
            router.push(link)
        }else{
            let routeUrl = router.resolve({
                path: link,
            });
            window.open(routeUrl.href, '_blank');
        }
    }
}

export default {
    regTest,
    linkGoType,
}