var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hv-title",class:( _obj = {}, _obj[_vm.data.wrap] = _vm.data.wrap, _obj[_vm.data.align] = _vm.data.align, _obj ),style:({
      'background':_vm.data.bgcolor,
      'text-align':_vm.data.align
  })},[(_vm.data.title)?_c('div',{staticClass:"hv-title__content"},[_c('p',{staticClass:"hv-title__title",style:({
              'font-size':_vm.data.titlefont,
              'color':_vm.data.titlecolor,
              'font-weight':_vm.data.titlebold
          })},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('p',{staticClass:"hv-title__desc",style:({
              'font-size':_vm.data.descfont,
              'color':_vm.data.desccolor,
              'font-weight':_vm.data.descbold
          })},[_vm._v(" "+_vm._s(_vm.data.desc)+" ")])]):_vm._e(),(_vm.data.isShowMore)?_c('div',{staticClass:"hv-title__more",on:{"click":function($event){return _vm.linkGoType(_vm.data.link,_vm.data.linkType)}}},[(_vm.data.moreType == 1 || _vm.data.moreType == 3)?_c('p',[_vm._v(_vm._s(_vm.data.moreText))]):_vm._e(),(_vm.data.moreType == 2 || _vm.data.moreType == 3)?_c('i',{staticClass:"el-icon-arrow-right"}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }