"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessnBasic = void 0;
/*
 * @Author: any
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-06-16 11:21:28
 * @Description: sessionstorage基礎類
 * @FilePath: \shop_corp\src\library2ts\basicClass\sessnBasic.ts
 */
var sessionstorage_js_1 = require("@/library2/storage/sessionstorage.js");
var sessnBasic = /** @class */ (function () {
    function sessnBasic(nbr) {
        if (nbr === void 0) { nbr = null; }
        this._tb = ''; //表名
        this._tickKey = null;
        this._wcIdx = null;
        this._wcVal = null;
        this._nbr = null;
        this._nbr = nbr;
    }
    /**
     * @description: 設定表
     * @param {string} tableName
     * @return {object}
     */
    sessnBasic.prototype.setTable = function (tableName) {
        this._tb = tableName;
        return this;
    };
    /**
     * @description: 新增或修改數據
     * @param {string} value
     */
    sessnBasic.prototype.put = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                obj = new sessionstorage_js_1.sessn();
                obj.put(this._tb, value);
                return [2 /*return*/];
            });
        });
    };
    /**
     * @description: 刪除數據
     */
    sessnBasic.prototype.del = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                obj = new sessionstorage_js_1.sessn();
                return [2 /*return*/, obj.del(this._tb)];
            });
        });
    };
    /**
     * @description: 獲取數據
     */
    sessnBasic.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj = new sessionstorage_js_1.sessn();
                        return [4 /*yield*/, obj.get(this._tb)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //異常處理
    sessnBasic.prototype._error = function (msg) {
        //自拋自接
        try {
            throw new Error('@sessnBasic ' + msg);
        }
        catch (e) {
            console.log(e);
        }
    };
    /**
     * @description: 刪除某張表數據
     */
    sessnBasic.prototype.clear = function () {
        var obj = new sessionstorage_js_1.sessn();
        obj.del(this._tb);
    };
    return sessnBasic;
}());
exports.sessnBasic = sessnBasic;
