//
//
//
//
//
//
//
//
//
//
//

import { Col } from "element-ui";
import BaseImage from "../../base/Image";
import pa from "../../../utils/package";
export default {
    name: "Grid",
    components: {
        [Col.name]: Col,
        BaseImage,
    },
    props: {
        data: Object,
    },
    computed: {
        colSize() {
            switch (this.data.column_num) {
                case 1:
                    return 100;
                case 2:
                    return 50;
                case 3:
                    return 33.33;
                case 4:
                    return 25;
                case 5:
                    return 20;
                case 6:
                    return 16.66;
                case 7:
                    return 14.285;
                case 8:
                    return 12.5;
                default:
                    return 25;
            }
        },
    },
    data() {
        return {
        };
    },
    created() {},
    mounted() {},
    methods: {
        handleClick(link, linkType) {
            if (link) {
                pa.linkGoType(link, linkType);
            }
        },
    },
};
