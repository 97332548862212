var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
define(["require", "exports", "@/library2/utils/dataTrans.js", "@/library2/storage/localstorage.js", "@/library2/utils/datatype.js", "@/library2/utils/log.js", "dexie"], function (require, exports, dataTrans_js_1, localstorage_js_1, datatype_js_1, log_js_1, dexie_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var idxBasic = /** @class */ (function () {
        function idxBasic(db) {
            this._tickKey = '';
            this._sec = 0;
            this._deleted = [];
            this._db = null;
            this._tbName = '';
            this._wc = [];
            if (!this._db) {
                //初始化db
                this._db = new dexie_1.default(db.MAIN_DB);
                this._db.version(db.MAIN_DB_VER).stores(db.TABLE);
            }
        }
        /**
         * @description:  設定表名
         */
        idxBasic.prototype.setTable = function (name) {
            this._tbName = name;
        };
        //清空數據庫
        idxBasic.prototype.clearDB = function () {
            return this._db.delete();
        };
        idxBasic.prototype._getTickInfo = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, false];
                });
            });
        };
        idxBasic.prototype._dealNoDue = function (data) {
            return __awaiter(this, void 0, Promise, function () {
                var tick;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!datatype_js_1.datatype.isNull(data['tick'])) return [3 /*break*/, 2];
                            return [4 /*yield*/, this._getTickInfo()];
                        case 1:
                            tick = _a.sent();
                            if (!tick)
                                return [2 /*return*/, false];
                            this._setTick(tick);
                            return [2 /*return*/, true];
                        case 2: return [2 /*return*/, false];
                    }
                });
            });
        };
        /**
         */
        /**
         * @description: 獲取數據 過期重新獲取 連服務端接口
         * @param {any} reqObj
         * @param {string} url
         * @param {object} params
         * @param {object} value
         * @param {any} dealInput
         * @param {any} dealTick
         */
        idxBasic.prototype.genData = function (reqObj, url, params, value, dealInput) {
            if (value === void 0) { value = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var data, tick, oldTick, data2, tick_1, res, tick, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.get(false)];
                        case 1:
                            data = _a.sent();
                            if (!!datatype_js_1.datatype.isNull(data)) return [3 /*break*/, 9];
                            return [4 /*yield*/, this.getTick()
                                //數據過期
                            ];
                        case 2:
                            tick = _a.sent();
                            if (!!tick) return [3 /*break*/, 9];
                            return [4 /*yield*/, this.getTick(false)
                                //獲取新數據
                            ];
                        case 3:
                            oldTick = _a.sent();
                            //獲取新數據
                            value = __assign({}, value, { tick: datatype_js_1.datatype.isNull(oldTick) ? 0 : oldTick });
                            return [4 /*yield*/, reqObj.post(url, params, value)
                                //返回值中不含tick，說明數據未過期，重新將本地數據置為有效
                            ];
                        case 4:
                            data2 = _a.sent();
                            return [4 /*yield*/, this._dealNoDue(data2)];
                        case 5:
                            //返回值中不含tick，說明數據未過期，重新將本地數據置為有效
                            if (_a.sent()) {
                                this.clearWc();
                                return [2 /*return*/, JSON.parse(JSON.stringify(data))];
                            }
                            if (!data2) return [3 /*break*/, 9];
                            tick_1 = data2.tick;
                            //刪除舊數據
                            return [4 /*yield*/, this._db[this._tbName].clear()];
                        case 6:
                            //刪除舊數據
                            _a.sent();
                            data = data2;
                            if (!dealInput) return [3 /*break*/, 8];
                            return [4 /*yield*/, dealInput(data)];
                        case 7:
                            res = _a.sent();
                            if (!datatype_js_1.datatype.isNull(res)) {
                                data = res;
                            }
                            else {
                                data = [];
                            }
                            _a.label = 8;
                        case 8:
                            if (this._sec) {
                                tick_1 = Date.now() / 1000;
                            }
                            //存儲
                            if (!datatype_js_1.datatype.isNull(data))
                                this.add(data, tick_1);
                            _a.label = 9;
                        case 9:
                            if (!datatype_js_1.datatype.isNull(data)) return [3 /*break*/, 13];
                            return [4 /*yield*/, reqObj.post(url, params, __assign({}, (datatype_js_1.datatype.isNull(value) ? {} : value), { tick: 0 }))];
                        case 10:
                            //獲取服務器數據
                            data = _a.sent();
                            if (datatype_js_1.datatype.isNull(data))
                                return [2 /*return*/, this._error('@genData service err')
                                    //獲取接口傳遞的tick
                                ];
                            tick = data.tick;
                            if (!dealInput) return [3 /*break*/, 12];
                            return [4 /*yield*/, dealInput(data)];
                        case 11:
                            res = _a.sent();
                            if (!datatype_js_1.datatype.isNull(res)) {
                                data = res;
                            }
                            else {
                                data = [];
                            }
                            _a.label = 12;
                        case 12:
                            if (!tick && this._sec) {
                                tick = Date.now() / 1000;
                            }
                            //存儲
                            if (!datatype_js_1.datatype.isNull(data))
                                this.add(data, tick);
                            _a.label = 13;
                        case 13:
                            this.clearWc();
                            return [2 /*return*/, JSON.parse(JSON.stringify(data))];
                    }
                });
            });
        };
        idxBasic.prototype._chktick = function (tick) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, true];
                });
            });
        };
        /**
         * @description: 增量式獲取數據
         * @param {any} reqObj
         * @param {string} url
         * @param {object} params
         * @param {object} value
         * @param {any} dealInput
         * @param {any} dealTick
         * @param {*} oldTick
         */
        idxBasic.prototype.genData2 = function (reqObj, url, params, value, dealInput, oldTick) {
            if (value === void 0) { value = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var value_bak, local_data, data, data_bak, tick, res, data2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            value_bak = JSON.parse(JSON.stringify(value));
                            if (!!oldTick) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.getTick(false)
                                //本地數據
                            ];
                        case 1:
                            oldTick = _a.sent();
                            _a.label = 2;
                        case 2: return [4 /*yield*/, this._db[this._tbName].toArray()];
                        case 3:
                            local_data = _a.sent();
                            if (datatype_js_1.datatype.isNull(oldTick) || datatype_js_1.datatype.isNull(local_data))
                                oldTick = 0;
                            return [4 /*yield*/, this._chktick(oldTick)];
                        case 4:
                            if (!((_a.sent()) || datatype_js_1.datatype.isNull(local_data))) return [3 /*break*/, 13];
                            value = __assign({}, value, { tick: datatype_js_1.datatype.isNull(oldTick) ? 0 : oldTick });
                            return [4 /*yield*/, reqObj.post(url, params, value)];
                        case 5:
                            data = _a.sent();
                            return [4 /*yield*/, this._dealNoDue(data)];
                        case 6:
                            if (!!(_a.sent())) return [3 /*break*/, 13];
                            data_bak = JSON.parse(JSON.stringify(data));
                            tick = data.tick;
                            //合並待刪除的編號
                            if (!datatype_js_1.datatype.isNull(data.Deleted)) {
                                this._deleted = this._deleted.concat(data.Deleted);
                                this._deleted = dataTrans_js_1.dataTrans.arrayUnique(this._deleted);
                            }
                            if (!dealInput) return [3 /*break*/, 8];
                            return [4 /*yield*/, dealInput(data)];
                        case 7:
                            res = _a.sent();
                            if (!datatype_js_1.datatype.isNull(res)) {
                                data = res;
                            }
                            else {
                                data = [];
                            }
                            _a.label = 8;
                        case 8:
                            if (!!datatype_js_1.datatype.isNull(data)) return [3 /*break*/, 10];
                            return [4 /*yield*/, this.put(data, tick)
                                //再次發起請求
                            ];
                        case 9:
                            _a.sent();
                            _a.label = 10;
                        case 10:
                            if (!data_bak.isNext) return [3 /*break*/, 11];
                            this.genData2(reqObj, url, params, value_bak, dealInput, tick);
                            return [3 /*break*/, 13];
                        case 11:
                            if (!!datatype_js_1.datatype.isNull(this._deleted)) return [3 /*break*/, 13];
                            return [4 /*yield*/, this._db[this._tbName].bulkDelete(this._deleted)];
                        case 12:
                            _a.sent();
                            _a.label = 13;
                        case 13: return [4 /*yield*/, this.get(false)];
                        case 14:
                            data2 = _a.sent();
                            this.clearWc();
                            return [2 /*return*/, JSON.parse(JSON.stringify(data2))];
                    }
                });
            });
        };
        /**
         * @description: 新增數據
         * @param {any} value
         * @param {string} tick
         */
        idxBasic.prototype.add = function (value, tick) {
            if (tick === void 0) { tick = ''; }
            return __awaiter(this, void 0, void 0, function () {
                var cnt;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!tick)
                                return [2 /*return*/, this._error('@add no tick')];
                            log_js_1.log.addLog('@idxBasic @add value params', value);
                            cnt = 0;
                            if (!datatype_js_1.datatype.isArray(value)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this._db[this._tbName].bulkAdd(value)];
                        case 1:
                            cnt = _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this._db[this._tbName].add(value)];
                        case 3:
                            cnt = _a.sent();
                            _a.label = 4;
                        case 4:
                            if (tick)
                                this._setTick(tick);
                            return [2 /*return*/, cnt];
                    }
                });
            });
        };
        /**
         * @description: 新增或修改數據
         * @param {any} value
         * @param {string} tick
         */
        idxBasic.prototype.put = function (value, tick) {
            if (tick === void 0) { tick = ''; }
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            log_js_1.log.addLog('@idxBasic @put value params', value);
                            if (!datatype_js_1.datatype.isArray(value)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this._db[this._tbName].bulkPut(value)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this._db[this._tbName].put(value)];
                        case 3:
                            res = _a.sent();
                            _a.label = 4;
                        case 4:
                            if (tick)
                                this._setTick(tick);
                            return [2 /*return*/, res];
                    }
                });
            });
        };
        /**
         * @description: 修改數據
         * @param {any} value
         */
        idxBasic.prototype.update = function (value) {
            return __awaiter(this, void 0, void 0, function () {
                var wc;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this._genWc()];
                        case 1:
                            wc = _a.sent();
                            return [4 /*yield*/, wc.modify(value)];
                        case 2: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        };
        idxBasic.prototype.where = function (field) {
            if (datatype_js_1.datatype.isArray(field)) {
                this._wc['where'] = field.join('+');
            }
            else {
                this._wc['where'] = field;
            }
            return this;
        };
        idxBasic.prototype.equals = function (value) {
            this._wc['equals'] = value;
            return this;
        };
        idxBasic.prototype.and = function (value) {
            this._wc['and'] = value;
            return this;
        };
        idxBasic.prototype.reverse = function () {
            this._wc['_reverse'] = true;
            return this;
        };
        idxBasic.prototype.sortBy = function (value) {
            this._wc['sortBy'] = value;
            return this;
        };
        idxBasic.prototype.offset = function (cnt) {
            this._wc['offset'] = cnt;
            return this;
        };
        idxBasic.prototype.limit = function (cnt) {
            this._wc['limit'] = cnt;
            return this;
        };
        /**
         * @description: 取值
         * @param {*} chkValid 為true檢查數據是否有效
         */
        idxBasic.prototype.get = function (chkValid) {
            if (chkValid === void 0) { chkValid = true; }
            return __awaiter(this, void 0, void 0, function () {
                var wc, data, length, tick;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this._genWc()];
                        case 1:
                            wc = _a.sent();
                            if (!this._wc['sortBy']) return [3 /*break*/, 3];
                            return [4 /*yield*/, wc.sortBy(this._wc['sortBy'])];
                        case 2:
                            data = _a.sent();
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, wc.toArray()];
                        case 4:
                            data = _a.sent();
                            _a.label = 5;
                        case 5:
                            if (datatype_js_1.datatype.isNull(data))
                                return [2 /*return*/, false];
                            length = data.length;
                            if (length == 1) {
                                data = data['0'];
                            }
                            //檢查是否過期
                            if (chkValid) {
                                tick = this.getTick(true);
                                if (!tick)
                                    return [2 /*return*/, false];
                            }
                            return [2 /*return*/, data];
                    }
                });
            });
        };
        idxBasic.prototype._genWc = function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = this._db[this._tbName];
                            // console.log(777);
                            // console.log(this._wc['and']);
                            if (!datatype_js_1.datatype.isNull(this._wc['where']))
                                data = data.where(this._wc['where']);
                            if (!datatype_js_1.datatype.isNull(this._wc['equals']))
                                data = data.equals(this._wc['equals']);
                            if (this._wc['and'])
                                data = data.and(this._wc['and']);
                            if (!!datatype_js_1.datatype.isNull(this._wc['_reverse'])) return [3 /*break*/, 2];
                            return [4 /*yield*/, data.reverse()];
                        case 1:
                            data = _a.sent();
                            _a.label = 2;
                        case 2:
                            if (!datatype_js_1.datatype.isNull(this._wc['offset']))
                                data = data.offset(this._wc['offset']);
                            if (!datatype_js_1.datatype.isNull(this._wc['limit']))
                                data = data.limit(this._wc['limit']);
                            return [2 /*return*/, data];
                    }
                });
            });
        };
        idxBasic.prototype.clearWc = function () {
            this._wc = [];
        };
        /**
         * @description:  刪除數據
         */
        idxBasic.prototype.del = function () {
            return __awaiter(this, void 0, void 0, function () {
                var wc;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this._genWc()];
                        case 1:
                            wc = _a.sent();
                            return [4 /*yield*/, wc.delete()];
                        case 2: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        };
        idxBasic.prototype.clear = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this._db[this._tbName].clear()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        };
        /**
         * @description: 設定過期tick的key
         */
        idxBasic.prototype.setTickKey = function (key) {
            this._tickKey = key;
        };
        /**
         * @description: 刪除tick
         */
        idxBasic.prototype.delTick = function () {
            return __awaiter(this, void 0, void 0, function () {
                var obj, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            obj = new localstorage_js_1.ls();
                            return [4 /*yield*/, obj.get(this._tickKey)];
                        case 1:
                            data = _a.sent();
                            if (datatype_js_1.datatype.isNull(data))
                                return [2 /*return*/]; //重復刪除不報錯
                            obj.put(this._tickKey, null);
                            return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * @description: 取得tick
         */
        idxBasic.prototype.getTick = function (chkValid) {
            if (chkValid === void 0) { chkValid = true; }
            return __awaiter(this, void 0, void 0, function () {
                var obj, data, tick;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            obj = new localstorage_js_1.ls();
                            return [4 /*yield*/, obj.get(this._tickKey)];
                        case 1:
                            data = _a.sent();
                            if (datatype_js_1.datatype.isNull(data))
                                return [2 /*return*/, false
                                    //檢查數據是否過期
                                ];
                            if (!chkValid) return [3 /*break*/, 3];
                            return [4 /*yield*/, this._getTickInfo()];
                        case 2:
                            tick = _a.sent();
                            if (!tick)
                                return [2 /*return*/, false];
                            if (tick > data.tick) {
                                return [2 /*return*/, false]; //過期
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/, data.tick];
                    }
                });
            });
        };
        //設定過期時間sec優先級大於後臺返回的tick
        idxBasic.prototype.setExpires = function (t) {
            this._sec = t;
        };
        idxBasic.prototype._setTick = function (tick) {
            if (datatype_js_1.datatype.isNull(this._tickKey))
                return this._error('@_setTick no tick key');
            if (datatype_js_1.datatype.isNull(tick))
                return this._error('@_setTick no tick');
            var obj = new localstorage_js_1.ls();
            var val = { tick: tick };
            //設定時效
            if (this._sec) {
                val['expires'] = (Number(this._sec) + Number(Date.now() / 1000)).toFixed(2);
            }
            obj.put(this._tickKey, val);
        };
        //異常處理
        idxBasic.prototype._error = function (msg) {
            //自拋自接
            try {
                throw new Error('@inxBasic ' + msg);
            }
            catch (e) {
                console.log(e);
            }
        };
        return idxBasic;
    }());
    exports.idxBasic = idxBasic;
});
