import { invoice } from "@/elearningservice/service/invoice.js";
import loginError from "@/exception/loginError.js";
import { account } from "@/elearningservice/service/account.js";
import { datatype } from "@/library2/utils/datatype.js";
import store from "@/store/index.js";
import { Message } from "element-ui";
import route from "@/router/index.js";
class inv {
    _storeNameSpace = "order";
    //取得所有發票訊息
    async getInvList() {
        const data = await new invoice().getInvList();
        return data.InvList;
    }
    //修改發票訊息
    async updInvInfo(value) {
        const data = await new invoice().updInvInfo(value);
        if (data.response) return data;
    }
}

export default new inv();
