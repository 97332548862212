//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavItem from "./item.vue";
import { Menu } from "element-ui";
export default {
    name: "NavBar",
    components: {
        [Menu.name]: Menu,
        NavItem,
    },
    props: {
      data: {
            type: Object,
            default: () => {},
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        bgcolor: {
            type: String,
            default: "#545c64",
        },
        activecolor: {
            type: String,
            default: "#ffd04b",
        },
        textcolor: {
            type: String,
            default: "#fff",
        },
    },
    computed: {},
    created() {
        // console.log(this.data);
    },
};
