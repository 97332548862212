/*
 * @Author: your name
 * @Date: 2021-06-16 10:17:25
 * @LastEditTime: 2021-06-16 11:19:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_corp\src\elearningservice\repository\captcha.ts
 */
import { sessnBasic } from '@/library2/basicClass/sessnBasic.js'

export class captcha extends sessnBasic {
	constructor() {
		super()
		this._mySetParam()
	}

	_mySetParam() {
		super.setTable('captcha')

		// super.setTickKey('captchaTick')

		// //緩存十分鐘
		// super.setExpires(600)
	}
}

