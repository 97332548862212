import { rsn } from "@/elearningservice/repository/rsn.js";
import { orderList as repOrder } from "@/elearningservice/repository/orderList.js";
import { afterList as repAfterList } from "@/elearningservice/repository/afterList.js";
import { partRetResult as repPartRetResult } from "@/elearningservice/repository/partRetResult.js";
import { partRetList as repPartRetList } from "@/elearningservice/repository/partRetList.js";
import { account } from "@/elearningservice/service/account.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { datatype } from "@/library2/utils/datatype.js";
import { request } from "@/elearningservice/service/request.js";
import { coupon as serCpn } from "@/elearningservice/service/coupon.js";
import { invoice as serInv } from "@/elearningservice/service/invoice.js";
import { log } from "@/library2/utils/log.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { browser } from "@/library2/utils/browser.js";
import { cart as serCart } from "@/elearningservice/service/cart.js";

export class order extends serBasic {
    /**
     * @description: 取得退貨申請的原因
     */
    async getRsnList() {
        let userNbr = new account().getAuth();
        if (!userNbr) return super._error();

        const obj = new rsn();
        obj.setTickKey("rsnListTick|" + userNbr);
        obj.where("userNbr").equals(userNbr);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "order", op: "getRsnList" }, { userNbr: userNbr });

        return datatype.isNull(data) ? super._error() : super._success({ RsnList: data.RsnList });
    }

    /**
     * @description: 刪除order相關數據
     */
    async delStorageOrders() {
        const obj = new repOrder();
        obj.clear();
    }

    /**
     * @description: 退貨申請
     */
    async applyRet(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const options = { encryptFields: ["receiverName", "receiverTel"] };
        const req = new request().connectNsuv(options);

        if (!datatype.isNull(value["Upload"])) {
            req.setFileList({ name: "Upload", files: value.Upload });
            value = dataTrans.unset(value, "Upload");
        }
        // if (!datatype.isNull(value["Upload"])) {
        //     let file = { Upload: value["Upload"] };
        //     req.setFile(file);
        //     value = dataTrans.unset(value, "Upload");
        // }
        let data = await req.post("", { fun: "order", op: "applyRet" }, value);
        if (!datatype.isNull(data)) {
            //本地刪除數據
            this.delStorageOrders();
            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success(data);
        }
        return super._error();
    }

    /**
     * @description: 部分退貨申請
     */
    async applyPartRet(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();
        value["SodList"] = JSON.stringify(value["SodList"]);

        value = { ...value, ...{ userNbr: nbr } };

        const options = { encryptFields: ["receiverName", "receiverTel"] };
        const req = new request().connectNsuv(options);

        if (!datatype.isNull(value["Upload"])) {
            req.setFileList({ name: "Upload", files: value.Upload });
            value = dataTrans.unset(value, "Upload");
        }
        let data = await req.post("", { fun: "ret", op: "applyPartRet" }, value);
        if (!datatype.isNull(data)) {
            //本地刪除數據
            this.delStorageOrders();
            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success(data);
        }
        return super._error();
    }

    /**
     * @description: 獲取訂單明細
     */
    async getDetail(value) {
        //獲取userNbr
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { userNbr: nbr, soNbr: value["soNbr"] };

        //crypt fields
        const options = { decryptFields: { SoInfo: ["receiverNameOrig", "receiverTelOrig", "memEmailOrig"] } };

        const req = new request().connectNsuv(options);
        let data = await req.post("", { fun: "order", op: "getDetail" }, value);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 獲取脫敏訂單明細
     */
    async getDesDetail(value) {
        const req = new request().connectNsuv();
        let data = await req.post("", { fun: "order", op: "getDesDetail" }, value);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 取消訂單--未付款
     */
    async cancelOrder(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "order", op: "cancelOrder" }, value);

        if (!datatype.isNull(data)) {
            //刪除訂單相關數據
            this.delStorageOrders();

            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success();
        }
        return super._error();
    }

    /**
     * @description: 取消訂單-已付款
     */
    async refund(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "order", op: "refund" }, value);

        if (!datatype.isNull(data)) {
            //刪除訂單相關數據
            this.delStorageOrders();
            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success();
        }
        return super._error();
    }

    /**
     * @description: 獲取訂單列表
     */
    async getSoList(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new repOrder();
        obj.setTickKey("soListTick|" + nbr + "|" + value["stat"]);
        obj.where("[userNbr+_stat]").equals([nbr, value["stat"]]);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "order", op: "getSoList" }, { userNbr: nbr, stat: value["stat"], lastPage: value["lastPage"] }, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);
                data.map((item) => {
                    item["userNbr"] = nbr;
                    item["_stat"] = value["stat"];
                });
                return data;
            }
        });

        return datatype.isNull(data) ? super._error() : super._success({ SoList: super._toBeArray(data) });
    }

    /**
     * @description: 獲取訂單裝填
     */
    async getSoStat(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "order", op: "getSoStat" }, value);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 加入訂單
     */
    async addOrder(value) {
        let nbr = new account().getAuth();
        if (!nbr) super._error();

        value["PtList"] = JSON.stringify(value["PtList"]);

        value = { ...value, ...{ userNbr: nbr } };

        //crypt fields
        const options = { encryptFields: ["receiverName", "receiverTel", "memEmail", "invTel", "invEmail","invTitle"] };

        const req = new request().connectNsuv(options);
        let data = await req.post("", { fun: "order", op: "addOrder" }, value);
        if (!datatype.isNull(data)) {
            //刪除訂單相關數據
            this.delStorageOrders();

            //刪除發票訊息
            new serInv().delStorageInvs();

            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success({ redirectUrl: data.redirectUrl });
        }
        return super._error();
    }

    /**
     * @description: 團購加入訂單
     */
    async addGrpOrder(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value["PtList"] = JSON.stringify(value["PtList"]);

        value = { ...value, ...{ userNbr: nbr } };

        //crypt fields
        const options = { encryptFields: ["receiverName", "receiverTel", "memEmail", "invTel", "invEmail"] };

        const req = new request().connectNsuv(options);
        let data = await req.post("", { fun: "fun", op: "addGrpOrder" }, value);
        if (!datatype.isNull(data)) {
            //刪除訂單相關數據
            this.delStorageOrders();

            //刪除發票訊息
            new serInv().delStorageInvs();

            //刪除優惠券
            new serCpn().delCoupons();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success({ redirectUrl: data.redirectUrl });
        }
        return super._error();
    }

    /**
     * @description: 加入購物車
     */
    async addCartOrder(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value["CartList"] = JSON.stringify(value["CartList"]);
        value = { ...value, ...{ userNbr: nbr } };

        //crypt fields
        const options = { encryptFields: ["receiverName", "receiverTel", "memEmail", "invTel", "invEmail"] };

        const req = new request().connectNsuv(options);
        let data = await req.post("", { fun: "order", op: "addCartOrder" }, value);
        if (!datatype.isNull(data)) {
            //刪除訂單相關數據
            this.delStorageOrders();

            //刪除發票訊息
            new serInv().delStorageInvs();

            //刪除優惠券
            new serCpn().delCoupons();

            new serCart().delCartList();

            if (browser.getBrowserTp() == "fiedoraapp") {
                window.HVJS.notifyNsHomeRefresh();
            }

            return super._success({ redirectUrl: data.redirectUrl });
        }
        return super._error();
    }

    /**
     * @description: 加入售後
     */
    async addAfter(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const info = { ...value, userNbr: nbr };
        const obj = new repAfterList();
        await obj.put(info);

        return super._success();
    }

    /**
     * @description: 獲取售後列表
     */
    async getAfter(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new repAfterList();
        obj.where("[userNbr+soNbr]").equals([nbr, value["SoNbr"]]);

        let data = await obj.get(false);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 16.10部分退貨的試算結果
     */
    async getPartRetResult(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();
        value["SodList"] = JSON.stringify(value["SodList"]);
        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "ret", op: "getPartRetResult" }, value);
        if (!datatype.isNull(data)) {
            if (!datatype.isNull(data.RetdList)) {
                for (const item of data.RetdList) {
                    item.qtyOrd = item.qtyRet;
                }
            }
            
            // data.CouponList = [
            //     {
            //         couponNbr:1,
            //         title:"優惠券名稱",
            //         desc:"優惠券內容",
            //         amtClawback:15
            //     },
            //     {
            //         couponNbr:1,
            //         title:"優惠券名稱",
            //         desc:"優惠券內容",
            //         amtClawback:35
            //     },
            //     {
            //         couponNbr:1,
            //         title:"優惠券名稱",
            //         desc:"優惠券內容",
            //         amtClawback:15
            //     },
            // ];
            if (!datatype.isNull(data.CouponList)) {
                for (const item of data.CouponList) {
                    item.name = item.title;
                    item.discType = 2;
                    item.amtDisc = item.amtClawback;
                }
            }
            await this.addPartRetResult2(data, nbr, value.soNbr);
            return super._success(data);
        }
        return super._error();
    }
    /**
     * @description: 16.10部分退貨的試算結果-本地存儲
     */
    async addPartRetResult2(value, nbr, soNbr) {
        const info = { ...value, userNbr: nbr, soNbr };
        const obj = new repPartRetResult();
        await obj.put(info);

        return super._success();
    }

    /**
     * @description: 獲取部分退貨的試算結果-本地存儲
     */
    async getPartRetResult2(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new repPartRetResult();
        obj.where("[userNbr+soNbr]").equals([nbr, value["soNbr"]]);

        let data = await obj.get(false);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description: 上傳折讓單
     */
    async upAllowanceInfo(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        const req = new request().connectNsuv();

        // if (!datatype.isNull(value["Upload1"])) {
        //     req.setFileList({ name: "Upload1", files: [value.Upload1] });
        //     value = dataTrans.unset(value, "Upload1");
        // }
        // if (!datatype.isNull(value["Upload2"])) {
        //     req.setFileList({ name: "Upload2", files: [value.Upload2] });
        //     value = dataTrans.unset(value, "Upload2");
        // }

        let data = await req.post("", { fun: "ret", op: "postRetImg" }, value);

        return datatype.isNull(data) ? super._error() : super._success();
    }
    /**
     * @description: 根據訂單編號返回退貨列表- 遠程
     */
    async getRetList1(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "ret", op: "getRetList" }, value);
        if (!datatype.isNull(data)) {
            if (!datatype.isNull(data.RetList)) {
                for (const item of data.RetList) {
                    if (datatype.isNull(item.RetdList)) return;
                    for (const retd of item.RetdList) {
                        retd.qtyOrd = retd.qtyRet;
                    }
                }
            }
            //存儲列表 todo
            this.addRetList(data, value.soNbr);
            //返回狀態
            return super._success(data);
        }
        return super._error();
    }

    /**
     * @description: 根據訂單編號返回退貨列表- 存儲
     */
    async addRetList(value, soNbr) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const info = { ...value, userNbr: nbr, soNbr };
        const obj = new repPartRetList();
        await obj.put(info);

        return super._success();
    }

    /**
     * @description: 根據訂單編號返回退貨列表
     */
    async getRetList2(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        const obj = new repPartRetList();
        obj.where("[userNbr+soNbr]").equals([nbr, value["soNbr"]]);

        let data = await obj.get(false);
        if (datatype.isNull(data)) {
            const ret = await this.getRetList1(value);
            return datatype.isNull(ret) ? super._error() : ret;
        } else {
            return super._success(data);
        }
    }
}
