//
//
//
//
//
//
//
//

import BaseImage from "../../base/Image";
export default {
	name: "Vtour",
	components: {
		BaseImage,
	},
	props: {
		data: Object,
	},
	computed: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {
		clickhandle() {
			window.open(this.data.vtourPreviewUrl, "_self");
		},
	},
};
