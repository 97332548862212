/*
 * @Author: your name
 * @Date: 2021-07-15 12:33:44
 * @LastEditTime: 2021-07-16 09:31:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\repository\partRetList.ts
 */
import {idxBasic} from '@/library2/basicClass/idxBasic.js'
import DB from '@/elearningservice/repository/DB.js'
import {tick} from '@/elearningservice/repository/tick.js'

export class partRetList extends idxBasic {
	_tkey = 'MemTickInfo.memberTick'

	constructor() {
		super(DB)
		this._mySetParam()
	}

	_mySetParam() {
		super.setTable('partRetList')

		super.setTickKey('partRetListTick')
		//緩存一周時間
		super.setExpires(86400 * 7)
	}

	async _getTickInfo() {
		let obj = new tick()
		let tickInfo = await obj.get(false)
		if (!tickInfo) return false

		return eval('tickInfo.' + this._tkey)
	}
}

