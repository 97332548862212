export default function ExtendableBuiltIn(cls){

  function buildIn(){

      cls.apply(this, arguments);

  }

  buildIn.prototype = Object.create(cls.prototype);

  buildIn.__proto__ = cls;

  return buildIn
}
