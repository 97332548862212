/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 09:44:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\info.ts
 */
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { datatype } from "@/library2/utils/datatype.js";
import { account } from "@/elearningservice/service/account.js";
import store from "@/store";
export class comment extends serBasic {
	/**
	 * @description: 獲取評論列表
	 */
	async getCommentList(value) {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();
		const { ptNbr, chpdNbr, page } = value;
		const key = page + ptNbr + (chpdNbr || "");
		if (!datatype.isNull(store.state.comment.commentInfo[key])) return super._success(store.state.comment.commentInfo[key]);
		value = { userNbr: nbr, ...value };

		const req = new request().connectNsuv();
		const data = await req.post("", { fun: "comment", op: "getCommentList" }, value);
		if (data) store.dispatch("comment/setCommentInfo", { ...data, key });
		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 獲取留言詳情列表
	 */
	async getReplyList(value) {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, ...value };

		const req = new request().connectNsuv();
		let data = await req.post("", { fun: "comment", op: "getReplyList" }, value);

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 新增評論回復
	 */
	async addComment(value) {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, ...value };

		const req = new request().connectNsuv();
		let data = await req.post("", { fun: "comment", op: "addComment" }, value);

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	/**
	 * @description: 新增留言回復
	 */
	 async addReply(value) {
		let nbr = new account().getAuth();
		if (!nbr) return super._error();

		value = { userNbr: nbr, ...value };

		const req = new request().connectNsuv();
		let data = await req.post("", { fun: "comment", op: "addReply" }, value);

		return datatype.isNull(data) ? super._error() : super._success(data);
	}

	//清除緩存
	clearCommentInfo() {
		store.dispatch("comment/clearCommentInfo");
	}
}
