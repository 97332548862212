//
//
//
//
//
//

import BaseImage from "../../base/Image"
export default {
    name:"ImageAD",
    components:{
      BaseImage
    },
    props: {
        data: Object,
    },
    computed:{
    },
    data(){
        return{
        }
    },
    created(){
    },
    mounted(){
    }
}
