import { shop } from "@/elearningservice/service/shop.js";
import redirect from "@/controller/redirect.js";
class crtlShop {
	//獲取預設捐贈碼
	async getLoveCodeList() {
		let data = await new shop().getLoveCodeList();
		if (data.response) {
			for (const item of data.list) {
				item.value = item.name;
			}
			return data.list;
		}
	}
}

export default new crtlShop();
