//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { FormItem, Form, Input, Select, RadioGroup, Radio, Option, Link, Autocomplete } from "element-ui";
import { ctrlShop } from "@/controller";
export default {
	name: "comment",
	components: {
		[Form.name]: Form,
		[FormItem.name]: FormItem,
		[Input.name]: Input,
		[Select.name]: Select,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Option.name]: Option,
		[Link.name]: Link,
		[Autocomplete.name]: Autocomplete,
	},
	props: {
		inv: Object,
	},
	computed: {
		...mapState({
			//validateMsg
			msg(state) {
				return state["validate"];
			},
		}),
        configInfo() {
            return this.$store.state.base.configInfo;
        },
	},
	data() {
		return {
			rules: null,
			restaurants: [],
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.$emit("getInvForm", this.$refs["invForm"]);
			this.getLoveCodeList();
		});
	},
	created() {
		this.getRules();
	},
	methods: {
		getRules() {
			this.rules = {
				name: [{ required: true, message: this.msg.req, trigger: "blur" }],
				email: [{ required: true, validator: this.msg.eleIsEmail, message: this.msg.email, trigger: "blur" }],
				invType: [{ required: true, message: "請選擇發票類型", trigger: "change" }],
				carrierID: [{ required: true, validator: this.chkCarrierID, trigger: "blur" }],
				invTitle: [{ required: true, message: this.msg.req, trigger: "blur" }],
				taxNo: [{ required: true, message: this.msg.req, trigger: "blur" }],
				loveCode: [{ min: 3, max: 5, message: "長度在 3 到 5 個字符", trigger: "blur" }],
			};
		},
		chkCarrierID(rule, value, callback) {
			if (this.inv.carrierType == 1) {
				const reg = /^\/[0-9a-zA-Z+-.]{7}$/;
				if (value === "") {
					callback(new Error("請輸入正確內容"));
				} else if (!reg.test(this.inv.carrierID)) {
					callback(new Error("手機條碼格式不正確"));
				} else {
					callback();
				}
			} else if (this.inv.carrierType == 2) {
				const reg = /^[A-Z]{2}[0-9]{14}$/;
				if (value === "") {
					callback(new Error("請輸入正確內容"));
				} else if (!reg.test(this.inv.carrierID)) {
					callback(new Error("自然人憑證條碼格式不正確"));
				} else {
					callback();
				}
			}
		},
		invTypeChange(item) {
			this.$refs["invForm"].clearValidate(["carrierID", "invTitle", "taxNo", "loveCode"]);
		},
		async getLoveCodeList() {
			this.restaurants = await ctrlShop.getLoveCodeList();
			console.log(this.restaurants);
		},
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 調用 callback 返回建議列表的數據
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
			};
		},
		handleSelect(item) {
			this.inv.loveCode = item.code;
		},
	},
};
