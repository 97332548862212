//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseImage from "../../base/Image";
import ComVideo from "../../common/Video";
import VtourPc from "../Vtour";
import { Carousel, CarouselItem, Image } from "element-ui";
import { isExist } from "../../../utils";
export default {
	name: "HvSwiper2",

	components: {
		BaseImage,
		ComVideo,
		VtourPc,
		[Carousel.name]: Carousel,
		[CarouselItem.name]: CarouselItem,
		[Image.name]: Image,
	},
	props: {
		data: Object,
		type:"",
		isResizeHeight:{
			type:Boolean,
			default:true
		}
	},
	watch: {
		data: {
			handler(val, oldVal) {
				this.autoplay = Boolean(val.autoplay)
				this.interval = (val.interval || 5) * 1000
				//兼容舊的數據結構
				if (val.imgList || val.videoList) {
					const imgList = val.imgList || [];
					const videoList = val.videoList || [];
					if (!val.list) this.$set(val, "list", imgList.concat(videoList));
					// val.list = imgList.concat(videoList);
				}
				// this.initHeight();
			},
			immediate: true,
		},
		//     contentWidth(val){
		//         this.contentWidth = val
		//         console.log(this.contentWidth);
		//     }
	},
	computed: {
		itemFormate() {
			return (item) => {
				let data = {
					playerOptions: {},
				};
				data.playerOptions = item;
				data.mode = item.mode;
				return data;
			};
		},
		isOnly() {
			if (isExist(this.data)) {
				if (this.data?.list?.length  <= 1) {
					return true;
				}
				return false;
			}
		},
	},
	data() {
		return {
			autoplay: true,
			playerList: [],
			youtubePlayerList: [],
			carouselHeight: "250px",
			imageHeight: 0,

			swipeList: [],
			interval:5000
		};
	},
	// created() {
	//     this.resizeHeight
	// },
	mounted() {
		window.addEventListener("resize", this.resizeHeight);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resizeHeight);
	},
	methods: {
		//計算輪播高度
		// initHeight() {
		//     this.$nextTick(() => {
		//         if (this.$refs.mySwiper.$children[0].$el.firstElementChild.className.includes("hv-video")) {
		//             this.carouselHeight = this.$refs.mySwiper.$children[0].$el.firstElementChild.scrollHeight + "px";
		//         }
		//         this.imageHeight = 0;
		//     });
		// },
		//輪播切換回調
		swipeChange() {
			if (this.playerList.length) {
				for (const item of this.playerList) {
					if (item.el_) item.pause();
				}
			}
			if (this.youtubePlayerList.length) {
				for (const item of this.youtubePlayerList) {
					item.pauseVideo();
				}
			}
		},
		// 播放器準備完成回調
		playerReadied(player) {
			this.playerList.push(player);
			// this.player = player;
			// console.log(this.player);
		},
		// 播放回調
		onPlayerPlay() {
			this.autoplay = false;
			this.data.autoplay = false;
			// console.log(this.$refs.mySwiper);
			// this.swiper.autoplay.stop();
		},
		//Youtube準備完成回調
		onYoutubeReady(player) {
			this.youtubePlayerList.push(player);
			// this.swiper.autoplay.stop()
			this.swipeList.push(player.getSize());
			this.resizeHeight();
		},
		//youtube播放回調
		onYoutubePlaying() {},
		//圖片加載完成回調
		imgLoad(v) {
			// console.log(this.imageHeight);
			// //輪播第一張是圖片 取最大圖片高度
			// if (v.imageHeight > this.imageHeight) this.imageHeight = v.imageHeight;
			// if (this.$refs.mySwiper.$children[0].$el.firstElementChild.className.includes("hv-image")) {
			//     this.carouselHeight = this.imageHeight + "px";
			// }

			//this.imageHeight 有值說明已經循環過一次了，沒必要多次循環
			// if (this.imageHeight) return false;
			// console.log(this.$refs.hvSwiper.offsetWidth);
			if (!/data:/.test(v.src)) {
				const imgsize = {
					width: v.imageWidth,
					height: v.imageHeight,
				};

				this.swipeList.push(imgsize);
				// console.log(this.swipeList);
				if(this.isResizeHeight) this.resizeHeight();
			}
		},
		resizeHeight() {
			const maxSize = this.swipeList.reduce(function(pre, curv) {
				return pre.height < curv.height ? curv : pre;
			});
			
			const box = this.$refs.hvSwiper.clientWidth;
			const scale = box / maxSize.width;
			this.carouselHeight = maxSize.height * scale + "px";
			// console.log(this.carouselHeight);
		},
	},
};
