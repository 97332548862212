/*
 * @Author: andy
 * @Date: 2021-06-16 17:35:37
 * @LastEditTime: 2021-06-17 10:01:41
 * @Description: fb相關
 * @FilePath: \shop_corp\src\elearningservice\service\fb.ts
 */
import { dataTrans } from '@/library2/utils/dataTrans.js'
import { datatype } from '@/library2/utils/datatype.js'
import { log } from '@/library2/utils/log.js'
import { base } from '@/elearningservice/service/base.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import des from '@/library2/utils/des.js'

export class fb extends serBasic {
    /**
     * @description: 分享
     */
    async share(value) {
        let t = await this._getSign(value)
        if (!t) return super._error()

        let redirectUrl
        if (value['redirectUrl'].indexOf('?') != -1) {
            redirectUrl = value['redirectUrl'] + '&t=' + t
        } else {
            redirectUrl = value['redirectUrl'] + '?t=' + t
        }

        let href = 'https://www.facebook.com/dialog/share?app_id=' + value['appId'] + '&display=popup&href=' + value['shareUrl'] + '&redirect_uri=' + redirectUrl
        log.addLog('@fb @share href', href)

        window.location.href = href
    }

    async _getSign(value) {
        const key = dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY))
        log.addLog('key---', key)
        if (!key) return false

        let config = await new base().getConfigInfo()
        log.addLog('config---', config)
        if (datatype.isNull(config.ConfigInfo.storeNbr)) return false

        return des.desEncode(dataTrans.encodeBase64(Date.now() / 1000 + '|' + config.ConfigInfo.storeNbr + '|' + value.extra), key)

    }

    /**
     * @description: 驗證是否分享成功
     */
    async chkShareRes(value) {
        const key = dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY))
        if (!key) return super._error()

        let sign = dataTrans.decodeBase64(des.desDecode(value['t'], key))
        log.addLog('sign---', sign)
        if (!sign) return super._error()

        let ar = sign.split('|')

        if (ar['0']) {
            let now = Date.now() / 1000
            let diff = Number(now) - Number(ar['0'])
            if (diff > 1800) return super._error()
        }

        if (ar['1']) {
            let config = await new base().getConfigInfo()
            if (ar['1'] != config.ConfigInfo.storeNbr) return super._error()
        }

        if (ar['2']) {
            if(ar['2'] == 'undefined')  return super._success()
            return super._success({ extra: ar['2'] })
        }

        return super._success()

    }

}
