//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";

export default {
    computed: {
        ...mapState({
            config(state) {
                return state.base.configInfo;
            },
        })
    }
}
