import { Message,MessageBox } from 'element-ui';
import ExtensibleError from '@/exception/ExtensibleError.js'
import loginError from '@/exception/loginError.js'
import store from '@/store/index.js'
import route from '@/router/index.js'

export class connectError extends ExtensibleError(Error){
  code=null
  msg=null

  constructor(code, msg){
    super()
    this.code = code
    this.msg = msg
  }

  static error(code, msg){
    switch (code){
      case '0':
      case 0:
        this.code0(msg)
        break;
      case '3':
      case 3:
        this.code3(msg)
        break;
      case '404':
      case 404:
        this.code404()
        break;
      case '999':
      case 999:
        this.code999(msg)
        break;
      case 'sign':
        this.errOnce()
        break;
      case 'once':
        this.errOnce()
        break;
      case 'timeout':
        this.timeout()
        break;
      case 'repeated':
        this.repeated()
        break;
      default:
        this.errOnce()
        break;
    }
  }

  static code0(msg,type = null){
    //服務異常,連接超時 彈窗刷新頁面
    if(type == 1){
      MessageBox.alert('服務異常，請重整頁面', {
        confirmButtonText: '重整',
        showClose:false,
        callback: action => {
          location.reload();
        }
      });
      return;
    }
    msg = msg ? msg : 'retCode0'

    if(msg){
      Message({
        message: msg,
        closeOnClick: true,
        closeOnClickOverlay:true,
        overlay:true,
        duration:7000
      })
    }
  }

  static code999(msg){

    if(msg){
       this.code0(msg)
    }else{
      this.code0('retCode999')
    }

    //刪除會員相關數據
    // import('@/shopservice/service/account.js').then(obj=>{
    //   obj.default.delMemInfo()
    // })
  }

  static code404(){
    route.push("/404")
  }
  //登入校驗
  static code3(){
    store.commit('base/setGraphicVerifyShow',true)
  }

  // static errSign(){
  //   this.code0('服務異常')
  // }

  static errOnce(){
    this.code0('服務異常',1)
  }

  static timeout(){
    this.code0('請求超時',1)
  }

  static repeated(){
    // this.code0('重複請求')
  }
}

export default connectError
