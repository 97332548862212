import { request } from "@/elearningservice/service/request.js";

//獲取獲取表單詳情
const getFormInfo = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "form", op: "getFormInfo" }, data);
	return res.data;
};
//上傳表單
const addForm = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "form", op: "addForm" }, data);
	return res.data;
};
//獲取獲取表單設定值詳情
const getFormValueInfo = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "form", op: "getFormValueInfo" }, data);
	return res.data;
};

//獲取圖片上傳地址
const uploadFile = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "form", op: "uploadFile" }, data);
	return res.data;
};

export { getFormInfo, addForm, getFormValueInfo, uploadFile };
