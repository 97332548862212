import basic from "@/store/basic.js";
class comment extends basic {
    constructor() {
        super();
    }

    state = {
        commentInfo:{
            // 1:[]
        }
    };

    mutations = {
        setCommentInfo(state, data) {
            state.commentInfo[data.key] = data;
            console.log(state.commentInfo);
        },
        clearCommentInfo(state){
            state.commentInfo = {};
        }
    };

    actions = {
        setCommentInfo({ commit, state }, value) {
            commit("setCommentInfo", value);
        },
        clearCommentInfo({ commit }){
            commit("clearCommentInfo");
        }
    };
}

export default new comment();
