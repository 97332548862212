/**
 * @description local緩存
 */
import basic from "@/store/basic.js";
class localStore extends basic {
	constructor() {
		super();
	}
	state = {
		hisList: [],
	};

	getters = {
		getHisList(state) {
			return state.hisList;
		},
	};

	mutations = {
		setHisList(state, data) {
			state.hisList.unshift(data);
		},
		clearHisList(state) {
			state.hisList = [];
		},
	};
	actions = {
		setHisList({ commit, state }, value) {
			commit("setHisList", value);
		},
	};
}

export default new localStore();
