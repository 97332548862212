import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { page as repPage } from "@/elearningservice/repository/page.js";
import { head as repHead } from "@/elearningservice/repository/head.js";
import { foot as repFoot } from "@/elearningservice/repository/foot.js";
import { nav as repNav } from "@/elearningservice/repository/nav.js";
import { pt as serPt } from "@/elearningservice/service/pt.js";
import { datatype } from "@/library2/utils/datatype.js";
import { cpg as serCpg } from "@/elearningservice/service/cpg.js";
import { coupon as serCpn } from "@/elearningservice/service/coupon.js";
import { cg as serCg } from "@/elearningservice/service/cg.js";
import { info as serInfo } from "@/elearningservice/service/info.js";
import { log } from "@/library2/utils/log.js";
import { browser } from "@/library2/utils/browser.js";
import { sessnBasic } from "@/library2/basicClass/sessnBasic.js";

export class page extends serBasic {
    /**
     * @description: 獲取頁面信息
     */
    async getPageInfo(value) {
        let p = "";
        if (!datatype.isNull(value["p1"])) p = p + value["p1"];
        if (!datatype.isNull(value["p2"])) p = p + value["p2"];
        if (!datatype.isNull(value["p3"])) p = p + value["p3"];
        if (datatype.isNull(p)) p = "default";

        //獲取瀏覽器類別
        let b = browser.getBrowserTp2();
        const terminal = sessionStorage.getItem("terminal");

        const obj = new repPage();
        obj.setTickKey("pageInfoTick|" + p);
        //判斷當初端口設備是否與上一次一致
        if (terminal == b) {
            obj.where("[b+p]").equals([b, p]);
        } else {
            sessionStorage.setItem("terminal", b);
            obj.clear()
        }

        let params = { p1: value["p1"], p2: value["p2"], p3: value["p3"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "page", op: "getPageInfo" }, params, (data) => {
            data["b"] = b;
            data["p"] = p;
            return data;
        });
        if (datatype.isNull(data.PageInfo)) return super._success();
        log.addLog("data.PageInfo--", data.PageInfo);

        data = await this._getPageData(data);

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    async _getPageData(data) {
        if (!datatype.isNull(data.PageInfo)) {
            if (!datatype.isNull(data.PageInfo.List)) {
                // console.log("data.PageInfo.List");
                // console.log("=================");
                // console.log(data.PageInfo.List);
                
                let richText = [];
                for (let i in data.PageInfo.List) {
                    //新的結構邏輯處理
                    if(data.PageInfo.List[i].name.indexOf("pc_") != -1) data.PageInfo.List[i].name = data.PageInfo.List[i].name.split("pc_")[1]
                    if (data.PageInfo.List[i].name == "Goods") {
                        const ptNbrs = data.PageInfo.List[i].ptNbrs
                        const res = await new serPt().getPtAdvList({ ptNbrs });
                        data.PageInfo.List[i].list = res.PtAdvList;
                    }

                    if (data.PageInfo.List[i].name == "HvTabs") {
                        const tagNbrs = data.PageInfo.List[i].tagNbrs
                        tagNbrs.split(",").forEach(async (tagNbr, tagIndex) => {
                            const ret = await new serPt().getPtByTag({ tagNbr });
                            data.PageInfo.List[i].list[tagIndex].content.list = ret.PtAdvList;
                        });
                    }

                    //舊的結構邏輯處理
                    //PtInfo
                    if (data.PageInfo.List[i].name == "TagInfo") {
                        data.PageInfo.List[i].name = "Goods";
                        data.PageInfo.List[i].type = "scroll";

                        let res = await new serPt().getPtByTag({ tagNbr: data.PageInfo.List[i].tagNbr });
                        if (res.response) {
                            if (res.TagInfo) {
                                data.PageInfo.List[i] = { name: "HvTitle", title: res.TagInfo.tagName, id: res.TagInfo.tagNbr };
                            }

                            let ptList = res.PtAdvList;
                            for (const item of ptList) {
                                item.link = item.redirectUrl;
                            }
                            data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ list: ptList } };
                        }
                    }
                    //PtInfo
                    if (data.PageInfo.List[i].name == "PtInfo") {
                        // if (data.PageInfo.List[i].ptNbr) {
                        //     let ptInfo = await new serPt().getPtInfo({ ptNbr: data.PageInfo.List[i].ptNbr });
                        //     if (!datatype.isNull(ptInfo)) data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...ptInfo };
                        // }
                    }
                    //CpnInfo
                    if (data.PageInfo.List[i].name == "CpnInfo") {
                        data.PageInfo.List[i].name = "Coupon";

                        if (data.PageInfo.List[i].cpnNbr) {
                            let cpnInfo = await new serCpn().getCpnInfo({ cpnNbr: data.PageInfo.List[i].cpnNbr });
                            if (cpnInfo.response) {
                                if (!datatype.isNull(cpnInfo.list)) data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ list: cpnInfo.list } };
                                if (!datatype.isNull(cpnInfo.CpnInfo)) data.PageInfo["CpnInfo"] = cpnInfo.CpnInfo;
                            }
                        }
                    }

                    if (data.PageInfo.List[i].name == "PtListByTag") {
                        data.PageInfo.List[i].name = "Goods";

                        if (data.PageInfo.List[i].tagNbr) {
                            let res = await new serPt().getPtByTag({ tagNbr: data.PageInfo.List[i].tagNbr });
                            if (res.response) {
                                let ptList = res.PtList;
                                if (!datatype.isNull(ptList)) {
                                    ptList.map((v) => {
                                        v["link"] = v.redirectUrl;
                                    });
                                }
                                data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ list: ptList } };
                            }
                        }
                    }

                    if (data.PageInfo.List[i].name == "PtListByCpn") {
                        data.PageInfo.List[i].name = "Goods";

                        if (data.PageInfo.List[i].cpnNbr) {
                            let res = await new serPt().getPtByCpn({ cpnNbr: data.PageInfo.List[i].cpnNbr });
                            if (res.response) {
                                let ptList = res.PtList;
                                if (!datatype.isNull(ptList)) {
                                    ptList.map((v) => {
                                        v["link"] = v.redirectUrl;
                                    });
                                }
                                data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ list: ptList } };
                            }
                        }
                    }

                    //PtPreview
                    if (data.PageInfo.List[i].name == "PtPreview") {
                        // if (data.PageInfo.List[i].ptNbr) {
                        //     let ptInfo = await new serPt().getPrePtInfo({ ptNbr: data.PageInfo.List[i].ptNbr });
                        //     if (!datatype.isNull(ptInfo)) data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...ptInfo };
                        // }
                    }

                    //CgInfo
                    if (data.PageInfo.List[i].name == "CgInfo") {
                        data.PageInfo.List[i].name = "Goods";

                        if (data.PageInfo.List[i].cgNbr) {
                            let res = await new serCg().getPtList({ cgNbr: data.PageInfo.List[i].cgNbr });
                            if (res.response) {
                                let ptList = res.PtList;
                                if (!datatype.isNull(ptList)) {
                                    ptList.map((v) => {
                                        v["link"] = v.redirectUrl;
                                    });
                                }
                                data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ list: ptList } };
                            }
                        }
                    }

                    //Info
                    if (data.PageInfo.List[i].name == "Info") {
                        data.PageInfo.List[i].name = "RichText";
                        if (data.PageInfo.List[i].nbr) {
                            let res = await new serInfo().getInfo({ nbr: data.PageInfo.List[i].nbr });
                            if (res.response) {
                                if (!datatype.isNull(res.title)) {
                                    richText.push({
                                        name: "HvTitle",
                                        title: res.title,
                                    });
                                }
                                if (!datatype.isNull(res.Medias)) richText.push(res.Medias);

                                data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...{ content: res.content } };
                            }
                        }
                    }

                    //CpgInfo
                    if (data.PageInfo.List[i].name == "CpgInfo") {
                        if (data.PageInfo.List[i].cpgNbr) {
                            //獲取活動數據
                            let cpgInfo = await new serCpg().getCpgInfo({ cpgNbr: data.PageInfo.List[i].cpgNbr });

                            if (!datatype.isNull(cpgInfo.CpgInfo)) {
                                data.PageInfo["CpgInfo"] = cpgInfo.CpgInfo;

                                //推廣頁頭部圖片或標題
                                let text= [];
                                if (!datatype.isNull(cpgInfo.CpgInfo.cpgImg)) {
                                    text["name"] = "ImageAD";

                                    text["list"] = [];
                                    text["list"].push({
                                        img: cpgInfo.CpgInfo.cpgImg,
                                    });
                                } else if (!datatype.isNull(cpgInfo.CpgInfo.title)) {
                                    text["name"] = "HvTitle";
                                    text["title"] = cpgInfo.CpgInfo.title;
                                    if (!datatype.isNull(cpgInfo.CpgInfo.desc)) text["desc"] = cpgInfo.CpgInfo.desc;
                                }

                                if (!datatype.isNull(text)) richText.push(text);
                            }

                            //根據活動編號獲取產品列表
                            let ptList = await new serPt().getPtByCpg({ cpgNbr: data.PageInfo.List[i].cpgNbr });
                            if (!datatype.isNull(ptList) && !datatype.isNull(ptList.PtList) && ptList.response) {
                                let goods = {};
                                goods["name"] = "Goods";

                                goods["list"] = [];
                                for (let i of ptList.PtList) {
                                    goods["list"].push({
                                        img: i.img,
                                        title: i.title,
                                        desc: i.desc,
                                        origPrice: i.origPrice,
                                        price: i.price,
                                        link: i.redirectUrl,
                                        cpgType: i.cpgType,
                                        cpgNbr: i.cpgNbr,
                                        percentage: i.surplusStock / i.ttlStock,
                                    });
                                }
                                if (!datatype.isNull(goods)) data.PageInfo.List[i] = { ...data.PageInfo.List[i], ...goods };
                            }
                        }
                    }
                }

                if (!datatype.isNull(richText)) {
                    for (let i of richText) {
                        data.PageInfo.List.unshift(i);
                    }
                }
            }
        }
        // console.log(data.PageInfo.List);

        return data;
    }

    /**
     * @description: 獲取頁面頭部信息
     */
    async getHeadInfo(value) {
        const obj = new repHead();
        obj.setTickKey("headInfoTick|" + value["headId"]);
        obj.where("headId").equals(value["headId"]);

        let params = { headId: value["headId"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "page", op: "getHeadInfo" }, params, (data) => {
            data = { ...data, ...params };
            return data;
        });

        return datatype.isNull(data) ? super._error() : super._success(data.HeadInfo);
    }

    /**
     * @description: 獲取底部頭部信息
     */
    async getFootInfo(value) {
        const obj = new repFoot();
        obj.setTickKey("footInfoTick|" + value["footId"]);
        obj.where("footId").equals(value["footId"]);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "page", op: "getFootInfo" }, { footId: value["footId"] }, (data) => {
            data["footId"] = value["footId"];
            return data;
        });

        return datatype.isNull(data) ? super._error() : super._success(data.FootInfo);
    }

    /**
     * @description: 獲取導航信息
     */
    async getNavInfo(value) {
        const obj = new repNav();
        obj.setTickKey("navInfoTick|" + value["navId"]);
        obj.where("navId").equals(value["navId"]);

        let data = await obj.genData(new request().connectNsuv(), "", { fun: "page", op: "getNavInfo" }, { navId: value["navId"] }, (data) => {
            data["navId"] = value["navId"];
            return data;
        });

        return datatype.isNull(data) ? super._error() : super._success(data.NavInfo);
    }
}
