//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import { Dialog } from "element-ui";
Vue.use(Dialog);
export default {
    data() {
        return {
            videoPop: false,
            videoSrc: "",
        };
    },
    created() {
        window.getVideo = this.getVideo;
    },
    methods: {
        getVideo(e) {
            this.videoPop = true;
            let src = e.getAttribute("data-video");

            // href帶入youtube資源
            if (!src) {
                e.setAttribute("data-video", e.getAttribute("href"));
                e.href = "javascript:void(0)";
                src = e.getAttribute("data-video");
            }

            let videoId = src.split("youtu.be/");
            let reg = RegExp(/youtu.be/);

            if (src.match(reg)) {
                // youtube預設分享方式
                videoId = videoId[1];
                if (videoId.indexOf("?") > 0) {
                    videoId = videoId.substr(0, videoId.indexOf("?"));
                }
                if (videoId.indexOf("&") > 0) {
                    videoId = videoId.substr(0, videoId.indexOf("&"));
                }
                this.videoSrc =
                    "https://www.youtube.com/embed/" + videoId + "?autoplay=1";
            } else {
                // iframe嵌入方式鏈接
                this.videoSrc = src;
            }
        },

        resetVideo() {
            this.videoPop = false;
            this.videoSrc = "";
        },
    },
};
