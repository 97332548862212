
import { basic } from "@/hooks";
import { account } from "@/elearningservice/service/account.js";
import { addFreeOrder } from "@/api/order";
import loginError from "@/exception/loginError.js";
class order extends basic {
    constructor() {
        super();
    }

    async addFreeOrder(skuId,path) {
        //檢查是否登入
        const ret = new account().chkLogin();
        if (!ret.response) {
            sessionStorage.setItem("herf_link", path);
            return loginError.goLogin();
        } 
        return await addFreeOrder({ skuId, userNbr: this.getUserNbr() });
    }
}

export default new order();
