import { base } from "@/elearningservice/service/base.js";
import { cg } from "@/elearningservice/service/cg.js";
class ctrlBase {
    async getConfigInfo() {
        let data = await new base().getConfigInfo();
        if (data.response) return data.ConfigInfo;
    }

    async getTickInfo() {
        let data = await new base().getTickInfo();
        if (data.response) return data;
    }
    async getShareUrl(value) {
        return window.location.href.split("_")[0];
        // let data = await new share().getShareUrl(value);
        // if (data.response) return data.shareUrl;
    }
    async getPtList(value) {
        let data = await new cg().getPtList(value);
        if (data.response) return data.PtList;
    }
    async fbPixel(fbPixelId) {
        new base().fbPixel(fbPixelId);
    }
    async googleAnalytics(id) {
        new base().googleAnalytics(id);
    }
    //ga按鈕事件追蹤
    gaSentEvent(eventCategory, eventAction, eventLabel, eventValue) {
       new base().gaSentEvent({ eventCategory, eventAction, eventLabel, eventValue });
    }
    //返回列表名稱
    getPtNameStr(list, key = "name", key2) {
        let ptNameList = [];
        for (let item of list) {
            if (key2) {
                ptNameList.push(item[key][key2]);
            } else {
                ptNameList.push(item[key]);
            }
        }
        return ptNameList.toString();
    }
}

export default new ctrlBase();
