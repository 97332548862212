var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AddressWin"},[_c('el-dialog',{attrs:{"visible":_vm.AddressWinVisible,"width":"464px","close-on-press-escape":false,"close-on-click-modal":false,"before-close":_vm.handleCloseAdr,"title":_vm.status == 'add' ? '添加地址' : '修改地址'},on:{"update:visible":function($event){_vm.AddressWinVisible=$event}}},[_c('el-form',{ref:"adrRuleForm",attrs:{"model":_vm.adr}},[_c('div',{staticClass:"center"},[_c('ul',[_c('li',[_c('div',{staticClass:"label"},[_vm._v("收件人")]),_c('div',{staticClass:"l1"},[_c('el-form-item',{attrs:{"prop":"name","rules":[
                                    {
                                        trigger: 'blur',
                                        required: true,
                                        message: _vm.msg.req,
                                    } ]}},[_c('el-input',{model:{value:(_vm.adr.name),callback:function ($$v) {_vm.$set(_vm.adr, "name", $$v)},expression:"adr.name"}})],1),_c('el-form-item',{attrs:{"prop":"gender","rules":[
                                    {
                                        trigger: 'change',
                                        required: true,
                                        message: _vm.msg.req,
                                    } ]}},[_c('el-radio-group',{model:{value:(_vm.adr.gender),callback:function ($$v) {_vm.$set(_vm.adr, "gender", $$v)},expression:"adr.gender"}},[_c('el-radio',{attrs:{"label":"M"}},[_vm._v("先生")]),_c('el-radio',{attrs:{"label":"F"}},[_vm._v("女士")])],1)],1)],1)]),_c('li',[_c('div',{staticClass:"label"},[_vm._v(" 手機號碼 ")]),_c('div',[_c('el-form-item',{attrs:{"prop":"tel","rules":[
                                    {
                                        validator: _vm.msg.eleIsMobile,
                                        trigger: 'blur',
                                        required: true,
                                    } ]}},[_c('el-input',{model:{value:(_vm.adr.tel),callback:function ($$v) {_vm.$set(_vm.adr, "tel", $$v)},expression:"adr.tel"}})],1)],1)]),(_vm.Common.isExist(_vm.tmpFrcItem.isStore))?[_c('li',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.tmpFrcItem.name))]),_c('el-button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"text"},on:{"click":_vm.openOther}},[_vm._v(_vm._s(_vm.status == "add" ? "添加地址" : "修改地址"))])],1)]),(_vm.adr.city)?_c('li',[_c('div',{staticClass:"label"},[_vm._v(" 店鋪名稱 ")]),_c('div',[_c('el-form-item',{attrs:{"prop":"city","rules":[
                                        {
                                            trigger: 'blur',
                                            required: true,
                                            message: _vm.msg.req,
                                        } ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.adr.city),callback:function ($$v) {_vm.$set(_vm.adr, "city", $$v)},expression:"adr.city"}})],1)],1)]):_vm._e(),(_vm.adr.address)?_c('li',[_c('div',{staticClass:"label"},[_vm._v(" 詳情地址 ")]),_c('div',[_c('el-form-item',{attrs:{"prop":"address","rules":[
                                        {
                                            trigger: 'blur',
                                            required: true,
                                            message: _vm.msg.req,
                                        } ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.adr.address),callback:function ($$v) {_vm.$set(_vm.adr, "address", $$v)},expression:"adr.address"}})],1)],1)]):_vm._e()]:[(_vm.areaList)?_c('li',[_c('div',{staticClass:"label"},[_vm._v(" 收貨地址 ")]),_c('div',[_c('el-form-item',{attrs:{"prop":"cityCheck","rules":[
                                        {
                                            trigger: 'change',
                                            required: true,
                                            message: _vm.msg.req,
                                        } ]}},[_c('el-cascader',{ref:"cascaderAddr",attrs:{"placeholder":"請選擇","popper-append-to-body":false,"options":_vm.areaList,"props":{ value: 'code', label: 'name', children: 'children' },"popper-class":"adrCascader"},on:{"change":_vm.adrHandleChange},model:{value:(_vm.adr.cityCheck),callback:function ($$v) {_vm.$set(_vm.adr, "cityCheck", $$v)},expression:"adr.cityCheck"}})],1)],1)]):_vm._e(),_c('li',[_c('div',{staticClass:"label"},[_vm._v(" 詳情地址 ")]),_c('div',[_c('el-form-item',{attrs:{"prop":"address","rules":[
                                        {
                                            trigger: 'blur',
                                            required: true,
                                            message: _vm.msg.req,
                                        } ]}},[_c('el-input',{model:{value:(_vm.adr.address),callback:function ($$v) {_vm.$set(_vm.adr, "address", $$v)},expression:"adr.address"}})],1)],1)])],_c('li',[_c('div',[_c('el-checkbox',{model:{value:(_vm.adr.checked),callback:function ($$v) {_vm.$set(_vm.adr, "checked", $$v)},expression:"adr.checked"}},[_vm._v("設為預設地址")])],1)]),_c('li',{staticClass:"btn"},[_c('el-button',{on:{"click":_vm.handleCloseAdr}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onAddrFormSumbit('adrRuleForm')}}},[_vm._v("確認")])],1)],2)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }