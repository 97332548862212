import ExtensibleError from '@/exception/ExtensibleError.js'
import store from '@/store/index.js'
import router from '@/router/index.js'
// import browser from '@/library2/utils/browser.js'
// import { Notify } from 'vant'

class loginError extends ExtensibleError(Error) {
	constructor() {
	  super()
	}
  static goLogin(){
    // Notify("請先登入")
    router.push('/account/login')
  }
    //登入彈窗
  // static pop(){
  //   const browserTp = browser.getBrowserTp()
  //   if(browserTp == 'fiedoraapp'){
  //   console.log('login1')
  //     window.HVJS.login(window.location.href)

  //   }else{
  //     store.commit('login/showLoginPop')
  //   }
  // }
}

export default loginError
