//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Image, Input, Button, Empty, Drawer, Avatar, Dialog, Pagination } from "element-ui";
export default {
	name: "comment",
	components: {
		[Image.name]: Image,
		[Input.name]: Input,
		[Button.name]: Button,
		[Drawer.name]: Drawer,
		[Avatar.name]: Avatar,
		[Dialog.name]: Dialog,
		[Pagination.name]: Pagination,
	},
	props: {
		commentList: Array,
		replyList: Array,
		commentNum: Number,
		replyNum: Number,
		commentSet: Object,
		replySet: Object,
	},
	data() {
		return {
			commentText: null,
			reply: null,
			detailDrawer: false,
			commentDrawer: false,
			currItem: {},
			pageSize: 10,
		};
	},
	computed: {
		noMore() {
			//當起始頁數大於總頁數時停止加載
			return this.commentSet.page >= this.commentSet.pageTotal;
		},
		disabled() {
			return this.commentSet.loading || this.noMore;
		},
		replyNoMore() {
			//當起始頁數大於總頁數時停止加載
			return this.replySet.page >= this.replySet.pageTotal;
		},
		replyDisabled() {
			return this.replySet.loading || this.replyNoMore;
		},
	},
	mounted() {
		// this.$nextTick(() => {
		// 	this.sizeHandle();
		// });
	},
	created() {},
	methods: {
		load() {
			console.log("load--------------");
			this.$emit("load");
		},
		replyLoad() {
			console.log("replyLoad------------");
			this.$emit("replyLoad");
		},
		replyClick(item) {
			console.log(item);
			this.reply = null;
			item.isReplyTextShow = true;

			this.$nextTick(() => {
				this.$refs["reply_" + item.cmtNbr][0].focus();
				this.commentList
					.filter((com) => com.cmtNbr != item.cmtNbr)
					.forEach((comment) => {
						comment.isReplyTextShow = false;
					});
			});
			this.$emit("replyClick", item, item.cmtNbr);
		},
		//回復評論
		replySend(item) {
			if (!this.reply) return false;
			item.isReplyTextShow = false;
			this.$emit("replySend", item, item.cmtNbr, this.reply);
		},
		//評論
		commentSend() {
			if (!this.commentText) return false;
			this.$emit("commentSend", this.commentText);
		},
		moreClick(item) {
			this.currItem = item;
			this.$emit("moreClick", item);
			this.detailDrawer = true;
		},
		handleClose() {
			this.detailDrawer = false;
		},
		onPageChange(page) {
			this.$emit("onPageChange", page);
			
		},
		// sizeHandle() {
		// 	const className = document.getElementsByTagName("html")[0].className;
		// 	if (className.includes("moBrowser")) {
		// 		this.size = "60%";
		// 		this.direction = "btt";
		// 	}
		// },
	},
};
