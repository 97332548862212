//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog, Input, Form, FormItem, Message, Select, Option, OptionGroup, Button, Radio, RadioGroup } from "element-ui";
import { ctrlInv,ctrlBase } from "@/controller";
export default {
    name: "InvoiceWin",
    components: {
        [Dialog.name]: Dialog,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Select.name]: Select,
        [OptionGroup.name]: OptionGroup,
        [Option.name]: Option,
        [Input.name]: Input,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
    },
    props: {
        inv: Object,
        soNbr: String,
    },
    data() {
        return {
            InvoiceWinVisible: false,
            innerVisible: false,
            invList: [],
            invoice: {},
            value: "",
            invType: 2, //二聯式 三聯式
        };
    },
    watch: {
        inv(newVal) {
            this.getInvList();
        },
    },
    mounted() {},
    created() {},
    methods: {
        async getInvList() {
            this.invList = await ctrlInv.getInvList();
            this.invoice = JSON.parse(JSON.stringify(this.inv));
            this.invType = Number(this.invoice.invType) ? Number(this.invoice.invType) : 2;
            // this.invList = data;
            // // if (data) {
            // //     data.forEach((ele, i) => {
            // //         ele.value = i + 1;
            // //     });
            // //     this.invList = data;
            // // } else {
            // //     this.invList = [];
            // // }
            if (this.invoice.invTitle && this.invoice.taxNo) {
                return;
            }
            this.invoice.invTitle = "";
            this.invoice.taxNo = "";
            if (this.invList.length) {
                this.invoice.invTitle = this.invList[0].invTitle;
                this.invoice.taxNo = this.invList[0].taxNo;
            }
        },
        invChange(e) {
            for (const inv of this.invList) {
                if (inv.invTitle == e) {
                    this.invoice.invTitle = inv.invTitle;
                    this.invoice.taxNo = inv.taxNo;
                    break;
                }
            }
        },
        async save() {
            if (this.invType == 3) {
                if (!this.invoice.invTitle) {
                    return Message({
                        message: "發票抬頭不能為空",
                        type: "error",
                    });
                }
                if (!this.invoice.taxNo) {
                    return Message({
                        message: "統一編號不能為空",
                        type: "error",
                    });
                }
            }
            ctrlBase.gaSentEvent('編輯發票', '發票編輯儲存', this.invInfo.invType)
            let obj = {
                soNbr: this.soNbr,
                invTitle: this.invType == 2 ? "" : this.invoice.invTitle,
                invType: this.invType,
                taxNo: this.invType == 2 ? "" : this.invoice.taxNo,
            };
            const data = await ctrlInv.updInvInfo(obj);
            if (data) {
                Message.success("編輯成功");
                this.getInvList();
                this.InvoiceWinVisible = false;
                this.$emit("updInvInfo", true);
            }
        },
    },
};
