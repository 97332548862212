"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cookieBasic = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-08 10:23:05
 * @LastEditTime: 2021-06-18 11:06:16
 * @LastEditors: Please set LastEditors
 * @Description: cookie存儲相關類
 * @FilePath: \shop_corp\src\library2\basicClass\cookieBasic.js
 */
var cookie_js_1 = require("@/library2/storage/cookie.js");
var cookieBasic = /** @class */ (function () {
    function cookieBasic() {
        this.key = '';
    }
    /**
     * @description: 設置存儲的key
     * @param {string} k
     * @return {object}
     */
    cookieBasic.prototype.setKey = function (k) {
        this.key = k;
        return this;
    };
    /**
     * @description: 設置時效時間
     * @param {any} s
     * @return {object}
     */
    cookieBasic.prototype.setExpires = function (s) {
        this.day = s;
        return this;
    };
    /**
     * @description: 獲取數據
     * @return {object}
     */
    cookieBasic.prototype.get = function () {
        var obj = new cookie_js_1.ck();
        var data = obj.get(this.key);
        return data;
    };
    /**
     * @description: 不存在則新增，存在則修改
     * @param {string} value
     */
    cookieBasic.prototype.put = function (value) {
        var obj = new cookie_js_1.ck();
        obj.put(this.key, value, this.day);
    };
    /**
     * @description: 刪除
     */
    cookieBasic.prototype.del = function () {
        var obj = new cookie_js_1.ck();
        obj.del(this.key);
    };
    return cookieBasic;
}());
exports.cookieBasic = cookieBasic;
