import { fb } from "@/elearningservice/service/fb.js";
import { browser } from "@/library2/utils/browser.js";
class ctrlFb {
    async share(value) {
        let data = await new fb().share(value);
    }
    async chkShareRes(value) {
        let data = await new fb().chkShareRes(value);
        if (data.response) return data.extra;
    }
    //客服
	onlineService(config, ...args) {
        console.log(config);
		const obj = args[0];
		let keyName = null;
		let nbr = null;
		for (let key in obj) {
			keyName = key;
			nbr = obj[key];
		}
		let fbService = `${config.fbService}`;
		let lineService = `${config.lineService}`;
		if (keyName) {
			fbService = `${config.fbService}?ref=${keyName}_${nbr}`;
			lineService = `${config.lineService}?ref=${keyName}_${nbr}`;
		}
		if(config.defaultService == 'line'){
			return window.open(lineService, "_blank");
		}
		// if (browser.getBrowserTp() == "line") {
		// 	return window.open(config.lineService, "_blank");
		// }
		window.open(fbService, "_blank");
	}
}

export default new ctrlFb();
