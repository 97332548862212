"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessn = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-06-10 17:08:35
 * @Description: session基礎類
 * @FilePath: \shop_corp\src\library2ts\storage\sessionstorage.ts
 */
var sessn = /** @class */ (function () {
    function sessn() {
        this._init();
    }
    sessn.prototype._init = function () {
        //檢查是否支持sessionStorage
        if (!window.sessionStorage)
            this._error('@_init Nonsupport sessionStorage');
    };
    /**
     * @description: 修改某一筆數據，若不存在，則新增
     * @param {string} key
     * @param {any} data
     */
    sessn.prototype.put = function (key, data) {
        this._chkKey(key);
        Promise.resolve().then(function () {
            sessionStorage.setItem(key, JSON.stringify(data));
        });
    };
    /**
     * @description: 獲取某一筆數據
     * @param {string} key
     * @return {any}
     */
    sessn.prototype.get = function (key) {
        var data = sessionStorage.getItem(key);
        return JSON.parse(data);
    };
    /**
     * @description: 刪除數據
     * @param {string} key
     */
    sessn.prototype.del = function (key) {
        this._chkKey(key);
        Promise.resolve().then(function () {
            sessionStorage.removeItem(key);
        });
    };
    /**
     * @description: 清空所有數據
     */
    sessn.prototype.clear = function () {
        Promise.resolve().then(function () {
            sessionStorage.clear();
        });
    };
    sessn.prototype._chkKey = function (key) {
        if (key === null)
            this._error('@_chkKey Err Key');
        return true;
    };
    /**
     * @description: 異常拋出
     * @param {string} msg
     */
    sessn.prototype._error = function (msg) {
        throw new Error('@sessionstorage ' + msg);
    };
    return sessn;
}());
exports.sessn = sessn;
