//
//
//
//
//
//

import navBar from "./navBar.vue";
import { Menu, Submenu, MenuItem, MenuItemGroup,Image } from "element-ui";
export default {
    name: "NavMenu",
    components: {
        [Menu.name]: Menu,
        [Submenu.name]: Submenu,
        [MenuItem.name]: MenuItem,
        [MenuItemGroup.name]: MenuItemGroup,
        [Image.name]:Image,
        navBar,
    },
    props: {
        data: Object,
        vertical: Boolean
    },
    computed: {
        list() {
            return this.data.list;
        },
    },
    created(){
        // console.log(this.data);
    }
};
