import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { ptInfo as repPt } from "@/elearningservice/repository/ptInfo.js";
import { account } from "@/elearningservice/service/account.js";
import { datatype } from "@/library2/utils/datatype.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { cpnPtList as repCpnPtList } from "@/elearningservice/repository/cpnPtList.js";
import { cpgPtList as repCpgPtList } from "@/elearningservice/repository/cpgPtList.js";
import { tagPtList as repTagPtList } from "@/elearningservice/repository/tagPtList.js";
import { additionList as repAdditionList } from "@/elearningservice/repository/additionList.js";
import { ptAdvList as repPtAdvList } from "@/elearningservice/repository/ptAdvList.js";
import { log } from "@/library2/utils/log.js";

export class pt extends serBasic {
    /**
     * @description:獲取可用庫存
     */
    async getSkuAvail(value) {
        let data = await new request().connectNsuv().post("", { fun: "pt", op: "getSkuAvail" }, { ptNbr: value["ptNbr"] });

        return datatype.isNull(data) ? super._error() : super._success({ SkuAvails: data.SkuAvails });
    }
    /**
     * @description:根據skuId獲取可用庫存
     */
    async getAvailBySkuId(value) {
        let data = await new request().connectNsuv().post("", { fun: "pt", op: "getAvailBySkuId" }, { skuId: value["skuId"] });

        return datatype.isNull(data) ? super._error() : super._success({ SkuAvails: data.SkuAvails });
    }
    /**
     * @description:獲取產品信息
     */
    async getPtInfo(value) {
        const obj = new repPt();
        obj.setTickKey("ptInfoTick|" + value["ptNbr"]);
        obj.where("ptNbr").equals(value["ptNbr"]);

        let params = { ptNbr: value["ptNbr"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getPtInfo" }, params, (data) => {
            data["ptNbr"] = value["ptNbr"];
            return data;
        });

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description:獲取商品預覽數據
     */
    async getPrePtInfo(value) {
        let data = await new request().connectNsuv().post("", { fun: "preview", op: "getPtInfo" }, { ptNbr: value["ptNbr"] });

        return datatype.isNull(data) ? super._error() : super._success(data);
    }

    /**
     * @description:刪除ptInfo tick
     */
    async dealPtInfoTick(value) {
        const obj = new repPt();
        obj.setTickKey("ptInfoTick|" + value["ptNbr"]);

        await obj.delTick();
        return super._success();
    }

    /**
     * @description:到貨通知
     */
    async addNotice(value) {
        const req = new request().connectNsuv({ encryptFields: ["noticeName", "noticeTel", "noticeEmail"] });

        let data = await req.post("", { fun: "pt", op: "addNotice" }, value);

        return datatype.isNull(data) ? super._error() : super._success();
    }

    /**
     * @description:根據商品編號獲取加價購列表
     */
    async getAdditionList(value) {
        const obj = new repAdditionList();
        obj.setTickKey("additionListTick|" + value["ptNbr"]);
        obj.where("ptNbr").equals(value["ptNbr"]);

        let params = { ptNbr: value["ptNbr"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getAdditionList" }, params, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);

                data.map((item) => {
                    item["ptNbr"] = value["ptNbr"];
                });

                return data;
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ AdditionList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description:根據優惠券編號獲取商品列表
     */
    async getPtByCpn(value) {
        const obj = new repCpnPtList();
        obj.setTickKey("cpnPtListTick|" + value["cpnNbr"]);

        obj.where("cpnNbr").equals(value["cpnNbr"]);

        let params = { cpnNbr: value["cpnNbr"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getPtByCpn" }, params, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);
                data.map((item) => {
                    item["cpnNbr"] = value["cpnNbr"];
                });

                return data;
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ PtList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description:根據標簽編號獲取商品列表
     */
    async getPtByTag(value) {
        const obj = new repTagPtList();
        obj.setTickKey("tagPtListTick|" + value["tagNbr"]);

        obj.where("tagNbr").equals(value["tagNbr"]);

        let params = { tagNbr: value["tagNbr"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getPtByTag" }, params, (data) => {
            // let ret = {};
            // if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
            //     ret["PtList"] = dataTrans.minDictRows(data.Dict, data.Rows);
            // }

            // if (data.TagInfo) ret["TagInfo"] = data.TagInfo;
            data["tagNbr"] = value["tagNbr"];

            return data;
        });

        return datatype.isNull(data) ? super._error() : super._success({ PtAdvList: data.PtAdvList, TagInfo: data.TagInfo });
    }

    /**
     * @description:根據活動獲取產品列表
     */
    async getPtByCpg(value) {
        const obj = new repCpgPtList();
        obj.setTickKey("cpgPtListTick|" + value["cpgNbr"]);

        obj.where("cpgNbr").equals(value["cpgNbr"]);

        let params = { cpgNbr: value["cpgNbr"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getPtByCpg" }, params, (data) => {
            if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
                data = dataTrans.minDictRows(data.Dict, data.Rows);
                data.map((item) => {
                    item["cpgNbr"] = value["cpgNbr"];
                });

                return data;
            }
        });

        return datatype.isNull(data) ? super._error(data) : super._success({ PtList: data ? super._toBeArray(data) : [] });
    }

    /**
     * @description:根據多個商品編號獲取商品列表
     */
    async getPtAdvList(value) {
        const obj = new repPtAdvList();
        obj.setTickKey("ptAdvListTick|" + value["ptNbrs"]);
        obj.where("ptNbrs").equals(value["ptNbrs"]);

        let params = { ptNbrs: value["ptNbrs"] };
        let data = await obj.genData(new request().connectNsuv(), "", { fun: "pt", op: "getPtAdvList" }, params, (data) => {
            data.ptNbrs = value["ptNbrs"];
            return data;
        });
        return datatype.isNull(data) ? super._error(data) : super._success({ PtAdvList: data.PtAdvList });
    }

    /**
     * @description:檢查商品是否可買
     */
    async checkPtBuy(value) {
        let nbr = new account().getAuth();
        if (!nbr) return super._error();

        value = { ...value, ...{ userNbr: nbr } };

        let data = await new request().connectNsuv().post("", { fun: "pt", op: "checkPtBuy" }, value);

        return datatype.isNull(data) ? super._error() : super._success({ stat: data.stat, soNbr: data.soNbr });
    }
}
