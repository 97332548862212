//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog } from "element-ui";
import { ctrlAccount } from "@/controller";
import { mapState } from "vuex";
export default {
    components: {
        [Dialog.name]: Dialog,
    },
    data() {
        return {
            // verifyShow: false,
            tips: "拖動左邊滑塊完成上方拼圖",
            visible: true,
            puzzle: false, //拼圖是否正確

            bgImg: "", //背景圖片
            coverImg: "", //
            coverHeight: "", //
            verifyCode: "", //校驗碼

            dom: "",
            slider: "",
        };
    },
    mounted() {
        // this.init();
    },
    computed: {
        ...mapState({
            verifyParmInfo(state) {
                return state.base.verifyParmInfo;
            },
        }),
        graphicVerifyShow: {
            get: function() {
                return this.$store.state.base.graphicVerifyShow;
            },
            set: function(v) {
                this.$store.dispatch("base/setGraphicVerifyShow", v);
            },
        },
    },
    methods: {
        async init() {
            //服務器獲取校驗圖片
            await this.getSliderCaptcha();
            //拼圖驗證碼初始化
            this.draw();
            this.puzzle = false;
            this.clear();
        },
        closed() {
            this.$store.dispatch("base/setVerifyRes", false);
        },
        //還原初始位置
        async clear() {
            this.dom && (this.dom.style.left = "");
            this.slider && (this.slider.style.left = "");
            this.tips = "拖動左邊滑塊完成上方拼圖";
        },
        //服務器獲取校驗圖片
        async getSliderCaptcha() {
            const data = await ctrlAccount.getSliderCaptcha();
            console.log(data);
            this.bgImg = "data:image/jpeg;base64," + data.bg;
            this.coverImg = "data:image/jpeg;base64," + data.cover;
            this.coverHeight = data.height;
            this.verifyCode = data.code;
        },
        //拼圖驗證碼初始化
        draw() {
            let mainDom = document.querySelector("#codeImg");
            let bg = mainDom.getContext("2d");

            let imgsrc = this.bgImg;
            let img = document.createElement("img");
            img.style.objectFit = "none"; //按照原始尺寸
            img.src = imgsrc;
            img.onload = function() {
                bg.drawImage(img, 0, 0);
            };

            //遮罩圖片
            let blockDom = document.querySelector("#sliderBlock");
            let block = blockDom.getContext("2d");
            blockDom.height = blockDom.height;
            let imgsrc2 = this.coverImg;
            let img2 = document.createElement("img");
            img2.style.objectFit = "none"; //按照原始尺寸
            img2.src = imgsrc2;

            let coverHeight = this.coverHeight;
            img2.onload = function() {
                block.drawImage(img2, 0, coverHeight);
            };
        },
        //touchstart
        drag(e) {
            // console.log("touchstart---");
            this.dom = e.target;
            this.slider = document.querySelector("#sliderBlock"); //滑塊dom
            //x軸數據
            let x = 0;
            const move = (moveEV) => {
                // x = moveEV.targetTouches[0].pageX;
                x = moveEV.x-e.screenX;
                // if (x >= 251 || x <= 0) return false;

                this.dom.style.left = x + "px";
                this.slider.style.left = x + "px";
            };

            const up = async () => {
                // console.log("松開鼠標----");
                // document.removeEventListener("touchmove", move, true);
                // document.removeEventListener("touchend", up, true);
                document.removeEventListener("mousemove", move);
                document.removeEventListener("mouseup", up);
                let data = await this.chkSliderCaptchaCode(x);
                if (data.retCode == "1") {
                    this.puzzle = true;
                    this.tips = "驗證成功";
                    this.$emit("verifyRes", true);
                    setTimeout(async () => {
                        console.log( this.verifyParmInfo);
                        this.visible = false;
                        this.$store.dispatch("base/setGraphicVerifyShow", false);
                        await this.$store.dispatch("base/setVerifySource", this.verifyParmInfo);
                    }, 500);
                } else {
                    this.tips = "驗證失敗，請重試";
                    this.init();
                }
            };
            // document.addEventListener("touchmove", move, true);
            // document.addEventListener("touchend", up, true);
            document.addEventListener("mousemove", move);
            document.addEventListener("mouseup", up);
        },
        async chkSliderCaptchaCode(captchaCode) {
            return await ctrlAccount.chkSliderCaptchaCode({ captchaCode, verifyCode: this.verifyCode });
        },
    },
};
