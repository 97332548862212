import router from "@/router/index.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { browser } from "@/library2/utils/browser.js";
import log from "@/library2/utils/log.js";

class redirect {
    constructor() {}

    static push(val, route = null) {
        route == "buy" && (val.redirectUrl = val.redirectUrl.replace(/\/item\//, "/buy/"))
        
        if (val.RedirectInfo) {
            let redirectInfo = val.RedirectInfo;
            //跳內部網址
            let url = "";
            if (redirectInfo.fun) url = url + "/" + redirectInfo.fun;
            if (redirectInfo.op) url = url + "/" + redirectInfo.op;

            //拼接nbr
            if (redirectInfo.nbr) {
                url = url + "/" + redirectInfo.nbr;
            }

            const map = ["fun", "op", "nbr"];
            for (let i of map) {
                redirectInfo = dataTrans.unset(redirectInfo, i);
            }

            if (browser.getBrowserTp() == "fiedoraapp" && route && route.name == "home") {
                let params = "";
                for (let i in redirectInfo) {
                    params += i + "=" + redirectInfo[i] + "&";
                }
                params = params.substr(0, params.length - 1);

                let url2 = document.location.hostname + url;
                if (params) url2 += "?" + params;

                console.log(url2);
                if (url2.indexOf("http") != -1) {
                    window.HVJS.open(url2);
                } else {
                    window.HVJS.open("https://" + url2);
                }
            } else {
                router.push({ path: url, query: redirectInfo });
            }
        } else if (val.redirectUrl) {
            if (browser.getBrowserTp() == "fiedoraapp" && route && route.name == "home") {
                console.log(val.redirectUrl);
                if (val.redirectUrl.indexOf("http") != -1) {
                    window.HVJS.open(val.redirectUrl);
                } else {
                    window.HVJS.open("https://" + val.redirectUrl);
                }
            } else {
                window.location.href = val.redirectUrl;
            }
        }
    }
}

export default redirect;
