/*
 * @Author: your name
 * @Date: 2021-04-15 14:28:59
 * @LastEditTime: 2021-07-16 09:30:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\repository\chpdInfo.js
 */
import {idxBasic} from '@/library2/basicClass/idxBasic.js'
import DB from '@/elearningservice/repository/DB.js'
import {tick} from '@/elearningservice/repository/tick.js'

export class chpdInfo extends idxBasic {
  _tkey = 'MemTickInfo.memberTick'

  constructor() {
    super(DB)
    this._mySetParam()
  }

  _mySetParam() {
    super.setTable('chpdInfo')
  }

  async _getTickInfo() {
    let obj = new tick()
    let tickInfo = await obj.get(false)
    if (!tickInfo) return false

    return eval('tickInfo.' + this._tkey)
  }
}


