/*
 * @Author: your name
 * @Date: 2021-06-16 10:17:25
 * @LastEditTime: 2021-07-02 14:14:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_corp\src\elearningservice\service\captcha.js
 */
import { datatype } from '@/library2/utils/datatype.js'
import { request } from '@/elearningservice/service/request.js'
import { serBasic } from '@/elearningservice/service/serBasic.js'
import { captcha as repCaptcha } from '@/elearningservice/repository/captcha.js'

export class captcha extends serBasic {
  /**
   * @description: 獲取拼圖驗證碼
   */
  async getSliderCaptcha(value) {
    let data = await new request().connectNsuv().post('', { fun: 'captcha', op: 'getSliderCaptcha' }, { dtu: value['dtu'] })

    return (datatype.isNull(data)) ? super._error() : super._success(data)
  }

  /**
   * @description: 驗證拼圖驗證碼
   */
  async chkSliderCaptchaCode(value){
    let captchaCode = parseInt(value['captchaCode'])

    const req = new request().connectNsuv({ encryptFields: ['captchaCode'] })

    let data = await req.post('', { fun: 'captcha', op: 'chkSliderCaptchaCode' }, { captchaCode: captchaCode, verifyCode: value['verifyCode'] })
    if (datatype.isNull(data)) return super._error()

    const obj = new repCaptcha()
    await obj.put({ captchaCode: captchaCode, verifyCode: value['verifyCode'] })

    return super._success(data)

  }
}
