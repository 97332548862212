import HvTabs from "../common/Tabs";
import Goods from "../common/Goods";
import RichText from "../common/RichText";
import HvVideo from "../common/Video";
import ImageAD from "../common/ImageAD";
import HvTitle from "../common/Title";
import HvSwiper from "../common/Swiper";
import HvSwiper2 from "../common/Swiper2";
import Coupon from "../common/Coupon";
import Cube from "../common/Cube";
import Grid from "../common/Grid";
import Notice from "../common/Notice";
import NavHeader from "../common/NavHeader";
import NavMenu from "../common/NavMenu";
import Search from "../common/Search";
import Form from "../common/Form";
const components = [HvSwiper2,Form,Goods, RichText, HvVideo, ImageAD, HvTitle, HvSwiper, Coupon, HvTabs, Notice, Cube, Grid, NavHeader, NavMenu,Search]; //通用組件
let PC_Plugin = {};

PC_Plugin.install = function(Vue) {
    Vue.component("pc_" + Goods.name, Goods);
	for (const component of components) {
		Vue.component(component.name, component);
	}
};

export default PC_Plugin;
