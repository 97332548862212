"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.browser = void 0;
/*
 * @Author: andy
 * @Date: 2021-06-09 17:27:39
 * @LastEditTime: 2021-06-10 12:05:05
 * @Description: 瀏覽器相關類
 * @FilePath: \shop_corp\src\library2ts\utils\browser.js
 */
var browser = /** @class */ (function () {
    function browser() {
    }
    /**
     * @description: 獲取瀏覽器類型
     * @return {string}
     */
    browser.getBrowserTp = function () {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.search(/MicroMessenger/i)) {
            return "weixin";
        }
        else if (ua.search(/FiedoraApp/i)) {
            return "fiedoraapp";
        }
        else if (this.isFBMsg()) {
            return "messenger";
        }
        else if (this.isFbApp()) {
            return "fb";
        }
        else if (this.isLine()) {
            return "line";
        }
        else if (ua.search(/Mobile/i)) {
            return "mobile";
        }
        else {
            return "window";
        }
    };
    browser.getBrowserTp2 = function () {
        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
            return "mobile";
        }
        else {
            return "window";
        }
    };
    /**
     * @description: 判斷是否為fb messenger
     * @return {boolean}
     */
    browser.isFBMsg = function () {
        var u = navigator.userAgent.toLowerCase();
        if (this.isIos()) {
            return u.indexOf("messenger") > -1 && u.indexOf("fbav") > -1;
        }
        else {
            return u.indexOf("orca") > -1 && u.indexOf("fbav") > -1;
        }
    };
    /**
     * @description: 判斷是否為line
     * @return {boolean}
     */
    browser.isLine = function () {
        var u = navigator.userAgent.toLowerCase();
        return u.indexOf("line") > -1;
    };
    /**
     * @description: 判斷是否為微信
     * @return {boolean}
     */
    browser.isWeiXin = function () {
        var u = navigator.userAgent.toLowerCase();
        return u.indexOf("micromessenger") > -1;
    };
    /**
     * @description: 判斷是否為fb app
     * @return {boolean}
     */
    browser.isFbApp = function () {
        var u = navigator.userAgent.toLowerCase();
        if (this.isIos()) {
            return u.indexOf("messenger") <= -1 && (u.indexOf("fbav") > -1 || u.indexOf("fbdv") > -1);
        }
        else {
            return u.indexOf("orca") <= -1 && (u.indexOf("fbav") > -1 || u.indexOf("fbdv") > -1);
        }
    };
    /**
     * @description: 判斷是否為IOS設備
     * @return {boolean}
     */
    browser.isIos = function () {
        var u = navigator.userAgent;
        var ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        var iPad = u.indexOf("iPad") > -1;
        var iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1;
        if (ios || iPad || iPhone) {
            return true;
        }
        else {
            return false;
        }
    };
    /**
     * @description: 是否為安卓設備
     * @return {boolean}
     */
    browser.isAndroid = function () {
        var u = navigator.userAgent;
        return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    };
    return browser;
}());
exports.browser = browser;
