//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Dialog } from "element-ui";
export default {
	name: "LoginDialog",
	components: {
		[Dialog.name]: Dialog,
		[Button.name]: Button,
	},
	props: {
		text: {
			type: String,
			default: "是否登入？",
		},
		title: {
			type: String,
			default: " ",
		},
		link: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			show: false,
		};
	},
	methods: {
		confirm() {
			this.$emit("loginDialogConfirm", (res) => {
				if (res) {
					const link = this.link ? this.link : this.$router.currentRoute.fullPath;
					sessionStorage.setItem("herf_link", link);
					this.$router.push(`/account/login`);
				}
			});
		},
	},
};
