import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { order as serOrder } from "@/elearningservice/service/order.js";
import { payList } from "@/elearningservice/repository/payList.js";
import { datatype } from "@/library2/utils/datatype.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { account as serAcct } from "@/elearningservice/service/account.js";
import { browser } from "@/library2/utils/browser.js";
import { coupon as serCpn } from "@/elearningservice/service/coupon.js";

export class payModules extends serBasic {
	/**
	 * @description: 獲取付款方式列表
	 */
	async getPayList() {
		const obj = new payList();

		let data = await obj.genData(new request().connectNsuv(), "", { fun: "pay", op: "getPayList" }, {}, (data) => {
			// data.Dict = ["payNbr", "icon", "name", "desc", "isOnline"];
			// data.Rows = [["1yo7hv", "", "銀行匯款", "123s", 0]];
			if (!datatype.isNull(data.Dict) && !datatype.isNull(data.Rows)) {
				return dataTrans.minDictRows(data.Dict, data.Rows);
			}
		});
		for (const item of super._toBeArray(data)) {
			item.desc = item.desc ? item.desc.replace(/\n/g, "<br>") : item.desc;
		}

		return datatype.isNull(data) ? super._error(data) : super._success({ PayList: data ? super._toBeArray(data) : [] });
	}

	/**
	 * @description: 付款
	 */
	async pay(value) {
		const userNbr = await new serAcct().getAuth();
		if (!userNbr) return super._error();

		let params = {
			soNbr: value["soNbr"],
			payNbr: value["payNbr"],
			userNbr: userNbr,
		};

		let data = await new request().connectNsuv().post("", { fun: "pay", op: "pay" }, params);

		if (!datatype.isNull(data)) {
			//刪除訂單相關數據
			new serOrder().delStorageOrders();

			//刪除優惠券
			new serCpn().delCoupons();

			if (browser.getBrowserTp() == "fiedoraapp") {
				window.HVJS.notifyNsHomeRefresh();
			}

			return super._success(data);
		}

		return super._error();
	}

	//部分付款/部分退款
	/**
	 * @description: 付款
	 */
	async partPay(value) {
		let nbr = new serAcct().getAuth();
		if (!nbr) return super._error();

		value = { ...value, ...{ userNbr: nbr } };

		const options = { encryptFields: ["receiverName", "receiverTel"] };
		const req = new request().connectNsuv(options);
		let data;

		if (value["Upload"]) {
			let file = { Upload: value["Upload"] };
			value = dataTrans.unset(value, "Upload");

			data = await req.upload("", { fun: "order", op: "partPay" }, file, value);
		} else {
			data = await req.post("", { fun: "order", op: "partPay" }, value);
		}
		if (!datatype.isNull(data)) {
			//本地刪除數據
			new serOrder().delStorageOrders();
			//刪除優惠券
			new serCpn().delCoupons();

			if (browser.getBrowserTp() == "fiedoraapp") {
				window.HVJS.notifyNsHomeRefresh();
			}

			return super._success(data);
		}
		return super._error();
	}

	//匯款賬號輸入
	/**
	 * @description: 付款
	 */
	async updPayInfo(value) {
		let nbr = new serAcct().getAuth();
		if (!nbr) return super._error();

		value = { ...value, ...{ userNbr: nbr } };

		const req = new request().connectNsuv();

		// if (! datatype.isNull(value['Upload'])) {
		//   let file = { Upload: value['Upload'] }
		//   req.setFile(file)
		//   value = dataTrans.unset(value, 'Upload')

		// }

		if (!datatype.isNull(value["Upload"])) {
			req.setFileList({ name: "Upload", files: [value.Upload] });
			value = dataTrans.unset(value, "Upload");
		}

		let data = await req.post("", { fun: "order", op: "updPayInfo" }, value);

		return datatype.isNull(data) ? super._error() : super._success();
	}
}
