//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, Field, Checkbox, CheckboxGroup, RadioGroup, Radio, Picker, Popup, Calendar, Button, Image as VanImage, Rate, Uploader, DatetimePicker, Toast } from "vant";
import { isExist } from "../../../utils";
export default {
	name: "HvForm",
	components: {
		[Form.name]: Form,
		[Field.name]: Field,
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Picker.name]: Picker,
		[Popup.name]: Popup,
		[Calendar.name]: Calendar,
		[Button.name]: Button,
		[VanImage.name]: VanImage,
		[Rate.name]: Rate,
		[Uploader.name]: Uploader,
		[DatetimePicker.name]: DatetimePicker,
	},
	props: {
		data: Object,
		valueData: Array,
		userInfo: [Object, Boolean],
		isClose: Boolean,
	},
	computed: {},
	data() {
		return {
			radio: {},
			radioImages: {},
			checkbox: {},
			checkboxImages: {},
			input: {},
			user: {},
			text: {},
			email: {},
			number: {},
			digit: {},
			tel: {},
			textarea: {},
			select: {},
			password: {},
			selectKey: null,
			date: {},
			dateKey: null,
			calendar: {},
			calendarKey: null,
			calendarPicker: false,

			selectPicker: false,
			selectPickerVal: [],

			datePicker: false,
			minDate: new Date(1910, 0, 1),
			maxDate: new Date(),
			currDate: new Date(),
			calendarMinDate: new Date(new Date().setDate(new Date().getDate() - 365 * 10)),
			calendarMaxDate: new Date(new Date().setDate(new Date().getDate() + 365 * 10)),
			rate: {},
			cascader: {},
			cascaderKey: null,
			cascaderPicker: null,
			cascaderPickerVal: [],
			value: {}, //表單數據
			req: "請輸入正確內容",
			emailVal: "請輸入正確電子信箱",
			mobile: "請輸入正確手機號碼",
			passwordVal: "密碼長度在6-16之間，只能包含字符、數字和下劃線",
			certNo: "請輸入正確的身份證號碼",
			image: {},
			images: {
				a: [], //默認站位 否則無法正常添加
				// "7ybg82": [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }, { url: "https://img01.yzcdn.cn/vant/tree.jpg" }],
			},
		};
	},
	watch: {
		//數據回顯
		valueData: {
			handler(newValue) {
				for (const item of newValue) {
					if (item.type == "checkbox") {
						this.$set(this[item.type], item.key, item.value.split(","));
					} else if (item.type == "image") {
						if (item.value) {
							this[item.type][item.key] = [];
							this[item.type][item.key].push(item.value);
						}
					} else if (item.type == "images") {
						if (item.value) {
							this.$set(this[item.type], item.key, item.value);
						}
					} else if (item.type == "rate") {
						this.$set(this[item.type], item.key, +item.value);
					} else {
						this.$set(this[item.type], item.key, item.value);
					}
				}
			},
		},
	},
	created() {},
	mounted() {
		this.getInit();
	},
	methods: {
		//手機號碼
		isMobile(value) {
			if (!value) return true;
			//判斷是否為數字
			if (isNaN(value)) return false;

			return /^1[3-9]\d{9}$|^09\d{8}$/.test(value);
		},
		//電子信箱
		isEmail(value) {
			if (!value) return true;

			return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.[_a-z0-9-]+(\.[_a-z0-9-]+)*$/i.test(value);
		},
		//身份證
		isCertNo(value) {
			if (!value) return true;

			return /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)|^[a-zA-Z][0-9]{9}$/.test(value);
		},
		//是否是數字
		isNumber(value) {
			if (!value) return true;

			return /^[0-9]+$/.test(value);
		},
		//密碼
		isPassword(value) {
			if (!value) return true;

			return /^[a-zA-Z\w]{6,16}$/.test(value);
		},
		getInit() {
			if (isExist(this.data)) {
				for (const item of this.data.fldList) {
					if (["radio", "radioImages", "select"].includes(item.fldType)) {
						for (const option of item.fldOptions) {
							let type = item.fldType;
							if (option.check) {
								this.$set(this[type], item.key, option.name);
							}
						}
					} else if (["checkbox", "checkboxImages"].includes(item.fldType)) {
						let arr = [];
						for (const option of item.fldOptions) {
							let type = item.fldType;
							if (option.check) {
								arr.push(option.name);
								this.$set(this[type], item.key, arr);
							}
						}
					} else if (["user", "tel", "email"].includes(item.fldType)) {
						//預設關聯用戶信息
						if (isExist(this.userInfo)) {
							if (item.isRelation && !this[item.fldType][item.key]) {
								switch (item.fldType) {
									case "user":
										if (this.userInfo.memName) this.$set(this[item.fldType], item.key, this.userInfo.memName);
										break;
									case "tel":
										if (this.userInfo.mobile) this.$set(this[item.fldType], item.key, this.userInfo.mobile);
										break;
									case "email":
										if (this.userInfo.email) this.$set(this[item.fldType], item.key, this.userInfo.email);
										break;
									default:
										break;
								}
							}
						}
					}
				}
				// if (isExist(this.valueData)) {
				//     for (const item of this.valueData) {
				//         this.$set(this[item.type], item.key, item.value);
				//     }
				// }
			}
		},
		onSelect(item) {
			this.selectKey = item.key;
			this.selectPicker = true;
			this.selectPickerVal = item.fldOptions;
		},
		selectConfirm(val) {
			this.select[this.selectKey] = val.name;
			this.selectPicker = false;
		},
		cascaderSelect(item) {
			this.cascaderKey = item.key;
			this.cascaderPicker = true;
			this.cascaderPickerVal = item.fldOptions;
		},
		cascaderConfirm(val) {
			this.cascader[this.cascaderKey] = val.join(",");
			this.cascaderPicker = false;
		},
		dateConfirm(date) {
			this.date[this.dateKey] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
			this.datePicker = false;
		},
		calendarConfirm(date) {
			this.calendar[this.calendarKey] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
			this.calendarPicker = false;
		},
		imageAfterRead(file, key) {
			this.$emit("imageAfterRead", file, key);
		},
		imageDelete(file, detail, key) {
			return new Promise((resolve, reject) => {
				delete this.image[key];
				this.$emit("imageDelete", key);
				resolve();
			});
		},
		imagesAfterRead(file, key) {
			this.$emit("imagesAfterRead", file, key, this.images[key]);
		},
		imagesDelete(file, detail, key) {
			console.log(this.images[key]);
			this.$nextTick(() => {
				this.images[key].splice(detail.index, 1);
				this.$emit("imagesDelete", key, detail);
			});
		},
		imageOversize() {
			Toast("圖片大小不能超過 10MB");
		},
		submit(val) {
			this.$emit("formSubmit", val, this.data.formInfo);
		},
	},
};
