//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Dialog } from "element-ui";
import CouponItem from "@/components/Coupon/Item.vue";
export default {
    name: "CouponGet",
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        CouponItem,
    },
    props: {
        cpnlist: Array,
        newCpnlist: Array,
    },
    data() {
        return {
            couponShow: false,
        };
    },
    mounted() {},
    created() {},
    methods: {},
};
