/*
 * @Author: your name
 * @Date: 2021-07-15 15:22:34
 * @LastEditTime: 2021-07-16 09:20:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shop_saas\src\elearningservice\service\base.ts
 */
import { request } from "@/elearningservice/service/request.js";
import { serBasic } from "@/elearningservice/service/serBasic.js";
import { config } from "@/elearningservice/repository/config.js";
import { tick } from "@/elearningservice/repository/tick.js";
import { datatype } from "@/library2/utils/datatype.js";
import { dataTrans } from "@/library2/utils/dataTrans.js";
import { log } from "@/library2/utils/log";
import des from "@/library2/utils/des.js";
import md5 from "js-md5";
export class base extends serBasic {
	/**
	 * @description: 獲取配置信息
	 */
	async getConfigInfo() {
		let data = await new config().genData(new request().connectNsuv(), "", { fun: "base", op: "getConfigInfo" });

		if (data?.ConfigInfo) {
			// sessionStorage.setItem("ConfigInfo", JSON.stringify(data.ConfigInfo));
			return super._success({ ConfigInfo: data.ConfigInfo });
		} else {
			return super._error();
		}
	}

	/**
	 * @description: 獲取時間戳
	 */
	async getTickInfo() {
		let data = await new request().connectNsuv().post("", { fun: "base", op: "getTickInfo" });
		if (datatype.isNull(data)) return super._error();

		const cnt = await new tick().put(data);

		return cnt > 0 ? super._success() : super._error();
	}
	async initTpAnalytics(type, value) {
		const { fbPixel, googleAnalytics } = await import("@/library2/utils/tpAnalytics.js");
		switch (type) {
			case "fbPixel":
				fbPixel(value);
				break;
			case "googleAnalytics":
				googleAnalytics(value);
				break;
			default:
				break;
		}
	}

	async fbPixel(fbPixelId) {
		this.initTpAnalytics("fbPixel", fbPixelId);
	}
	async googleAnalytics(id) {
		this.initTpAnalytics("googleAnalytics", id);
	}
	gaSentEvent(value) {
		log.addLog("gaSentEvent service請求參數", value);
		if (!window.ga) return false;
		if (datatype.isNull(value.eventValue)) {
			window.ga("send", "event", value.eventCategory, value.eventAction, value.eventLabel);
		} else {
			window.ga("send", "event", value.eventCategory, value.eventAction, value.eventLabel, value.eventValue);
		}
	}

	genSign(params) {
		const _sort = (json) => {
			let ar = [];
			for (let i in json) {
				ar.push(i);
			}

			let ar2 = ar.sort();

			let json2 = {};
			for (let i of ar2) {
				json2[i] = json[i];
			}

			return json2;
		};

		const _genSign = (params) => {
			//排序
			let json = _sort(params);

			//數組轉json
			let str = "";
			for (let i in json) {
				if (i == "sign") continue;
				if (json[i] instanceof File) continue;

				if (json[i] instanceof Array || json[i] instanceof Object) {
					json[i] = JSON.stringify(json[i]);
					// json[i] = json[i].replace(/\//g,'\\\/')
				}
				if (json[i] === "" || json[i] === null || json[i] === "undefined") continue;
				json[i] = String(json[i]); //轉成字符串
				json[i] = json[i].replace(/(^\s*) | (\s*$)/g, "");

				str = str + (str === "" ? "" : "&") + i + "=" + json[i];
			}

			//拼接
			if (process.env.VUE_APP_APPSECRET) {
				// console.log("str==========");
				// console.log(str);
				str = str + "&key=" + dataTrans.decodeBase64(des.desDecode(process.env.VUE_APP_APPSECRET, process.env.VUE_APP_CRYPT_KEY));
			}
			return md5(str);
		};

        return _genSign(params);
	}
}
