
import { ctrlBase, ctrlFb, ctrlCpn } from "@/controller";
import { mapState } from "vuex";
export const couponMixin = {
    data() {
        return {
            shareUrl: null,//分享網址
            cpnlist: [],//優惠券列表
        }
    },
    props: {
    },
    watch: {
    },
    created() {
    },
    computed: {
        ...mapState({
            config(state) {
                return state.base.configInfo;
            },
        }),
    },
    methods: {
        //領券
        async mix_collectCoupon(cpnNbrs, scene, type) {
            const data = await ctrlCpn.collectCoupon({ cpnNbrs, scene });
            if (data) {
                this.cpnlist = data;
                this.$refs.couponGet.couponShow = true;
                if (type == 'getPageInfo') { this.$store.dispatch("base/setNeedUpdate", true); }
                return data
            }
        },
        //無門檻領券
        async mix_uncollectCoupon(list) {
            const unCpnNbrs = list.filter((item) => item.stat == -1).map((ele) => ele.cpnNbr);
            //沒有未領取的無門檻券 直接return
            if (!this.Common.isExist(unCpnNbrs)) return false;
            this.mix_collectCoupon(unCpnNbrs, 0, "getPageInfo");
        },
        //獲取分享地址
        async mix_getShareUrl(value) {
            this.shareUrl = await ctrlBase.getShareUrl(value)
            if (this.shareUrl) this.mix_share(value.cpnNbr)
        },
        //分享
        async mix_share(extra) {
            const obj = {
                appId: this.config.fbAppId,
                shareUrl: this.shareUrl,
                redirectUrl: window.location.href,
                extra
            }
            await ctrlFb.share(obj)
        }
    }
};
