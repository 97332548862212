//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ctrlBase,
  ctrlPage,
  ctrlAccount,
  ctrlFb,
  ctrlCpn,
  ctrlCart,
} from '@/controller';
import { GraphicVerify, CouponGet, Header, NavBar } from '@/components';
import { Badge, Image, Button, Drawer } from 'element-ui';
import VideoCustom from '@/components/VideoCustom/VideoCustom.vue';
import { mapGetters } from 'vuex';
import { pt,filter } from '@/hooks';
const hkPt = new pt();
export default {
  components: {
    [Badge.name]: Badge,
    [Image.name]: Image,
    [Button.name]: Button,
    [Drawer.name]: Drawer,
    GraphicVerify,
    CouponGet,
    VideoCustom,
    Header,
    NavBar,
  },
  provide() {
    return {
      searchValue: () => this.search,
    };
  },
  data() {
    return {
      menuDrawer: false,
      tickInfo: {},
      info: {},
      list: [],
      // headInfo: {
      //     id:"default"
      // },
      // footInfo: {},
      // navInfo: {},
      isLogin: false, //是否登入
      newCpnlist: [], //新人券
      searchTags: [],
      search: '',
      filterPageInfo:{}
    };
  },
  watch: {
    async isLogin(newValue) {
      console.log('app.vue登入狀態：' + newValue);
      //檢測登入後調用個人信息
      if (newValue) {
        const user = await ctrlAccount.getSenMemInfo();
        this.$store.dispatch('user/setUserInfo', user);
      }
    },
    // $route: {
    //     handler(route, oldVal) {
    //         this.getInit(route);
    //     },
    // },
    //是否需要更新數據
    needUpdate(newVal) {
      if (newVal) {
        this.getInit();
      }
    },
    hasCollectCouponByUser(newValue) {
      newValue && this.collectCouponByUser();
    },
    pageInfo(newValue){
        if(this.isExist(newValue) &&newValue.List){
           this.filterPageInfo = filter.sortPageList(newValue.List);
        }
    }
  },
  created() {
    this.getConfigInfo();
    this.getInit();
    this.getSearchTags();
    this.getAppBrowser();
    this.getAppSystem();
  },
  mounted() {},
  computed: {
    pageInfo() {
      return this.$store.state.page.pageInfo;
    },
    headInfo() {
      return this.$store.state.page.headInfo;
    },
    footInfo() {
      return this.$store.state.page.footInfo;
    },
    navInfo() {
      return this.$store.state.page.navInfo;
    },
    configInfo() {
      return this.$store.state.base.configInfo;
    },
    needUpdate() {
      return this.$store.state.base.needUpdate;
    },
    hasCollectCouponByUser() {
      return this.$store.state.base.hasCollectCouponByUser;
    },
    userInfo(state) {
        return this.$store.state?.user?.userInfo ?? {};
    },
    ...mapGetters({
      historySearchTags: 'localStore/getHisList',
    }),
    key() {
      return this.$route.path;
    },
  },
  methods: {
    async getInit() {
      this.chkLogin();
    },
    async getConfigInfo() {
      this.getTickInfo();
      if (this.Common.isExist(this.configInfo)) return;
      const data = await ctrlBase.getConfigInfo();
      if (data) {
        this.$store.dispatch('base/setConfigInfo', data);
        // document.title = data.storeName;
        var link = document.createElement('link');
        link.href = data.favicon;
        // link.href = "https://img01.yzcdn.cn/vant/cat.jpeg";
        // link.href = "https://img01.yzcdn.cn/vant/logo.png";
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
        //開啟fb追蹤
        if (data.fbPixelId) ctrlBase.fbPixel(data.fbPixelId);
        //開啟Ga
        if (data.gaId) ctrlBase.googleAnalytics(data.gaId);
        let dom = document.getElementsByTagName('head')[0];
        //開啟meta
        if (data.meta) {
          dom.innerHTML += data.meta;
        }
        //自定義css
        if (data.styleUrl) {
          const css = document.createElement('link');
          css.rel = 'stylesheet';
          css.type = 'text/css';
          css.href = data.styleUrl;
          dom.appendChild(css);
        }
      }
    },
    //客服
    onlineService() {
      ctrlFb.onlineService(this.configInfo);
    },
    async getTickInfo() {
      this.tickInfo = await ctrlBase.getTickInfo();
    },
    async getNavInfo(navId) {
      this.navInfo = await ctrlPage.getNavInfo({ navId });
    },
    //新人券
    async collectCouponByUser() {
      const data = await ctrlCpn.collectCouponByUser(this.configInfo);

      if (data) {
        this.newCpnlist = data;
        this.$refs.couponGet.couponShow = true;
      }
    },
    //是否登入
    async chkLogin() {
      this.isLogin = ctrlAccount.chkLogin();
    },

    // 判斷瀏覽器
    getAppBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/Mobile/i) == 'mobile') {
        document.getElementsByTagName('html')[0].className += ' moBrowser';
        return 'mobile';
      } else if (ua.match(/FiedoraApp/i) == 'fiedoraapp') {
        document.getElementsByTagName('html')[0].className += ' appBrowser';
        return 'fiedoraapp';
      } else if (
        ua.match(/MessengerLite/i) == 'messengerlite' ||
        ua.match(/Orca-Android/i) == 'orca-android'
      ) {
        document.getElementsByTagName('html')[0].className +=
          'moBrowser msgBrowser';
        return 'fbmessenger';
      } else if (ua.match(/FBIOS/i) == 'fbios' || ua.match(/FB4A/i) == 'fb4a') {
        document.getElementsByTagName('html')[0].className +=
          ' moBrowser fbBrowser';
        return 'fbapp';
      } else if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        document.getElementsByTagName('html')[0].className +=
          ' moBrowser wxBrowser';
        return 'weixin';
      } else {
        document.getElementsByTagName('html')[0].className += ' winBrowser';
        this.browser = 'window';
        return 'window';
      }
    },
    // 判斷設備
    getAppSystem() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/Android/i) == 'android') {
        document
          .getElementsByTagName('html')[0]
          .setAttribute('data-sys', 'android');
      } else if (
        ua.match(/iPhone/i) == 'iphone' ||
        ua.match(/iPad/i) == 'ipad'
      ) {
        document
          .getElementsByTagName('html')[0]
          .setAttribute('data-sys', 'ios');
      } else if (ua.match(/crios/i) == 'crios') {
        // ios chrome
        document
          .getElementsByTagName('html')[0]
          .setAttribute('data-browser', 'ioschrome');
      }
    },
    async getSearchTags() {
      this.searchTags = await hkPt.getSearchTags();
    },
    // async getHistorySearchTags() {
    // 	this.historySearchTags = await hkPt.getHistorySearchTags();
    // },
    //搜索回調
    searchChange(search) {
      if (!search) return;
      const hisList = this.historySearchTags;
      if (!this.Common.isExist(hisList)) {
        this.$store.dispatch('localStore/setHisList', search);
      } else {
        const isExist = hisList.some((item) => item == search);
        if (!isExist) this.$store.dispatch('localStore/setHisList', search);
      }
      this.$router.push(`/search?q=${search}`);
    },
    //刪除記錄回調
    deleteHis() {
      this.$store.commit('localStore/clearHisList');
    },
    hotTagClick(search) {
      console.log('hotTagClick---');
      // console.log(search);
      this.search = search;
      // this.$router.push(`/search?q=${search}`);
      this.searchChange(search);
    },
    hisTagClick(search) {
      console.log('hisTagClick---');
      console.log(search);
      this.search = search;
      this.$router.push(`/search?q=${search}`);
    },
    //登出
    async signout() {
      const data = await ctrlAccount.signout();
      if (data) this.$store.dispatch('user/setUserInfo', {});
    },
  },
};
