import { request } from "@/elearningservice/service/request.js";

//獲取查詢商品列表
const getSearch = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "pt", op: "getSearch" }, data);
	return res.list;
};
//獲取熱門標簽
const getSearchTags = async (data) => {
	const res = await new request().connectNsuv().post("", { fun: "pt", op: "getSearchTags" }, data);
	return res.list;
};

export { getSearch, getSearchTags };
