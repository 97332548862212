/*
 * @Author: andy
 * @Date: 2021-06-04 09:50:52
 * @LastEditTime: 2021-07-16 11:50:01
 * @Description: DB
 * @FilePath: \shop_corp\src\elearningservice\repository\DB.js
 */

const MAIN_DB = 'edb6'
const MAIN_DB_VER = 1
const TABLE = {
  config: '++seq',
  tick: '++retCode',
  page: '++seq, [b+p]',
  foot: '++seq, footId',
  head: '++seq, headId',
  nav: '++seq, navId',
  ptInfo: '++seq,&ptNbr',
  favorite: '++seq,userNbr',
  cpgInfo: '++seq,cpgNbr',
  cpnPtList: '++seq,cpnNbr',
  tagPtList: '++seq,tagNbr',
  cpgPtList: '++seq,cpgNbr',
  additionList: '++seq,ptNbr',
  cgPtList: '++seq,cgNbr',
  cart: '++seq,userNbr',
  statCpnList: '++seq,[userNbr+useStat]',
  userCpnList: '++seq,userNbr',
  frcList: '++seq',
  areaList: '++seq',
  rsn: '++seq,userNbr',
  address: '++seq,userNbr',
  storeAddress: '++seq,userNbr',
  orderList: '++seq,[userNbr+_stat]',
  payList: '++seq',
  invList: '++seq, userNbr',
  buyNowInfo: '++storeNbr',
  afterList: '++userNbr,[userNbr+soNbr]',
  partRetResult: '++userNbr,[userNbr+soNbr]',
  partRetList:'++userNbr,[userNbr+soNbr]',
  info: '++seq,nbr',
  ptAdvList: '++seq,ptNbrs',
  courseList: '++seq,userNbr',
  playbackList: '++seq,userNbr',
  chpdInfo: '++seq,[userNbr+chpdNbr]',
  loveCodeList: '++seq,userNbr',
}

export default {
  MAIN_DB,
  MAIN_DB_VER,
  TABLE
}
