class filter {
  static sortPageList(list) {
      console.log("sortPageList----");
    // list = [
    //     {
    //         "content": "t1",
    //         "bgcolor": "",
    //         "id": "t1",
    //         "name": "RichText"
    //     },
    //     {
    //         "content": "t2",
    //         "bgcolor": "",
    //         "id": "t2",
    //         "name": "RichText"
    //     },
    //     {
    //         "content": "t3",
    //         "bgcolor": "",
    //         "id": "t3",
    //         "name": "RichText"
    //     },
    //     {
    //         "id": "p-4123s5",
    //         "name": "default",
    //         "ptNbr": "p-4123s5"
    //     },
    //     {
    //         "content": "f1",
    //         "bgcolor": "",
    //         "id": "s1",
    //         "name": "RichText"
    //     },
    //     {
    //         "content": "f2",
    //         "bgcolor": "",
    //         "id": "s2",
    //         "name": "RichText"
    //     },
    // ]
    let obj = {
      headerList: [],
      footerList: [],
    };

    for (let i = 0; i < list.length; i++) {
      if (list[i].name == 'default') {
        obj.headerList = list.slice(0, i);
        obj.footerList = list.slice(i + 1, list.length);
      }
    }
    return obj;
  }
}

export default filter;
