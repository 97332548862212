//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
export default {
    components: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
            chkAcctExistStat() {
                return this.$store.state.base.chkAcctExistStat;
            },
        }),
    },
    props: {
        text: String,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {},
};
