//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog, Tag, Tabs, TabPane, CheckboxGroup, Checkbox, Image } from "element-ui";
import CouponItem from "./Item";
import Null from "@/components/Null";
export default {
    name: "Coupon",
    components: {
        [Dialog.name]: Dialog,
        [Tag.name]: Tag,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [Image.name]: Image,
        CouponItem,
        Null
    },
    props: {
        source: String, //數據來源 根據不同顯示內容 checkout
        navList: Array,
        isShowBtn: Boolean,
    },
    data() {
        return {
            couponVisible: false,
            activeName: "可用",
            // navList: [
            //     { name: "可用", list: [] },
            //     { name: "不可用", list: [] },
            // ],
            currDiscList: [], //當前選中優惠券列表
        };
    },
    mounted() {
        this.currDiscList = this.navList[0].list;
    },
    created() {},
    methods: {
        handleClick() {},
        disHandleChange(currDiscList,item,list) {
            this.$emit("disChange", currDiscList,item,list);
        },
        collectCoupon() {
            this.$emit("collectCoupon", true);
        },
    },
};
